import { Component } from '@angular/core';

@Component({
  selector: 'app-adpanel',
  standalone: true,
  imports: [],
  templateUrl: './adpanel.component.html',
  styleUrl: './adpanel.component.scss'
})
export class AdpanelComponent {

  ngAfterViewInit() {
    setTimeout(() => {
      try {
        (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        console.error("ads", e);
      }
    }, 900);
  }


}
