import { AuthenticationService } from '@/_services';
import { FullscreenImageDialogComponent } from '@/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { User } from '@/models/user';
import { ImageService } from '@/services/image.service';
import { UserService } from '@/services/user.service';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Output() closeSidenav = new EventEmitter<void>();
  isLoggedIn = false;
  authSubscription: Subscription;
  currentUserSubscription: Subscription;
  // currentUser$: Observable<User>;
  currentUser: User;

  constructor(
    private logger: NGXLogger,
    private imageService: ImageService,
    private userService: UserService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.logger.debug('footer component : ubscribing to authchange isloggedin');
    this.authSubscription = this.authenticationService.authChange.subscribe(authStatus => {
      this.logger.debug('footer component : isloggedin =', authStatus);
      // this.isLoggedIn = authStatus;
    });
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      // this.logger.debug('FooterComponent  user subscribed: ', JSON.stringify(user))
      this.currentUser = user;
      this.isLoggedIn = (user != null);
      this.logger.debug('FooterComponent  is loggedin: ', this.isLoggedIn)
    });
    // if (this.currentUser) {
    //   this.logger.debug("current user :" + JSON.stringify(this.currentUser))
    //   this.currentUser$ = this.userService.getUser(this.currentUser.id)
    // }

  }

  onClose() {
    this.closeSidenav.emit();
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }
  openfullscreenimageDialog() {
    this.imageService.ogphotourl(this.currentUser.photocontainer, this.currentUser.photoname).subscribe(
      x => {
        const dialog_ref = this.dialog.open(FullscreenImageDialogComponent, {
          panelClass: ['full-screen'],
          data: { imgUrl: x },
          // data: { imgUrl: this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname) },
        });
        dialog_ref.afterClosed().subscribe(result => { });
      });
  }
}
