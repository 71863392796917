import { User } from '../_models';
import { Author } from './author';

export enum StatusType {
        DRAFT_STATUS = 1,
        GOPUBLIC_STATUS = 2,
        PUBLIC_STATUS = 3,
        INAPPROPRIATE = 4
};

export class Serie {
        public id: number;
        public name: string;
        public desc: string;
        public initial_language: string;
        public owner: Author;
        // public owner: User
        // public owner: number;

        public category: number;
        public photourl: string;
        public photoname: string;
        public photocontainer: string;
        public pub_date: Date;
        public updated_at: Date;
        public status: StatusType;
        constructor() { }
        gphotourl(): string {
                return 'xxxxxx';
                // return this.photocontainer + this.photoname;
        }
        public nb_likes: number;
        public islikedbyme: boolean;
        public nb_comments: number;
        public iscommentedbyme: boolean;
        public nb_subscribtions: number;
        public issubscribedbyme: boolean;
        public nb_raters: number;
        public isratedbyme: boolean;
}
