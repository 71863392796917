import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { Photo } from '../models/photo';
import { PhotoService } from '../services/photo.service';
import { Observable, map } from 'rxjs';
import { AuthenticationService } from '@/_services';
import { ImageService } from '@/services/image.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { computeArrowPoints8, createPhylactereOvale, createPhylactereRect, createThoughtLine } from '@/shared/util';
import { Texte, TexteDisplayType, TexteShapeType, TexteType } from '@/models/texte';
import { TexteService } from '@/services/texte.service';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {


  @Input() photos: Photo[];
  @Input() episode_id: number;
  display_comments: boolean = false;

  constructor(private photoService: PhotoService,
    private route: ActivatedRoute,
    private texteService: TexteService,
    public authenticationService: AuthenticationService,
    private imageService: ImageService,
    public translate: TranslateService,
    public dialog: MatDialog,
    private logger: NGXLogger,
    private render: Renderer2
  ) { }


  ngOnInit() {
    if (this.photos) {
      this.logger.debug("PhotosComponent has photos");
    } else
      if (this.episode_id) {
        this.getEpisodePhotos(this.episode_id);
      } else {
        this.getPhotos();
      }


  }

  ngAfterViewInit() {
    let i = 0;
    for (let photo of this.photos) {
      // this.texteService.getPhotoTextes(photo.id).subscribe(y => {
      //     // this.imgratio = this.myImg.nativeElement.clientWidth / this.myImg.nativeElement.naturalWidth;
      //     // this.logger.debug('carrousel imgratio : ' + this.imgratio);
      //     // this.logger.debug('carrousel clientw nat w : ' +
      //     // this.myImg.nativeElement.clientWidth + ' , ' + this.myImg.nativeElement.naturalWidth);
      // for (const txt of y) {
      for (const txt of photo.textes) {
        //       // this.logger.debug('carrousesel txt: ', txt.texte);
        waitForElementToExist('myimg' + photo.id, () => { this.drawTexte(photo.id, photo.size_x, photo.size_y, txt) });
        // this.drawTexte(photo.id, photo.size_x, photo.size_y, txt);
      }
      // });
      i++;
    }
  }
  getEpisodePhotos(episode_id: number): void {
    // getEpisodePhotos(episode_id: number): Observable<Photo[]> {
    this.photoService.getEpisodePhotos(episode_id).subscribe(pts => this.photos = pts);
    // return this.photoService.getEpisodePhotos(episode_id).pipe(map(page => page.results));
  }

  getPhotos(): void {
    //    this.photoService.getPhotos().subscribe(photos => this.photos = photos);
    this.photoService.getPhotosListAll().subscribe(page => this.photos = page.results);
  }

  add(desc: string): void {
    desc = desc.trim();
    if (!desc) { return; }
    this.photoService.addPhoto({ desc } as Photo)
      .subscribe(hero => {
        this.photos.push(hero);
      });
  }

  delete(photo: Photo): void {
    this.photos = this.photos.filter(h => h !== photo);
    this.photoService.deletePhoto(photo).subscribe();
  }
  drawTexte(photoid: number, size_x: number, size_y: number, txt: Texte) {
    const logger = this.logger;
    let svg: SVGSVGElement;
    let imgratio_x: number;
    let imgratio_y: number;
    let divimgratio: number;
    let imgratio: number;
    let textDivtop, textDivleft: number;
    let boundaryX2, boundaryY2: number;
    let imgtopleft_x: number = 0;
    let imgtopleft_y: number = 0;

    // this.logger.debug('photo id  ' + photoid);
    // this.logger.debug('drawTexte txt id  ' + txt.id);

    const myImg = document.getElementById('myimg' + photoid) as HTMLImageElement;;
    // imgratio = myImg.clientWidth / myImg.naturalWidth;
    divimgratio = myImg.clientWidth / myImg.clientHeight
    imgratio = size_x / size_y
    imgratio_x = myImg.clientWidth / size_x;
    imgratio_y = myImg.clientHeight / size_y;

    if (imgratio > divimgratio) {
      imgtopleft_x = 0; //ok
      imgtopleft_y = (myImg.clientHeight - imgratio_x * size_y) / 2
    }
    else {
      imgtopleft_x = (myImg.clientWidth - imgratio_y * size_x) / 2;
      imgtopleft_y = 0; // ok
    }

    // this.logger.debug('drawtext divimgratio =' + String(divimgratio));
    // this.logger.debug('drawtext imgratio =' + String(imgratio));
    // this.logger.debug('drawtext imgratio_x =' + String(imgratio_x));
    // this.logger.debug('drawtext imgratio_y =' + String(imgratio_y));
    // this.logger.debug('drawtext clientwidth =' + String(myImg.clientWidth));
    // this.logger.debug('drawtext clientHeight =' + String(myImg.clientHeight));
    // this.logger.debug('drawtext naturalwidth =' + String(myImg.naturalWidth));


    const textImg = document.getElementById('textimg' + photoid) as HTMLImageElement;;
    document.querySelectorAll(`[data-textid="${txt.id}"]`).forEach(e => e.remove());

    const textDiv = document.createElement('div');
    textImg.appendChild(textDiv);
    const textContent = document.createTextNode(txt.texte);
    textDiv.appendChild(textContent);

    textDiv.setAttribute('draggable', 'false');
    textDiv.setAttribute('data-textid', String(txt.id));
    textDiv.id = String(txt.id);
    this.render.addClass(textDiv, 'text-block');
    // textDiv.classList.add('text-block');
    // textDiv.style.backgroundColor = 'rgba(255,248,220,0.1)';
    textDiv.style.backgroundColor = txt.background_color;
    logger.debug('current font size : ' + txt.font_size);
    // textDiv.style.fontSize = txt.font_size;
    textDiv.style.fontSize = '0.3rem';
    // textDiv.style.backgroundColor = 'Cornsilk';
    textDiv.style.whiteSpace = 'pre';
    textDiv.style.zIndex = '10';
    textDiv.style.position = 'absolute';
    textDiv.style.borderRadius = '7px';
    // textDiv.style.boxSizing = 'border-box';
    textDiv.style.padding = '7px';
    textDiv.style.border = '0px solid';
    // textDiv.style.fontSize = '0.8rem';
    // this.logger.debug('drawTexte textDiv id  ' + textDiv.id);
    // this.logger.debug('drawTexte textDiv pos  top: ' + textDiv.style.top + ' , left: ' + textDiv.style.left);


    if (imgratio > divimgratio) {
      textDivtop = Math.floor(txt.position_y * imgratio_x);
      textDivleft = Math.floor(txt.position_x * imgratio_x);
    }
    else {
      textDivtop = Math.floor(txt.position_y * imgratio_y);
      textDivleft = Math.floor(txt.position_x * imgratio_y);
    }

    textDivtop += imgtopleft_y;
    textDivleft += imgtopleft_x;
    // this.logger.debug(' textdiv top =' + textDivtop);
    // this.logger.debug(' teYxtdiv left =' + textDivleft);
    // this.logger.debug('drawTexte textDiv bbox : ' + JSON.stringify(bbox));

    let bbox = textDiv.getBoundingClientRect();
    const PADDING = 0;
    const boundaryX1 = 0 + PADDING;
    const boundaryY1 = 0 + PADDING;
    if (imgratio < divimgratio) {
      boundaryX2 = size_x * imgratio_y - PADDING;
      boundaryY2 = myImg.clientHeight - PADDING;
    } else {
      boundaryX2 = myImg.clientWidth - PADDING;
      boundaryY2 = size_y * imgratio_x - PADDING;
    }
    // this.logger.debug('drawTexte boundary x2 y2 : ' + boundaryX2 + ' ' + boundaryY2);
    let minX = boundaryX1;
    let maxX = boundaryX2 - bbox.width;
    let minY = boundaryY1;
    let maxY = boundaryY2 - bbox.height;
    minX += imgtopleft_x;
    maxX += imgtopleft_x;
    minY += imgtopleft_y;
    maxY += imgtopleft_y;
    // logger.debug('minX maxX miny maxY ' +
    //   minX + ' ' + maxX + ' ' + minY + ' ' + maxY);
    // let dy = textDiv.offsetTop;
    // let dx = textDiv.offsetLeft;
    let dy = textDivtop;
    let dx = textDivleft;
    if (dx < minX) {
      dx = minX;
      // logger.debug('dx constrained ' + dx);
    } else if (dx > maxX) {
      dx = maxX;
      // logger.debug('dx constrained ' + dx);
    }
    if (dy < minY) {
      dy = minY;
      // logger.debug('dy constrained ' + dy);
    } else if (dy > maxY) {
      dy = maxY;
      // logger.debug('dy constrained ' + dy);
    }
    // logger.debug('dx dy last ' + dx + ' ' + dy);
    textDiv.style.top = String(dy) + 'px';
    textDiv.style.left = String(dx) + 'px';

    svg = null;
    svg = <SVGSVGElement><any>document.getElementById('svg' + photoid);

    let startxl = txt.arrow_x * imgratio;
    let startyl = txt.arrow_y * imgratio;
    if (imgratio > divimgratio) {
      startxl = txt.arrow_x * imgratio_x;
      startyl = txt.arrow_y * imgratio_x;
    }
    else {
      startxl = txt.arrow_x * imgratio_y;
      startyl = txt.arrow_y * imgratio_y;
    }
    startxl += imgtopleft_x;
    startyl += imgtopleft_y;
    const arrowPoints = computeArrowPoints8(logger, textDiv.offsetLeft, textDiv.offsetTop,
      textDiv.offsetWidth, textDiv.offsetHeight,
      startxl, startyl);

    if (txt.display === TexteDisplayType.DISPLAY_BOTH || txt.display === TexteDisplayType.DISPLAY_ARROW) {
      if (txt.type === TexteType.pensee) {
        createThoughtLine(logger, svg, imgratio, txt.id, txt.background_color, startxl, startyl, arrowPoints.x1, arrowPoints.y1);
      }
    }
    textDiv.style.backgroundColor = 'rgba(255,255,255,0)';
    textDiv.style.border = '0px solid';

    if (txt.shape === TexteShapeType.ovale) {
      createPhylactereOvale(logger, svg, imgratio, txt.id, txt.background_color,
        startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight, txt.type);
    }
    else if (txt.shape === TexteShapeType.rectangle) {
      createPhylactereRect(logger, svg, imgratio, txt.id, txt.background_color,
        startxl, startyl, arrowPoints.x1, arrowPoints.y1, arrowPoints.x2, arrowPoints.y2,
        textDiv.offsetLeft, textDiv.offsetTop, textDiv.offsetWidth, textDiv.offsetHeight, txt.type);
    }
  }
}

function waitForElementToExist(selector, callback) {
  let count = 0;
  // console.log('waitForElementToExist  ');
  const intervalID = setInterval(() => {
    count++;
    if (document.getElementById(selector)) {
      // console.log('The element exists');
      clearInterval(intervalID);
      callback();
    }
    else
      // console.log('The element idoes not exists');
      if (count > 20)
        clearInterval(intervalID);

  }, 600);
}

