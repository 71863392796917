import { queryPaginatedAllLimit } from '@/pagination';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { MessageService } from './message.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { UrlService } from './url.service';
import { User } from '@/models/user';

@Injectable({
  providedIn: 'root'
})
export class FollowsService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private urlService: UrlService,
    public translate: TranslateService,
    private logger: NGXLogger
  ) {
  }


  getMyFriends(): Observable<User[]> {
    return queryPaginatedAllLimit<User>(this.http, this.urlService.getFollowsUrl() + 'myfriends/', 100).pipe(
      map(events => {
        return events.results;
      }),
      tap(xxx => this.log(`fetched friends`)),
      catchError(this.handleError('getcEpisodes', []))
    );;
  }

  getMyFollowed(): Observable<User[]> {
    return queryPaginatedAllLimit<User>(this.http, this.urlService.getFollowsUrl() + 'myfollowed/', 100).pipe(
      map(events => {
        return events.results;
      }),
      tap(xxx => this.log(`fetched followed`)),
      catchError(this.handleError('getcEpisodes', []))
    );;;
  }
  getMyFollowers(): Observable<User[]> {
    return queryPaginatedAllLimit<User>(this.http, this.urlService.getFollowsUrl() + 'myfollowers/', 100).pipe(
      map(events => {
        return events.results;
      }),
      tap(xxx => this.log(`fetched followers`)),
      catchError(this.handleError('getcEpisodes', []))
    );;;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a EpisodeService message with the MessageService */
  private log(message: string) {
    this.messageService.add('EpisodeService: ' + message);
  }
}
