<div class="authors">
    <mat-button-toggle-group *ngIf="chooseDisplayMode"
                             name="fontStyle"
                             aria-label="Font Style">
        <mat-button-toggle value="grid"
                           (change)="onValChange($event.value)">
            <mat-icon class="example-tab-icon"
                      aria-label="toggle fullscreen">grid_on</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list"
                           (change)="onValChange($event.value)">
            <mat-icon class="example-tab-icon"
                      aria-label="toggle fullscreen">list</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-list *ngIf="displayMode=='list'"
              class="list">
        <div mat-subheader
             class="listheader"
             i18n="serie|my series@@my_series">{{title}}</div>
        <cdk-virtual-scroll-viewport [itemSize]="85"
                                     class="viewport">
            <mat-list-item *cdkVirtualFor="let author of authors"
                           class="item">
                <!-- src="{{ imageService.gphotourl(author.photocontainer,author.photoname) }}" -->
                <img class="myavatar"
                     routerLink="/authors/{{author.id}}"
                     [src]="imageService.ogphotourl(author.photocontainer,author.photoname)|async"
                     alt="Desc 1">
                <div routerLink="/authors/{{author.id}}">
                    <div MatListItemTitle>&#64;{{author.pseudo}}
                    </div>
                    <div MatListItemLine> {{ (author.bio.length>32)? (author.bio |
                        slice:0:32)+'...':(author.bio) }}
                    </div>
                </div>

                <div *ngIf="authenticationService.isLoggedIn()">


                    <button *ngIf="authenticationService.isLoggedIn()"
                            mat-icon-button
                            title="like author"
                            (click)="follow(author)">
                        <mat-icon matBadge="15"
                                  matBadgeColor="warn"
                                  matBadgePosition="after"
                                  aria-label="Example icon-button with a heart icon">
                            person_add
                        </mat-icon>
                    </button>
                </div>
            </mat-list-item>
        </cdk-virtual-scroll-viewport>
    </mat-list>

    <ng-container *ngIf="displayMode=='grid'">

        <div mat-subheader
             class="listheader"
             i18n="serie|my series@@my_series">{{title}}</div>
        <cdk-virtual-scroll-viewport #virtualViewport2
                                     [itemSize]="0"
                                     class="viewport listcard">

            <mat-card appearance="outlined"
                      *cdkVirtualFor="let author of authors"
                      [ngClass]="{'lcard':true, 'handset-portrait': handsetPortrait}">
                <mat-card-header>
                    <mat-card-title routerLink="/authors/{{author.id}}">&#64;{{author.pseudo |
                        truncateword : 20 }}
                    </mat-card-title>
                    <mat-card-subtitle routerLink="/authors/{{author.id}}">{{author.bio |
                        truncateword : 20 }}
                    </mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image
                     class="image"
                     routerLink="/authors/{{author.id}}"
                     *ngIf="(imageService.ogphotourl(author.photocontainer,author.photoname)|async) as imgurl"
                     [src]="imgurl"
                     alt="Photo of a Shiba Inu">
                <mat-card-content>
                </mat-card-content>
                <mat-card-actions>
                    <div>
                        <button mat-icon-button
                                title="like author"
                                [disabled]="!authenticationService.isLoggedIn()"
                                (click)="follow(author)">
                            <mat-icon matBadge="15"
                                      matBadgeColor="warn"
                                      matBadgePosition="after"
                                      aria-label="Example icon-button with a heart icon">person_add</mat-icon>
                        </button>
                    </div>
                </mat-card-actions>
                <mat-card-footer>
                </mat-card-footer>
            </mat-card>
        </cdk-virtual-scroll-viewport>
    </ng-container>
</div>