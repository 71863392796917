import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Serie } from '../models/serie';
import { MessageService } from './message.service';
import { Page, queryPaginatedAllLimit } from '../pagination';
import { UrlService } from './url.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 'Authorization': 'Basic ' +
      btoa('serge.frezefond@gmail.com:manager12')
  })
};

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private messageService: MessageService
  ) {
  }

  getMySubscribedSeries(): Observable<Page<Serie>> {
    return queryPaginatedAllLimit<Serie>(this.http, this.urlService.getSubscriptionsUrl() + 'mysubscribedseries/', 100);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a EpisodeService message with the MessageService */
  private log(message: string) {
    this.messageService.add('EpisodeService: ' + message);
  }
}

