<mat-toolbar class="footer"
             color="primary"
             flex>
    <span>
        <button mat-icon-button
                routerLink="/home">
            <mat-icon>home</mat-icon>
        </button>
    </span>
    <span class="fill-remaining-space">
    </span>
    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                (click)="onClose()"
                routerLink="/mysubscriptions">
            <mat-icon>subscriptions</mat-icon>
        </button>
    </span>
    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                (click)="onClose()"
                routerLink="/mybookmarks">
            <mat-icon>bookmark</mat-icon>
        </button>
    </span>
    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                (click)="onClose()"
                routerLink="/myliked/1">
            <mat-icon>favorite</mat-icon>
        </button>
    </span>

    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                (click)="onClose()"
                routerLink="/myrelations/0">
            <mat-icon>group</mat-icon>
        </button>
    </span>
    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                (click)="onClose()"
                routerLink="/mycomments">
            <mat-icon>comment</mat-icon>
        </button>
    </span>

    <ng-container *ngIf="isLoggedIn">
        <img mat-card-avatar
             class="myavatar episodethumbnail"
             *ngIf="(imageService.ogphotourl(currentUser.photocontainer,currentUser.photoname)|async) as imgurl"
             [src]="imgurl"
             alt="Desc 1"
             routerLink="/mydashboard/1">
    </ng-container>
    <!-- (click)="openfullscreenimageDialog()"> -->
    <!-- <span *ngIf="!isLoggedIn">
        <button mat-icon-button
                routerLink="/register"
                (click)="onClose()">
            <mat-icon>account_box</mat-icon>
        </button>
    </span>
    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                routerLink="/profile">
            <mat-icon>person</mat-icon>
        </button>
    </span>
    <span *ngIf="isLoggedIn">
        <button mat-icon-button
                routerLink="/settings">
            <mat-icon>settings</mat-icon>
        </button>
    </span> -->
</mat-toolbar>