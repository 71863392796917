import { Subscription } from 'rxjs';
// import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from '@/_services';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HostListener, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { User } from '@/_models';
import { NGXLogger } from 'ngx-logger';
import { Country } from '@angular-material-extensions/select-country';
import { TranslateService } from '@ngx-translate/core';
import { ImageService } from '@/services/image.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  elem: any; isFullScreen: boolean;

  @Output() sidenavToggle = new EventEmitter<void>();
  @Output() closeSidenav = new EventEmitter<void>();
  currentUserSubscription: Subscription;
  currentUser: User;
  // title = 'P';
  title = 'Picafrou';

  countryFormControl = new FormControl();
  countryFormGroup: FormGroup;


  defaultValue: Country = {
    name: 'Deutschland',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    numericCode: '276',
    callingCode: '+49'
  };

  predefinedCountries: Country[] = [
    {
      name: 'US',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      callingCode: '+1'
    },
    {
      name: 'Germany',
      alpha2Code: 'DE',
      alpha3Code: 'DEU',
      numericCode: '276',
      callingCode: '+49'
    },
    {
      name: 'Greece',
      alpha2Code: 'GR',
      alpha3Code: 'GRC',
      numericCode: '300',
      callingCode: '+30'
    },
    {
      name: 'China',
      alpha2Code: 'CN',
      alpha3Code: 'CHN',
      numericCode: '156',
      callingCode: '+33'
    },
    {
      name: 'India',
      alpha2Code: 'IN',
      alpha3Code: 'IND',
      numericCode: '356',
      callingCode: '+33'
    },
    {
      name: 'Spain',
      alpha2Code: 'ES',
      alpha3Code: 'ESP',
      numericCode: '724',
      callingCode: '+33'
    },
    {
      name: 'France',
      alpha2Code: 'FR',
      alpha3Code: 'FRA',
      numericCode: '250',
      callingCode: '+33'
    },
    {
      name: 'United Kingdom ',
      alpha2Code: 'GB',
      alpha3Code: 'GBR',
      numericCode: '826',
      callingCode: '+32'
    }
  ];

  siteLanguage = 'English';
  languageList = [
    { code: 'en', label: 'English' },
    { code: 'zh-hans', label: 'Chinese' },
    { code: 'hi', label: 'Hindi' },
    { code: 'es', label: 'Spanish' },
    { code: 'fr', label: 'French' },
    { code: 'ar', label: 'Arabic' },
    { code: 'bn', label: 'Bengali' },
    { code: 'ru', label: 'Russian' },
    { code: 'pt', label: 'Portuguese' },
    { code: 'ja', label: 'Japanese' },
    { code: 'ko', label: 'Korean' },
    { code: 'vi', label: 'Vietnamese' },
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authenticationService: AuthenticationService,
    private imageService: ImageService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private logger: NGXLogger,
  ) {

  }

  ngOnInit() {
    // this.chkScreenMode();
    this.elem = document.documentElement;
    // to try to avoid browser bar
    // this.openFullscreen();

    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      // this.logger.debug('header component user subscribed: ', JSON.stringify(user))
      this.currentUser = user;
    });
    this.countryFormGroup = this.formBuilder.group({
      country: [{
        name: 'Deutschland',
        alpha2Code: 'DE',
        alpha3Code: 'DEU',
        numericCode: '276',
        callingCode: '+32'
      }]
    });

    this.countryFormGroup.get('country').valueChanges
      .subscribe(country => console
        .log('this.countryFormGroup.get("country").valueChanges', country));

    this.countryFormControl.valueChanges
      .subscribe(country => console
        .log('this.countryFormControl.valueChanges', country));
  }

  ngAfterViewInit(): void {
    // this.logger.debug('ngAfterViewInit call to openFullscreen')
    // impossible requires human action
    // this.openFullscreen();
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  onClose() {
    this.closeSidenav.emit();
  }
  // onLogout() {
  // this.authenticationService.logout();
  // }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmodes(event) {
    // this.chkScreenMode();
  }
  chkScreenMode() {
    if (document.fullscreenElement) {
      // fullscreen
      this.isFullScreen = true;
      this.logger.debug('======================= chkScreenMode fullscreen')
    } else {
      // not in full screen
      this.isFullScreen = false;
      this.logger.debug('======================= chkScreenMode not fullscreen')
    }
  }
  openFullscreen() {
    this.logger.debug('======================= openFullscreen')
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();

      this.logger.debug('======================= requestFullscreen')
    } else if (this.elem.mozRequestFullScreen) {
      this.logger.debug('======================= mozRequestFullScreen')
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.logger.debug('======================= webkitRequestFullscreen')
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.logger.debug('======================= msRequestFullscreen')
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    this.logger.debug('======================= closeFullscreen')
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.elem.mozCancelFullScreen) {
      /* Firefox */
      this.elem.mozCancelFullScreen();
    } else if (this.elem.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitExitFullscreen();
    } else if (this.elem.msExitFullscreen) {
      /* IE/Edge */
      this.elem.msExitFullscreen();
    }
  }

  onCountrySelected($event: Country) {
    console.log($event);
    this.translate.use($event.alpha2Code.toLowerCase())
  }

  getLanguages(): string[] {
    return this.translate.getLangs().map((lg) => { if (lg == 'zh-hans') { return 'zh' } else return lg });
  }

  getCurrentlanguage(): string {
    return (this.translate.currentLang == 'zh-hans') ? 'zh' : this.translate.currentLang;
  }

  changeLang(lg: string) {
    let language = (lg == 'zh') ? 'zh-hans' : lg;
    this.translate.use(language)
    this.translate.setDefaultLang(language)
    localStorage.setItem('locale', language)
  }

  refresh(): void {
    window.location.reload();
  }
}


