<mat-tab-group class="tabs">
    <mat-tab label="series">
        <div *ngIf="(series$ | async) as series"
             class="flexd">
            <app-series [title]="'my liked series'"
                        [series]="series"></app-series>
        </div>
    </mat-tab>
    <mat-tab label="series">
        <div *ngIf="(episodes$ | async) as episodes"
             class="flexd">
            <app-episodes [title]="'my liked episodes'"
                          [episodes]="episodes"></app-episodes>
        </div>
    </mat-tab>
    <mat-tab label="series">
        <div *ngIf="(photos$ | async) as photos"
             class="flexd">
            <app-photos [title]="'my liked photos'"
                        [photos]="photos"></app-photos>
        </div>
    </mat-tab>
</mat-tab-group>