<mat-card appearance="outlined"
          class="my-card">
    <mat-card-title>
        Change Password
    </mat-card-title>
    <mat-card-content>
        <div *ngIf="loading"
             style="text-align: center;">
            <mat-spinner></mat-spinner>
        </div>
        <form class="my-form"
              [formGroup]="changepasswordForm"
              (ngSubmit)="onSubmit()">
            <mat-form-field class="full-width">
                <mat-label>Current Password</mat-label>
                <input matInput
                       placeholder="current password"
                       name="currentpassword"
                       formControlName='currentpassword'
                       [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>New Password</mat-label>
                <input matInput
                       placeholder="new password"
                       name="password1"
                       formControlName='password1'
                       [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-error *ngIf="password1.invalid">{{getPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field class=" full-width">
                <mat-label>Repeat New Password</mat-label>
                <input matInput
                       placeholder="new Password"
                       name="password2"
                       formControlName='password2'
                       [type]="hide ? 'password' : 'text'"
                       required
                       autocomplete="current-password">
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <!-- <mat-hint *ngIf="!passwordInput.value">Enter your password</mat-hint> -->
                <mat-error *ngIf="password2.hasError('mustMatch')">Passsword
                    and Confirm Password didn't match.
                </mat-error>
                <mat-error *ngIf="password2.invalid">{{getPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
            <p class="alert alert-danger"
               *ngIf="error">{{error}}</p>

            <mat-card-actions>
                <button mat-raised-button
                        matTooltipPosition="above"
                        matTooltip="Cancel Password Change"
                        [matTooltipShowDelay]="1"
                        [matTooltipHideDelay]="3"
                        color="accent"
                        type="button"
                        (click)="onCancel()">cancel</button>
                <button mat-raised-button
                        type=" submit"
                        color="primary">Validate</button>
                <a routerLink="/forgotpassword"
                   class="btn btn-link">Forgot password ?</a>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>