
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Serie } from '../models/serie';
import { Comment } from '../models/comment';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
import { AppConfigService } from './app-config.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from './url.service';
import { AuthenticationService } from '@/_services';
import { Rating } from '@/models/rating';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json', 'Authorization': 'Basic ' +
//       btoa('serge.frezefond@gmail.com:manager12')
//   })
// };

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({ providedIn: 'root' })
export class RatingService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private urlService: UrlService,
    private logger: NGXLogger
  ) {
    this.logger.debug('SerieService')
  }

  private httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + AuthenticationService.getJWTToken() })
  };

  getSerieRatings(serie_id: number): Observable<Rating[]> {
    return queryPaginatedAllLimit<Rating>(this.http, this.urlService.getAuthorsUrl() + serie_id + '/ratings/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched authors series`)),
        catchError(this.handleError<Rating[]>('getAuthorSeries', []))
      );

  }

  /** GET series from the server . public series are return  whic means with at least one public episode*/
  getRatings(): Observable<Rating[]> {
    return queryPaginatedAllLimit<Rating>(this.http, this.urlService.getSeriesUrl() + '?status=1', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Rating[]>('getSeries', []))
      );
  }

  /** GET series from the server */
  getMyRatings(): Observable<Rating[]> {
    return queryPaginatedAllLimit<Rating>(this.http, this.urlService.getSeriesUrl() + '?owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Rating[]>('getSeries', []))
      );
  }

  getRating(id: number): Observable<Rating> {
    // const url = this.urlService.getSeriesUrl() + `?id=${id}/`;
    const url = this.urlService.getSeriesUrl() + `${id}/`;
    return this.http.get<Rating>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched serie id=${id}`)),
      catchError(this.handleError<Rating>(`getSerie id=${id}`))
    );
  }
  addRating(rating: Rating): Observable<Rating> {

    console.log('addRating: ', JSON.stringify(rating));
    return this.http.post<Rating>(this.urlService.getRatingsUrl(), rating, this.httpOptions).pipe(
      tap((xrating: Rating) => this.log(`added serie w/ id=${xrating.id}`)),
      catchError(this.handleError<Rating>('addRating'))
    );
  }

  /** DELETE: delete the serie from the server */
  deleteRating(rating: Rating | number): Observable<Rating> {
    const id = typeof rating === 'number' ? rating : rating.id;
    const url = this.urlService.getSeriesUrl() + `${id}/`;

    return this.http.delete<Rating>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted serie id=${id}`)),
      catchError(this.handleError<Rating>('deleteSerie'))
    );
  }

  /** PUT: update the serie on the server */
  updateRating(serie: Rating): Observable<any> {
    return this.http.put(this.urlService.getSeriesUrl() + serie.id + '/', serie, this.httpOptions).pipe(
      tap(_ => this.log(`updated serie id=${serie.id}`)),
      catchError(this.handleError<any>('updateSerie'))
    );
  }

  rateSerie(serie: Serie, rating: number): Observable<any> {
    console.log('serieService rateSerie: userid ${this.authenticationService.currentUserValue.id} ' +
      'rating ${rating}');

    return this.http.post(this.urlService.getRatingsUrl(), {
      owner: this.authenticationService.currentUserValue.id,
      serie: serie.id, value: rating
    }, this.httpOptions);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('serieService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a SerieService message with the MessageService */
  private log(message: string) {
    this.messageService.add('SerieService: ' + message);
  }
}
