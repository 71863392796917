import { Texte, TexteType, TexteShapeType, TexteDisplayType } from '@/models/texte';
export enum StatusType {
    DRAFT_STATUS = 1,
    GOPUBLIC_STATUS = 2,
    PUBLIC_STATUS = 3,
    INAPPROPRIATE = 4
};
export enum ModerationStatusType {
    MODERATION_STATUS_PENDIND = 1,
    MODERATION_STATUS_REJECTED = 2,
    MODERATION_STATUS_TOCHECK = 3,
    MODERATION_STATUS_APPROVEED = 4
};

export class Photo {
    public id: number;
    public episode: number;
    public desc: string;
    public initial_language: string;
    public status: StatusType;
    public moderation_status: ModerationStatusType;
    public numero: number;
    public size_x: number;
    public size_y: number;
    public url: string;
    public photoname: string;
    public photocontainer: string;
    public pub_date: Date;
    public updated_at: Date;
    public owner: number;
    public nb_likes: number;
    public islikedbyme: boolean;
    public nb_comments: number;
    public iscommentedbyme: boolean;
    public textes: Texte[];
}
