<div *ngIf="textes">
  <li *ngFor="let texte of textes | async"
      [ngClass]="{'selectedlistitem':selectedItem === texte}"
      (click)="selectItem(texte)">
    <!-- 
    <div *ngFor="let comment of comments$ | async; let i = index">
    -->
    <span class="badge">{{texte.id}}</span> {{texte.texte}}
    <span class="badge">{{texte.type}}</span>

    <button mat-icon-button
            title="delete texte"
            (click)="delete(texte)">
      <mat-icon aria-label="Example icon-button with a heart icon">delete</mat-icon>
    </button>
    <!-- <button mat-icon-button title="edit texte" (click)="edit(texte)">
      <mat-icon aria-label="edit texte">edit</mat-icon>
    </button> -->
  </li>
</div>