import { Component, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../_models';
import { UserSettings } from '../models/userSettings';
import { UploadService } from '@/services/upload.service';
import { AuthenticationService } from '@/_services';
import { UserSettingsService } from '@/services/user-settings.service';
import { NGXLogger } from 'ngx-logger';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  SettingsForm: FormGroup;
  @Input() userId: number;
  user: User;
  usersettings: UserSettings;
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  buttonText = 'Update';

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userSettingsService: UserSettingsService,
    private authenticationService: AuthenticationService,
    private logger: NGXLogger,
    private uploadService: UploadService
  ) {
    this.logger.debug('profile constructor');
  }

  ngOnInit() {

    this.user = this.authenticationService.currentUserValue;
    this.userSettingsService.getByUserId(this.user.id).subscribe(
      (data: UserSettings) => {
        this.usersettings = data;
        this.logger.debug('ngOnInit ' + this.usersettings);

      },
      error => {
        console.log(error);
      });

    this.SettingsForm = this.fb.group({
      'email': ['', Validators.required],
      'language': ['', Validators.required],
      'toggle': false
    });
    this.logger.debug('ngOnInit settings component');
  }


  onSubmit() {

    const formValue = this.SettingsForm.value;
    const newSetings = new UserSettings();

    this.logger.debug('you submitted value: ', formValue);
    newSetings.id = this.usersettings.id;
    newSetings.language = formValue['language'];

    this.logger.debug('form value: ', formValue);

    // on doit etre sure qu'un utilisateur ne peut mettre à jour que son profil
    this.userSettingsService.update(newSetings)
      .subscribe(
        data => {
          this.router.navigate(['/settings/' + this.userId]);
        },
        error => {
          console.log(error);
        });
  }
  onChange() {
    console.log(JSON.stringify(this.SettingsForm.value));
  }
  onCancel() {
    this.SettingsForm.reset();
    this.router.navigate(['/home'], { relativeTo: this.route });
  }
}
