import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent implements OnInit {
  @Input() comment_type: string;
  @Input() object_id: number;
  @Input() comments: Comment[];
  display_comment: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

}
