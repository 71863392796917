<mat-card appearance="outlined" class="my-card mat-elevation-z8">
    <mat-card-title>
        Settings
    </mat-card-title>
    <mat-card-content>
        <form [formGroup]="SettingsForm"
              (ngSubmit)="onSubmit()">
            <mat-form-field class="full-width">
                <mat-label>language</mat-label>
                <input matInput
                       type="text"
                       id="language"
                       placeholder="language"
                       [formControl]="SettingsForm.controls['language']">
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>email</mat-label>
                <input matInput
                       type="text"
                       id="emailInput"
                       placeholder="email"
                       [formControl]="SettingsForm.controls['email']">
            </mat-form-field>
            <div>
                <!-- <mat-label class="example-margin">toggle </mat-label> -->
                <mat-slide-toggle style="float: left;"
                                  labelPosition='before'
                                  matInput
                                  (change)="onChange();"
                                  id="toggleInput"
                                  [formControl]="SettingsForm.controls['toggle']"
                                  class="example-margin"
                                  color="primary">
                    toggle</mat-slide-toggle>
            </div>

            <!-- <p>Form Group Status: {{ SettingsForm.status}}</p> -->

        </form>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button
                matTooltipPosition="above"
                matTooltip="Cancel Serie creation"
                [matTooltipShowDelay]="1"
                [matTooltipHideDelay]="3"
                color="accent"
                type="button"
                (click)="onCancel()">cancel</button>
        <button mat-raised-button
                color="primary"
                type="submit">{{buttonText}}</button>
    </mat-card-actions>
</mat-card>