
import { Texte, TexteDisplayType, TexteType } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-genphotosnum-dialog',
  templateUrl: './genphotosnum-dialog.component.html',
  styleUrls: ['./genphotosnum-dialog.component.scss']
})
export class GenphotosnumDialogComponent {


  texteForm: FormGroup;
  description: string;
  text_id: number;
  photo_id: number;

  constructor(
    private dialogRef: MatDialogRef<GenphotosnumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.logger.debug('newTextDialog constructor ' + JSON.stringify(data));
    this.text_id = data.text_id;
    this.photo_id = data.id;
  }

  ngOnInit(): void {
    this.texteForm = this.fb.group({
      'min': [1, Validators.required],
      'max': [1, Validators.required],
    });
  }

  save() {
    const formValue = this.texteForm.value;
    console.log('you submitted value: ', formValue);
    this.dialogRef.close({ min: formValue['min'], max: formValue['max'] });
  }

  close() {
    this.dialogRef.close();
  }
}
