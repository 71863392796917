<mat-button-toggle-group class="buttons"
                         name="fontStyle"
                         aria-label="Font Style">
    <mat-button-toggle value="grid"
                       (change)="onValChange($event.value)">
        <mat-icon class="example-tab-icon"
                  aria-label="toggle fullscreen">grid_on</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="list"
                       (change)="onValChange($event.value)">
        <mat-icon class="example-tab-icon"
                  aria-label="toggle fullscreen">list</mat-icon>
    </mat-button-toggle>

    <!-- mat-mini-fab -->
</mat-button-toggle-group>
<!-- [swipeLimitWidth]="80"
               [connectEdges]="true" -->
<mat-tab-group #tabgroup
               (touchstart)="swipe($event, 'start')"
               (touchend)="swipe($event, 'end')"
               matTabGroupGesture
               class="tabs"
               [(selectedIndex)]="selectedTabNum"
               (selectedIndexChange)="changeSelectedTabNum($event)">
    <mat-tab label="Picashoots">
        <div *ngIf="(photoshoots$ | async) as photoshoots"
             class="flexd">
            <div class="addbutton"
                 *ngIf="authenticationService.isLoggedIn()">
                <div class="centeradd">
                    <button mat-raised-button
                            color="primary"
                            class="picashoot"
                            *ngIf="authenticationService.isLoggedIn()"
                            [routerLink]="['/editphoto/0',{episodeMode: 'photoshoot'}]">
                        <mat-icon aria-label="add serie"><span class="material-symbols-outlined">
                                photo_camera_front
                            </span></mat-icon>
                        <mat-icon aria-label="add serie">add</mat-icon>
                        <div class="picashoot">Picashoot</div>
                    </button>
                </div>
            </div>
            <app-photos [title]="'Picashoots'"
                        [photos]="photoshoots"
                        [chooseDisplayMode]="false"
                        [displayTitle]="false"
                        [displayMode]="display">
            </app-photos>
        </div>
    </mat-tab>
    <mat-tab label="Series">
        <div *ngIf="(series$ | async) as series"
             class="flexd">
            <app-series [title]="'Series'"
                        [series]="series"
                        [chooseDisplayMode]="false"
                        [displayTitle]="false"
                        [virtualDisplay]="false"
                        [displayMode]="display">
            </app-series>
        </div>
    </mat-tab>
    <mat-tab label="Episodes">
        <div *ngIf="(episodes$ | async) as episodes"
             class="flexd">
            <app-episodes [title]="'Episodes'"
                          [episodes]="episodes"
                          [chooseDisplayMode]="false"
                          [displayTitle]="false"
                          [virtualDisplay]="false"
                          [displayMode]="display">

            </app-episodes>
        </div>
    </mat-tab>
</mat-tab-group>

<!-- 
<a *ngIf="authenticationService.isLoggedIn()"
   mat-fab
   routerLink="/series/new/">
    <mat-icon aria-label="add serie">add</mat-icon>
</a> -->

<!-- <app-container-list></app-container-list>

<hr />

<app-selected-container class="container">
    <app-input-file class="input-file"></app-input-file>

    <hr />
    <app-items-list class="items-list"></app-items-list>
    <hr />

    <div class="item-details">
        <app-items-uploaded class="items-uploaded"></app-items-uploaded>

        <hr />
        <app-items-downloaded class="items-downloaded"></app-items-downloaded>
        <hr />

        <app-items-deleted class="items-deleted"></app-items-deleted>
    </div>
</app-selected-container> -->