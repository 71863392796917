import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService }  from './in-memory-data.service';
import { ScrollingModule } from '@angular/cdk/scrolling';

// import { MatTabGroupGestureModule } from '@angular-material-gesture/mat-tab-group-gesture';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SerieSearchComponent } from './serie-search/serie-search.component';
import { MessagesComponent } from './messages/messages.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SeriesComponent } from './series/series.component';
import { SerieDetailComponent } from './serie-detail/serie-detail.component';
import { EpisodesComponent } from './episodes/episodes.component';
import { PhotosComponent } from './photos/photos.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { SerieService } from './services/serie.service';
import { AuthenticationService } from './_services/authentication.service';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EpisodeDetailComponent } from './episode-detail/episode-detail.component';
import { PhotoDetailComponent } from './photo-detail/photo-detail.component';
import { RateComponent } from './rate/rate.component';
import { LikeComponent } from './like/like.component';
import { CommentComponent } from './comment/comment.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { CaroussselComponent } from './carousssel/carousssel.component';
import { EditPhotoComponent } from './edit-photo/edit-photo.component';
import { TextesComponent } from './textes/textes.component';
import { TexteDetailComponent } from './texte-detail/texte-detail.component';
import { AuthorsComponent } from './authors/authors.component';
import { ActorsComponent } from './actors/actors.component';
import { ActorDetailComponent } from './actor-detail/actor-detail.component';
import { AuthorDetailComponent } from './author-detail/author-detail.component';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import { LikesComponent } from './likes/likes.component';
import { CommentsComponent } from './comments/comments.component';
import { SerieFormComponent } from './serie-form/serie-form.component';
import { MyMaterialModule } from './material.module';
import { EpisodeEditComponent } from './episode-edit/episode-edit.component';
import { FileUploadModule } from 'ng2-file-upload';
// import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { UploaddialogComponent } from './uploaddialog/uploaddialog.component';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload/upload.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { UploadService } from './services/upload.service';
import { RegistrationComponentComponent } from './registration-component/registration-component.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { ForgotpasswordComponent } from './forgot-password';
import { EmailVerificationComponent } from './email-verification';
import { ResendEmailVerificationComponent } from './resend-email-verification';
import { ResetPasswordComponent } from './reset-password';
import { MeComponent } from './me/me.component';
import { SettingsComponent } from './settings/settings.component';
import { ProfileComponent } from './profile/profile.component';
import { PhotoseditComponent } from './photosedit/photosedit.component';
import { NgxSortableModule } from 'ngx-sortable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PhotoselectComponent } from './photoselect/photoselect.component';
import { PhotocreateComponent } from './photocreate/photocreate.component';
import { AlertComponent } from './_components/alert.component';
import { FileValidator } from './serie-form/file-input.validator';
import { APP_INITIALIZER, isDevMode } from '@angular/core';
import { AppConfigService } from './services//app-config.service';
// import { ChatcliComponent } from './chatcli/chatcli.component';
// import { NgChatModule } from 'ng-chat';
import { SearchpageComponent } from './searchpage/searchpage.component';
import { PhotosonlyComponent } from './photosonly/photosonly.component';
import { LogoutComponent } from './logout/logout.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { MycommentsComponent } from './mycomments/mycomments.component';
import { SocialloginComponent } from './sociallogin/sociallogin.component';
import { MyheaderComponent } from './myheader/myheader.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
    GoogleLoginProvider,
    FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { TextFormComponent } from './text-form/text-form.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { SwiperModule } from 'swiper/angular';
import { HeaderComponent } from './navigation/header/header.component';
import { FooterComponent } from './navigation/footer/footer.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { EpisodeFormComponent } from './episode-form/episode-form.component';
// import { Step1Component } from './episode-form/episode-form.component';
// import { Step2Component } from './episode-form/step2/step2.component';
import { AzureStorageModule } from './azure-storage/azure-storage.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PasswordChangedDialogComponent } from './change-password/password-changed-dialog/password-changed-dialog.component';
import { ForgotPasswordDialogComponent } from './forgot-password/forgot-password-dialog/forgot-password-dialog.component';
import { RegistrationDoneDialogComponent } from './registration-component/registration-done-dialog/registration-done-dialog.component';
import { TermsandconditionsDialogComponent } from './registration-component/termsandconditions-dialog/termsandconditions-dialog.component';
import { ResetPasswordDoneDialogComponent } from './reset-password/reset-password-done-dialog/reset-password-done-dialog.component';
import { LoginFailedDialogComponent } from './login-component/login-failed-dialog/login-failed-dialog.component';
import { LoadingComponent } from './loading/loading.component';
import { ErrorMessagesComponent } from './error-messages/error-messages.component';
import { LoadingService } from './loading/loading.service';
import { ErrorMessagesService } from './error-messages/error-messages.service';
import { CommentsListComponent } from './comments-list/comments-list.component';
import { TruncatePipe, TruncateWordPipe } from './shared/truncatepipe';
import { FollowedComponent } from './followed/followed.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { RatingDialogComponent } from './serie-detail/rating-dialog/rating-dialog.component';
import { SharingDialogComponent } from './serie-detail/sharing-dialog/sharing-dialog.component';
import { EpisodeCarrouselComponent } from './episode-carrousel/episode-carrousel.component';
import { EpisodeLinearcarrouselComponent } from './episode-linearcarrousel/episode-linearcarrousel.component';
import { ChangeLanguageComponent } from './change-language/change-language.component';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { NewTextDialogComponent } from './edit-photo/new-text-dialog/new-text-dialog.component';
import { TextShapeDialogComponent } from './edit-photo/text-shape-dialog/text-shape-dialog.component';
import { TextTypeDialogComponent } from './edit-photo/text-type-dialog/text-type-dialog.component';
import { BackgroundColorPickerDialogComponent } from './edit-photo/background-color-picker-dialog/background-color-picker-dialog.component';
import { DeleteTextDialogComponent } from './edit-photo/delete-text-dialog/delete-text-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { FullscreenImageDialogComponent } from './fullscreen-image-dialog/fullscreen-image-dialog.component';
import { DeletePhotoDialogComponent } from './delete-photo-dialog/delete-photo-dialog.component';
import { DeleteEpisodeDialogComponent } from './delete-episode-dialog/delete-episode-dialog.component';
import { DeleteSerieDialogComponent } from './delete-serie-dialog/delete-serie-dialog.component';
import { NeedTextSelectedDialogComponent } from './edit-photo/need-text-selected-dialog/need-text-selected-dialog.component';
import { GenphotosnumDialogComponent } from './episode-linearcarrousel/genphotosnum-dialog/genphotosnum-dialog.component';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiComponent } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { SharesComponent } from './shares/shares.component';
import { TelechargeComponent } from './telecharge/telecharge.component';
import { MydashboardComponent } from './mydashboard/mydashboard.component';
import { TextMessageDialogComponent } from './text-message-dialog/text-message-dialog.component';
import { AdvertisementDialogComponent } from './advertisement-dialog/advertisement-dialog.component';
import { LanguageDialogComponent } from './language-dialog/language-dialog.component';
import { CguDialogComponent } from './cgu-dialog/cgu-dialog.component';
import { ResendEmailVerificationDoneDialogComponent } from './resend-email-verification/resend-email-verification-done-dialog/resend-email-verification-done-dialog.component';
import { RouterModule } from '@angular/router';





const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
        // and returns simulated server responses.
        // Remove it when a real server is ready to receive requests.
        //   HttpClientInMemoryWebApiModule.forRoot(
        //     InMemoryDataService, { dataEncapsulation: false }
        //    ),
        // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        // NgbModule,
        FileUploadModule,
        MyMaterialModule,
        CommonModule,
        ScrollingModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule, MatDialogModule, MatListModule, MatSidenavModule, MatSlideToggleModule,
        // FlexLayoutModule,
        BrowserAnimationsModule, MatProgressBarModule,
        NgxSortableModule,
        DragDropModule,
        // NgChatModule,
        SocialLoginModule,
        ShareButtonsModule,
        //   .withConfig({
        //   debug: true
        // }),
        ShareIconsModule,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            enableSourceMaps: true,
            // serverLoggingUrl: 'http://localhost:68552/', // Replace with YOUR API
            // serverLogLevel: NgxLoggerLevel.ERROR

        }),
        // LoggerModule.forRoot(environment.logging),
        // LoggerModule.forRoot({ serverLoggingUrl: '/api/logs', level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
        SwiperModule,
        AzureStorageModule,
        NgxStarRatingModule,
        MatSelectCountryModule.forRoot('fr'), // you can use 'br' | 'de' | 'en' | 'es' | 'fr' | 'hr' | 'hu' | 'it' | 'nl' | 'pt' --> MatSelectCountrySupportedLanguages
        BrowserModule,
        ColorPickerModule,
        PickerComponent,
        EmojiComponent,
        // MatTabGroupGestureModule,




        // ngx-translate and the loader module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: localStorage.getItem('locale') || 'en',

        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        RouterModule

    ],
    declarations: [
        AppComponent,
        RegistrationComponentComponent,
        LoginComponentComponent,
        ForgotpasswordComponent,
        EmailVerificationComponent,
        ResendEmailVerificationComponent,
        ResetPasswordComponent,
        DashboardComponent,
        MessagesComponent,
        SerieSearchComponent,
        SeriesComponent,
        SerieDetailComponent,
        EpisodesComponent,
        PhotosComponent,
        PaginatorComponent,
        EpisodeDetailComponent,
        PhotoDetailComponent,
        RateComponent,
        LikeComponent,
        CommentComponent,
        SubscribeComponent,
        CaroussselComponent,
        EditPhotoComponent,
        TextesComponent,
        TexteDetailComponent,
        AuthorsComponent,
        ActorsComponent,
        ActorDetailComponent,
        AuthorDetailComponent,
        BookmarksComponent,
        LikesComponent,
        CommentsComponent,
        SerieFormComponent,
        EpisodeFormComponent,
        EpisodeEditComponent,
        //    FileSelectDirective, FileDropDirective,
        UploaddialogComponent,
        UploadComponent,
        PaginatorComponent,
        MeComponent,
        SettingsComponent,
        ProfileComponent,
        PhotoseditComponent,
        PhotoselectComponent,
        PhotocreateComponent,
        AlertComponent,
        FileValidator,
        // ChatcliComponent,
        SearchpageComponent,
        PhotosonlyComponent,
        LogoutComponent,
        SubscriptionsComponent,
        MycommentsComponent,
        SocialloginComponent,
        MyheaderComponent,
        CommentFormComponent,
        TextFormComponent,
        HeaderComponent,
        FooterComponent,
        SidenavListComponent,
        // Step1Component,
        // Step2Component,
        ChangePasswordComponent,
        PasswordChangedDialogComponent,
        ForgotPasswordDialogComponent,
        RegistrationDoneDialogComponent,
        TermsandconditionsDialogComponent,
        ResetPasswordDoneDialogComponent,
        LoginFailedDialogComponent,
        LoadingComponent,
        ErrorMessagesComponent,
        CommentsListComponent,
        TruncatePipe,
        TruncateWordPipe,
        FollowedComponent,
        StarRatingComponent,
        RatingDialogComponent,
        SharingDialogComponent,
        EpisodeCarrouselComponent,
        EpisodeLinearcarrouselComponent,
        ChangeLanguageComponent,
        NewTextDialogComponent,
        TextShapeDialogComponent,
        TextTypeDialogComponent,
        BackgroundColorPickerDialogComponent,
        DeleteTextDialogComponent,
        FullscreenImageDialogComponent,
        DeletePhotoDialogComponent,
        DeleteEpisodeDialogComponent,
        DeleteSerieDialogComponent,
        NeedTextSelectedDialogComponent,
        GenphotosnumDialogComponent,
        SharesComponent,
        TelechargeComponent,
        MydashboardComponent,
        TextMessageDialogComponent,
        AdvertisementDialogComponent,
        LanguageDialogComponent,
        CguDialogComponent,
        ResendEmailVerificationDoneDialogComponent
    ],
    providers: [
        //  AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        // AuthenticationService,
        //  SerieService,
        UploadService,
        LoadingService,
        ErrorMessagesService,
        {
            provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
            // , deps: [AppConfigService,
            // HttpClient,
            // AuthenticationService]
        },
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
        // si obsrvable et pas promise
        // useFactory: (configService: ConfigurationService) => () => configService.loadConfigurations().toPromise(),
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('419003646728-0rcverujhoe15iolsl9kbeugacovceok.apps.googleusercontent.com')
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        // localhost:4200
                        //            provider: new FacebookLoginProvider('407378137312184')
                        // picafrou.com global
                        provider: new FacebookLoginProvider('1277021659332443')
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        provideClientHydration()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
