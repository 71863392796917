import { AppConfigService } from '@/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlobStorageRequest } from '../types/azure-storage';

@Injectable({
  providedIn: 'root'
})
export class SasGeneratorService {
  constructor(private appconfigService: AppConfigService,
    private http: HttpClient) { }

  getSasToken(): Observable<BlobStorageRequest> {
    let sasGeneratorUrl = this.appconfigService.config.apiUrl + '/getsastoken';
    return this.http.get<BlobStorageRequest>(`${sasGeneratorUrl}`);
    // return this.http.get<BlobStorageRequest>(
    //   `${environment.sasGeneratorUrl}`
    // `${environment.sasGeneratorUrl}/api/GenerateSasToken`
  }
}
