<mat-dialog-content [formGroup]="texteForm"
                    (ngSubmit)="onSubmit()">
    <mat-form-field class="full-width">
        <mat-label>Texte</mat-label>
        <textarea matInput
                  type="text"
                  id="comment"
                  placeholder="texteForm"
                  [formControl]="texteForm.controls['texte']"
                  rows="2"></textarea>
    </mat-form-field>

    <mat-form-field>
        <mat-select [formControl]="texteForm.controls['shape']"
                    matNativeControl
                    required>
            <!-- /mat-select>placeholder=" Type">-->
            <mat-option [value]=1>
                rectangular
            </mat-option>
            <mat-option [value]=2>
                round
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select [formControl]="texteForm.controls['type']"
                    matNativeControl
                    required>
            <!-- /mat-select>placeholder=" Type">-->
            <mat-option [value]=1>
                parole
            </mat-option>
            <mat-option [value]=2>
                pensée
            </mat-option>
            <mat-option [value]=3>
                texte
            </mat-option>
            <mat-option [value]=4>
                contexte
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select formControlName='font_size'
                    matNativeControl
                    required>
            <!-- /mat-select>placeholder=" Type">-->
            <mat-option value="1rem">
                1rem
            </mat-option>
            <mat-option value="2rem">
                2rem
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select formControlName='background_color'
                    matNativeControl
                    required>
            <!-- /mat-select>placeholder=" Type">-->
            <mat-option value="rgba(255, 248, 240, 0.8)">
                colour1
            </mat-option>
            <mat-option value="rgba(255, 248, 240, 0.9)">
                color2
            </mat-option>
            <mat-option value="rgba(255, 248, 240, 0.9)">
                color3
            </mat-option>
            <mat-option value="rgba(255, 248, 240, 1)">
                color4
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button id="id_parolebtn"
            (click)="close()"
            class="btn btn-primary">close</button>
    <button id="id_parolebtn"
            (click)="save()"
            class="mat-raised-button  btn btn-primary">submit</button>
</mat-dialog-actions>