<mat-card appearance="outlined" class="my-card">
    <mat-card-title>
        Forgot Password </mat-card-title>
    <mat-card-content>
        <form *ngIf="!sent"
              class="my-form"
              [formGroup]="forgotpasswordForm"
              (ngSubmit)="onSubmit()">
            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input matInput
                       placeholder="Email"
                       name="email"
                       formControlName='email'>
                <mat-error *ngIf="email.hasError('required')">
                    email required.
                </mat-error>
                <mat-error *ngIf="email.hasError('email')">must be a valid email.
                </mat-error>
            </mat-form-field>
            <button mat-raised-button
                    type=" submit"
                    color="primary">Reset Password</button>
        </form>
    </mat-card-content>
</mat-card>