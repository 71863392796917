import { DialogData } from '@/forgot-password';
import { RegistrationDoneDialogComponent } from '@/registration-component/registration-done-dialog/registration-done-dialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password-done-dialog',
  templateUrl: './reset-password-done-dialog.component.html',
  styleUrls: ['./reset-password-done-dialog.component.scss']
})
export class ResetPasswordDoneDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ResetPasswordDoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.dialogRef.close();
  }
}
