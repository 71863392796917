<mat-card appearance="outlined"
          class="my-card mat-elevation-z4">
  <mat-card-title>
    Serie Search
  </mat-card-title>
  <mat-card-content>
    <form class="my-form"
          [formGroup]="searchForm"
          (ngSubmit)="onSubmit()">
      <mat-form-field class="full-width">
        <mat-label>Search Term</mat-label>
        <input matInput
               #searchInput
               id="search-box"
               placeholder="search term"
               name="searchText"
               formControlName='searchTerm'
               autocomplete="off" />
        <!-- (keyup)="search(searchBox.value)" /> -->
      </mat-form-field>
    </form>
  </mat-card-content>
  <!-- <mat-card-actions>
    <button mat-raised-button
            [routerLink]="'/home'"
            color="primary">Cancel</button>
  </mat-card-actions> -->
  <!-- <mat-divider></mat-divider> -->
  <!--<ul class="search-result">
      <li *ngFor="let serie of series$ | async">
        <a routerLink="/series/{{serie.id}}">
          {{serie.name}}
        </a>
      </li>
    </ul> -->

</mat-card>
<ng-container *ngIf="(series$ | async) as series">
  <app-series [title]="'Your series'"
              [series]="(series$ | async)"
              [chooseDisplayMode]="false"
              [displayMode]="display">
  </app-series>
</ng-container>
<!-- <div *ngIf="series$">
  <li *ngFor="let serie of (series$ | async)">
    <span>{{serie.desc}}</span>
  </li>
</div> -->