<mat-card appearance="outlined"
          class="my-card mat-elevation-z8">
    <mat-card-title>
        Registration
    </mat-card-title>
    <mat-card-content>
        <div class="alert alert-danger"
             color="primary"
             *ngIf="error">
            <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading"
             style="text-align: center;">
            <mat-spinner></mat-spinner>
        </div>
        <form class="my-form"
              [formGroup]="registerForm"
              (ngSubmit)="onSubmit()">
            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input matInput
                       placeholder="Email"
                       name="email"
                       formControlName='email'>
                <mat-error *ngIf="email.invalid">{{getEmailErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Password</mat-label>
                <input matInput
                       placeholder="Password"
                       name="password"
                       formControlName='password'
                       [type]="hide ? 'password' : 'text'"
                       required>
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-hint *ngIf="!password.value">Enter your password</mat-hint>
                <mat-error *ngIf="password.invalid">{{getPasswordErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Confirm Password</mat-label>
                <input matInput
                       placeholder="Confirm Password"
                       name="confirmPassword"
                       formControlName='confirmPassword'
                       [type]="hide ? 'password' : 'text'"
                       required>
                <!-- [errorStateMatcher]="matcher"> -->
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-hint *ngIf="!confirmPassword.value">Confirm your password</mat-hint>
                <mat-error *ngIf="passwordMatchError">Passsword
                    and Confirm Password didn't match.

                </mat-error>
                <mat-error *ngIf="confirmPassword.hasError('mustMatch')">Passsword
                    and Confirm Password didn't match.
                </mat-error>
                <mat-error *ngIf="registerForm.hasError('mustMatch')">Passsword
                    and Confirm Password didn't match.
                </mat-error>
            </mat-form-field>
            <div *ngIf="confirmPassword.hasError('minlength')">
                minlength
            </div>

            <mat-form-field class="full-width">
                <mat-label>Pseudo</mat-label>
                <input matInput
                       placeholder="Pseudo"
                       name="pseudo"
                       formControlName='pseudo'>
                <mat-error *ngIf="pseudo.hasError('required')">
                    pseudo required
                </mat-error>
                <mat-error *ngIf="pseudo.hasError('minlength')">
                    pseudo$ 6 chars min
                </mat-error>
            </mat-form-field>
            <div class="acceptcgu">
                <mat-checkbox class="full-width"
                              formControlName="acceptTerms"
                              [disabled]="true"
                              id="acceptTerms">
                </mat-checkbox>
                <a (click)="openCguDialog()"> Accept terms and conditions</a>
            </div>

            <button mat-raised-button
                    type="submit"
                    color="primary"
                    [disabled]="!registerForm.valid">REGISTER</button>
        </form>
    </mat-card-content>
    <!--
    <mat-card-actions>
        <a routerLink="/register" class="btn btn-link">Register</a>

    </mat-card-actions>
-->
</mat-card>
<!--
                  <mat-form-field class="full-width">
                     <mat-label>First Name</mat-label>
                     <input matInput placeholder="First name" name="fname" required>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                     <mat-label>Last Name</mat-label>
                     <input matInput placeholder="Last Name" name="lname" required>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                     <mat-label>Address</mat-label>
                     <input matInput placeholder="Address" name="address" required>
                  </mat-form-field>
                  
         <section class="example-section">
            <label class="full-width">Gender:</label>
            <br>
            <br>
            <mat-radio-group>
               <mat-radio-button class="example-margin" value="after">Male</mat-radio-button>
               <mat-radio-button class="example-margin" value="before">Female</mat-radio-button>
               <mat-radio-button class="example-margin" value="before">Other</mat-radio-button>
            </mat-radio-group>
         </section>
         <mat-form-field class="full-width">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="picker" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
         </mat-form-field>
         -->