import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Texte } from '../models/texte';
import { TexteService } from '../services/texte.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Page } from '../pagination';

@Component({
  selector: 'app-textes',
  templateUrl: './textes.component.html',
  styleUrls: ['./textes.component.scss']
})
export class TextesComponent implements OnInit {
  @Output() textSelected = new EventEmitter();
  @Input() photo_id: number;
  @Input() textes: Observable<Texte[]>;
  //  textes: Texte[];
  filterForm: FormGroup;
  page: Observable<Page<Texte>>;

  public selectedItem: any;
  public selected = false;

  constructor(private texteService: TexteService) { }

  ngOnInit() {
    //    this.getTextes();
    // this.getPhotoTextes(this.photo_id);
    this.selected = false;

  }

  // from ngx-sortable
  public selectItem(item: any) {
    this.selectedItem = item;
    this.selected = true;
    this.textSelected.emit(this.selectedItem);

  }

  // getPhotoTextes(photo_id: number): void {
  //   //    this.texteService.getTextes().subscribe(textes => this.textes = textes);
  //   // this.texteService.getPhotoTextes(photo_id).subscribe(textes => this.textes = textes);
  //   this.texteService.getPhotoTextes(photo_id).subscribe(page => this.textes = page.results);
  // }

  // getTextes(): void {
  //   //    this.texteService.getTextes().subscribe(textes => this.textes = textes);
  //   this.texteService.getTextesListAll().subscribe(page => this.textes = page.results);
  // }

  // add(name: string): void {
  //   name = name.trim();
  //   if (!name) { return; }
  //   this.texteService.addTexte({ texte: name } as Texte)
  //     .subscribe(hero => {
  //       this.textes.push(hero);
  //     });
  // }

  delete(texte: Texte): void {
    //    this.textes = this.textes.filter(h => h !== texte);
    this.texteService.deleteTexte(texte).subscribe();
  }

  edit(texte: Texte): void {
  }

  select(texte: Texte): void {
  }
}
