import { Category } from '@/models/category';
import { Serie } from '@/models/serie';
import { queryPaginatedAllLimit } from '@/pagination';
import { AuthenticationService } from '@/_services';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, tap, catchError, of } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { MessageService } from './message.service';
import { UserService } from './user.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {


  constructor(private http: HttpClient,
    private messageService: MessageService,
    private appconfigService: AppConfigService,
    private urlService: UrlService,
    private logger: NGXLogger) {
  }

  /** GET series from the server */
  getCategories(): Observable<Category[]> {
    return queryPaginatedAllLimit<Category>(this.http, this.urlService.getCategoriesUrl(), 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched series`)),
        catchError(this.handleError<Category[]>('getCategories', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('serieService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  /** Log a SerieService message with the MessageService */
  private log(message: string) {
    this.messageService.add('SerieService: ' + message);
  }
}
