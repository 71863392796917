<mat-toolbar>
    <span>Logout</span>
</mat-toolbar>
<mat-card appearance="outlined" class="my-card">
    <mat-card-header>
        <span>Logout</span>
    </mat-card-header>
    <mat-card-content>
        <!--form class="my-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()"-->
        <form class="my-form"
              (ngSubmit)="logout()">
            <button mat-raised-button
                    type=" submit"
                    color="primary">Cancel</button>
            <button mat-raised-button
                    type=" submit"
                    color="primary">Accept</button>
        </form>
    </mat-card-content>
</mat-card>