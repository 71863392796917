<form (ngSubmit)="onSubmit()"
      [formGroup]="commentForm">
    <mat-card appearance="outlined"
              class="my-card">
        <mat-form-field [style.marginBottom]="'-1.25em'"
                        class="formfieldtext">
            <mat-label>Comment</mat-label>
            <textarea #commentInput
                      formControlName="message"
                      matInput
                      placeholder="Message"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="7"></textarea>
            <mat-icon matSuffix
                      (click)="toggleEmojiPicker()">sentiment_very_satisfied</mat-icon>
            <!-- (click)="showEmojiPicker = !showEmojiPicker;">sentiment_very_satisfied</mat-icon> -->
            <!-- <ngx-emoji set="twitter"
                       emoji="shrug"
                       size="24"
                       [fallback]="emojiFallback"></ngx-emoji> -->

            <!-- <ngx-emoji [emoji]="{ id: 'santa', skin: 3 }"
                       size="16"></ngx-emoji> -->

            <!-- <emoji-mart title="Pick your emoji…"
                        emoji="point_up"></emoji-mart>
            <emoji-mart set="emojione"></emoji-mart> -->
            <emoji-mart (emojiClick)="addEmoji($event)"
                        [include]="emocats"
                        [perLine]="7"
                        [showPreview]="False"
                        [enableSearch]="False"
                        [sheetSize]="16"
                        [isNative]="true"
                        [totalFrequentLines]="0"
                        *ngIf="showEmojiPicker"></emoji-mart>
            <!-- <emoji-mart
                        [style]="{ position: 'absolute', bottom: '20px', right: '20px' }"></emoji-mart>
            <emoji-mart
                        [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }"></emoji-mart> -->
            <mat-error align="end"
                       *ngIf="commentForm.controls['message'].hasError('required')">
                Required
            </mat-error>
        </mat-form-field>
        <div class="sendbutton">
            <button mat-icon-button
                    color="primary"
                    type="submit"><mat-icon>send</mat-icon></button>
        </div>
    </mat-card>
</form>