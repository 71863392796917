<!-- formControlName="background_color" -->
<!-- (colorPickerOpen)="colorPickerOpen($event)" -->
<!-- [cpDisableInput]="false" -->
<!-- [cpPosition]="'top-right'" -->
<span [cpPosition]="'bottom'"
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      [(colorPicker)]="color"
      [cpDisabled]="false"
      [cpOutputFormat]="'rgba'"
      [cpCancelButton]="true"
      [cpOKButton]="true"
      [cpEyeDropper]="true"
      [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
      [style.background]="color"
      (colorPickerClose)="colorPickerClose($event)"
      (colorPickerChange)="colorPickerChange($event)"
      (colorPickerSelect)="colorPickerSelect($event)"
      (colorPickerCancel)="colorPickerCancel()"
      #colorInput>
</span>
<!-- <form [formGroup]="texteForm"
      (ngSubmit)="onSubmit()">
    <input 
          [cpPosition]="'bottom'"

           [(colorPicker)]="color"
           formControlName="background_color"
           [cpToggle]="true"
           [cpDisabled]="false"
           [cpDisableInput]="true"
           [value]="color"
           [cpOutputFormat]="'rgba'"
           [cpCancelButton]="true"
           [cpOKButton]="true"
           [cpEyeDropper]="true"
           [cpPosition]="'top-right'"
           [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']"
           [style.background]="color"
           (colorPickerSelect)="changed($event)"
           (colorPickerChange)="changed($event)"
           #colorInput />
     <button id="id_parolebtn"
        (click)="close()"
        class="btn btn-primary">close</button>
<button id="id_parolebtn"
        type="submit"
        class="mat-raised-button  btn btn-primary">submit</button> 
</form> -->
<!-- <mat-dialog-content [formGroup]="texteForm">
    <mat-form-field>
    </mat-form-field>
</mat-dialog-content> -->
<!-- [formControl]="texteForm.controls['background_color']" -->

<!-- <mat-dialog-actions>
        (click)="save()"
</mat-dialog-actions> -->