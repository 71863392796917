import { Component, Input, OnInit } from '@angular/core';
import { Author } from '../models/author';
import { AuthorService } from '../services/author.service';
import { User } from '@/models/user';
import { AuthenticationService } from '@/_services';
import { ImageService } from '@/services/image.service';
import { UploadService } from '@/services/upload.service';

@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
  styleUrls: ['./authors.component.scss']
})
export class AuthorsComponent implements OnInit {
  @Input() title = 'Followed';
  @Input() authors: User[];
  @Input() displayMode = 'list';
  @Input() chooseDisplayMode = false;

  constructor(
    public authenticationService: AuthenticationService,
    private uploadService: UploadService,
    private imageService: ImageService,
    private authorService: AuthorService) { }

  ngOnInit() {
  }

  toggledisplayMode(): void {
    this.displayMode = (this.displayMode === 'list') ? 'grid' : 'list';
  }

  onValChange(value) {
    console.log(value);
    this.displayMode = value;
  }

  follow(author: Author): void {
    this.authorService.followAuthor(author).subscribe();
  }
}
