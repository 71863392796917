<mat-card appearance="outlined"
          class="my-card mat-elevation-z8">
    <mat-card-header>
        <mat-card-title>
            Login
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="alert alert-danger"
             color="primary"
             *ngIf="error">
            <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading"
             style="text-align: center;">
            <mat-spinner></mat-spinner>
        </div>
        <form class="my-form"
              [formGroup]="loginForm"
              (ngSubmit)="onSubmit()">
            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input matInput
                       placeholder="Email"
                       name="email"
                       formControlName='email'
                       autocomplete="username">
                <mat-hint *ngIf="!email.value">Enter your email</mat-hint>
                <mat-error *ngIf="email.hasError('email')">
                    enter a valid email adress
                </mat-error>
            </mat-form-field>
            <mat-form-field class=" full-width">
                <mat-label>Password</mat-label>
                <input matInput
                       placeholder="Password"
                       name="password"
                       formControlName='password'
                       [type]="hide ? 'password' : 'text'"
                       required
                       autocomplete="current-password">
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
                <mat-hint *ngIf="!password.value">Enter your password</mat-hint>
                <mat-error *ngIf="password.hasError('required')">
                    password required
                </mat-error>
            </mat-form-field>
            <button mat-raised-button
                    type=" submit"
                    color="primary">Connection</button>
            <mat-card-actions>
                <a routerLink="/forgotpassword"
                   class="btn btn-link">Forgot password</a>
                <a routerLink="/resendemailverification"
                   class="btn btn-link">Resend verification email</a>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>
<mat-card appearance="outlined"
          class="my-card">
    <mat-card-content>
        <mat-card-actions>
            You do not have an account ? <a routerLink="/register"
               class="btn btn-link">Register</a>
        </mat-card-actions>
    </mat-card-content>
</mat-card>