<input type="file"
       #fileInput
       style="display: none"
       accept="image/png,image/jpeg,image/gif"
       (change)="onFilesAdded($event)"
       multiple />
<div *ngIf="preview && preview !== ''"> new serie's thumbnail<img class="imgpreview"
       [src]="preview">
</div>
<!-- <p *ngIf="serieForm.controls.file.errors?.required"
                         style="color: red">This field is required!</p> -->
<div>: {{filename}} </div>
<div class="container">
  <h1 mat-dialog-title>Upload Files</h1>
  <div>
    <button [disabled]="uploading || uploadSuccessful"
            mat-raised-button
            color="primary"
            class="add-files-btn"
            (click)="fileInput.click()">
      <!-- (click)="addFiles()"> -->
      Add Files
    </button>
  </div>
  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content fxFlex>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <h4 mat-line>{{file.name}}</h4>
        <!-- sfrezefo mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress[file.name].progress | async"></mat-progress-bar-->
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>
  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <button *ngIf="showCancelButton"
            mat-button
            mat-dialog-close>Cancel</button>
    <button mat-raised-button
            color="primary"
            [disabled]="!canBeClosed"
            (click)="closeDialog()">{{primaryButtonText}}</button>
  </mat-dialog-actions>
  <div class="progress">

    <mat-progress-bar class="progress-bar"
                      mode="determinate"
                      [value]="uploadProgress"
                      *ngIf="uploadProgress">

    </mat-progress-bar>

    <mat-icon class="cancel-upload"
              (click)="cancelUpload()"
              *ngIf="uploadProgress">delete_forever</mat-icon>

  </div>
</div>