import { NewTextDialogComponent } from '@/edit-photo/new-text-dialog/new-text-dialog.component';
import { Episode } from '@/models/episode';
import { Photo } from '@/models/photo';
import { EpisodeService } from '@/services/episode.service';
import { PhotoService } from '@/services/photo.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-delete-episode-dialog',
  templateUrl: './delete-episode-dialog.component.html',
  styleUrls: ['./delete-episode-dialog.component.scss']
})
export class DeleteEpisodeDialogComponent {


  episode_id: number;

  constructor(
    private dialogRef: MatDialogRef<NewTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private episodeService: EpisodeService,
    private logger: NGXLogger
  ) {
    this.logger.debug('DeletePhotoDialogComponent constructor ' + JSON.stringify(data));
    this.episode_id = data.id;
  }

  ngOnInit(): void {
  }

  save() {
    const newEpisode = new Episode();
    newEpisode.id = this.episode_id;
    this.episodeService.deleteEpisode(newEpisode)
      .subscribe(txt => {
        console.log('delete txt :' + newEpisode.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close('deleted');
  }

  close() {
    this.dialogRef.close();
  }
}
