<mat-card appearance="outlined"
          class="my-card">
    <form [formGroup]="profileForm"
          (ngSubmit)="onSubmit()">
        <mat-card-title>
            Profile
        </mat-card-title>
        <button mat-icon-button
                title="edit my profile">
            <mat-icon aria-label="edit">edit</mat-icon>
        </button>
        <img mat-card-image
             [src]="imageService.ogphotourl(user.photocontainer,user.photoname)|async"
             (click)="openfullscreenimageDialog()">
        alt="desc 1">
        <input type="file"
               id="fileInput"
               (change)="onFileChange($event)"
               placeholder="your picture"
               formControlName="file" />
        <div *ngIf="preview && preview !== ''"> <img mat-card-image
                 [src]="preview">
        </div>
        <mat-label>Your Profile Picture</mat-label>

        <mat-card-content>
            <mat-form-field class="full-width">
                <mat-label>email</mat-label>
                <input matInput
                       type="text"
                       id="email"
                       placeholder="email"
                       formControlName="email"
                       required>
                <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>pseudo</mat-label>
                <input matInput
                       type="text"
                       id="pseudo"
                       placeholder="pseudo"
                       formControlName="pseudo"
                       required>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>Bio</mat-label>
                <input matInput
                       type="text"
                       id="bio"
                       placeholder='bio'
                       formControlName='bio'>
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>first name</mat-label>
                <input matInput
                       type="text"
                       id="first_name"
                       placeholder="first_name"
                       formControlName="first_name">
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-label>last name</mat-label>
                <input matInput
                       type="text"
                       id="last_name"
                       placeholder="last_name"
                       formControlName="last_name">
            </mat-form-field>

            <!-- 
            <p *ngIf="profileForm.controls.file.errors?.required" style="color: red">This field is required!</p>
            <div>: {{fileName}} </div>
 -->
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button
                    matTooltipPosition="above"
                    matTooltip="Cancel Serie creation"
                    [matTooltipShowDelay]="1"
                    [matTooltipHideDelay]="3"
                    color="accent"
                    type="button"
                    (click)="onCancel()">cancel</button>
            <button mat-raised-button
                    color="primary"
                    type="submit">{{buttonText}}</button>
        </mat-card-actions>
    </form>
</mat-card>