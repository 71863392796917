import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


// const httpOptions = {
//   // tslint:disable-next-line:max-line-length
//   //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('serge.frezefond@gmail.com:manager12') })
// };

export class Page<T> {
  count: number;      // total number of items
  next: string;       // URL of the next page
  previous: string;   // URL of the previous page
  results: Array<T>;  // items for the current page
}

export function queryPaginated<T>(http: HttpClient,
  baseUrl: string, urlOrFilter?: string | object): Observable<Page<T>> {
  let params = new HttpParams();
  let url = baseUrl;

  if (typeof urlOrFilter === 'string') {
    // we were given a page URL, use it
    url = urlOrFilter;
  } else if (typeof urlOrFilter === 'object') {
    // we were given filtering criteria, build the query string
    Object.keys(urlOrFilter).sort().forEach(key => {
      const value = urlOrFilter[key];
      if (value !== null) {
        params = params.set(key, value.toString());
      }
    });
  }
  return http.get<Page<T>>(url, { params: params });
}


export function queryPaginatedAll<T>(http: HttpClient, baseUrl: string): Observable<Page<T>> {

  const httpOptions = {
    // tslint:disable-next-line:max-line-length
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('serge.frezefond@gmail.com:manager12') })
  };
  return http.get<Page<T>>(baseUrl, httpOptions);
}

export function queryPaginatedAllLimit<T>(http: HttpClient, baseUrl: string, limit = 50): Observable<Page<T>> {
  const params = new HttpParams().set('limit', String(limit));
  return http.get<Page<T>>(baseUrl, { params: params });
}

