import { Component, Input, OnInit } from '@angular/core';
import { Episode } from '../models/episode';
import { BookmarkService } from '../services/bookmark.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Page } from '../pagination';
import { ImageService } from '@/services/image.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
  @Input() serie_id: number;
  @Input() author_id: number;
  @Input() title = 'My bookmarked episodes';
  @Input() displayMode = 'list'; // possible value list or grid
  episodes: Episode[];
  filterForm: FormGroup;
  // page: Observable<Page<Episode>>;

  constructor(private bookmarkService: BookmarkService,
    private imageService: ImageService) { }

  ngOnInit() {
    this.getMyBookmarkedEpisodes();
  }
  getMyBookmarkedEpisodes(): void {
    this.bookmarkService.getMyBookmarkedEpisodes().subscribe(page => {
      this.episodes = page.results;
      console.log('page : ' + page);
      console.log('page.results : ' + page.results);
      console.log('episodes : ' + this.episodes);
    });
  }


}
