<div class="series">
    <mat-button-toggle-group *ngIf="chooseDisplayMode"
                             name="fontStyle"
                             aria-label="Font Style">
        <mat-button-toggle value="grid"
                           (change)="onValChange($event.value)">
            <mat-icon class="example-tab-icon"
                      aria-label="toggle fullscreen">grid_on</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="list"
                           (change)="onValChange($event.value)">
            <mat-icon class="example-tab-icon"
                      aria-label="toggle fullscreen">list</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="title"
         i18n="serie|my series@@my_series"
         *ngIf="displayTitle">{{title}}</div>

    <mat-list *ngIf="displayMode=='list'"
              class="list">
        <!-- <div mat-subheader
             class="listheader"
             i18n="serie|my series@@my_series"
             *ngIf="displayTitle">{{title}}</div> -->
        <ng-container *ngIf="virtualDisplay else novirtualDisplayList">
            <cdk-virtual-scroll-viewport #virtualViewport1
                                         [itemSize]="85"
                                         class="viewport">
                <div>
                    <div class="addbutton">
                        <div class="centeradd">
                            <button mat-raised-button
                                    color="primary"
                                    *ngIf="authenticationService.isLoggedIn()"
                                    routerLink="/series/new/">
                                <mat-icon aria-label="add serie">add</mat-icon>
                                add Serie
                            </button>
                        </div>
                    </div>
                    <ng-container *ngIf="(series$ | async) as series"
                                  else
                                  loading>
                        <mat-list-item *cdkVirtualFor="let serie of  series"
                                       class="item">
                            <img class="myavatar"
                                 *ngIf="(imageService.ogphotourl(serie.photocontainer,serie.photoname) | async) as imgurl"
                                 routerLink="/series/{{serie.id}}"
                                 [src]="imgurl"
                                 alt="Desc 1">
                            <div routerLink="/series/{{serie.id}}">
                                <div MatListItemTitle>{{serie.name| truncateword : 45}}
                                </div>
                                <!-- <div MatListItemLine> {{ serie.desc| truncateword : 45}}
                    </div> -->
                            </div>
                            <div class="buttons">

                                <button [disabled]="!authenticationService.isLoggedIn()"
                                        mat-icon-button
                                        title="like episode">
                                    <mat-icon [matBadge]="nblikes"
                                              matBadgeColor="warn"
                                              aria-label="like">favorite</mat-icon>
                                </button>

                                <button mat-icon-button
                                        [disabled]="!authenticationService.isLoggedIn()"
                                        class="subscribe"
                                        i18n-title
                                        title="subscribe serie"
                                        i18n="serie|subscription to a serie@@serie_subscription">
                                    <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                                              [matBadge]="nbcomments"
                                              matBadgePosition="after">comment</mat-icon>
                                </button>
                                <button mat-icon-button
                                        class="subscribe"
                                        [disabled]="!authenticationService.isLoggedIn()"
                                        i18n-title
                                        title="subscribe serie"
                                        i18n="serie|subscription to a serie@@serie_subscription">
                                    <mat-icon [matBadge]="nbsubscriptions"
                                              matBadgeColor="warn"
                                              matBadgePosition="after">subscriptions</mat-icon>
                                </button>
                                <button mat-icon-button
                                        class="subscribe"
                                        [disabled]="!authenticationService.isLoggedIn()"
                                        i18n-title
                                        title="rate serie"
                                        i18n="serie|subscription to a serie@@serie_subscription">
                                    <mat-icon [matBadge]="nbratings"
                                              matBadgePosition="after">star</mat-icon>
                                </button>
                                <button [disabled]="!authenticationService.isLoggedIn()"
                                        mat-icon-button>
                                    <mat-icon [matBadge]="nbshares"
                                              matBadgePosition="after"
                                              aria-label="edit">share</mat-icon>
                                </button>
                                <!-- <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                            mat-icon-button
                            (click)="deleteSerieDialog(serie.id)"
                            title="delete serie">
                        <mat-icon aria-label="delete serie">delete</mat-icon>
                    </button>
                    <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                            mat-icon-button
                            routerLink="/editserie/{{serie.id}}"
                            title="edit episode">
                        <mat-icon aria-label="heart icon">edit</mat-icon>
                    </button> -->
                            </div>
                        </mat-list-item>
                    </ng-container>
                    <ng-template #loading>
                        <div style="text-align: center;">
                            <mat-spinner></mat-spinner>
                        </div>
                    </ng-template>
                </div>
            </cdk-virtual-scroll-viewport>
        </ng-container>
        <ng-template #novirtualDisplayList>
            <div>
                <!-- <div class="viewport"> -->
                <div class="addbutton">
                    <div class="centeradd">
                        <button mat-raised-button
                                color="primary"
                                *ngIf="authenticationService.isLoggedIn()"
                                routerLink="/series/new/">
                            <mat-icon aria-label="add serie">add</mat-icon>
                            add Serie
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="(series$ | async) as series">
                    <mat-list-item *ngFor="let serie of series"
                                   class="item">
                        <img class="myavatar"
                             *ngIf="(imageService.ogphotourl(serie.photocontainer,serie.photoname) | async) as imgurl"
                             routerLink="/series/{{serie.id}}"
                             [src]="imgurl"
                             alt="Desc 1">
                        <div routerLink="/series/{{serie.id}}">
                            <div MatListItemTitle>{{serie.name| truncateword : 45}}
                            </div>
                            <!-- <div MatListItemLine> {{ serie.desc| truncateword : 45}}
                                </div> -->
                        </div>
                        <div class="buttons">

                            <button [disabled]="!authenticationService.isLoggedIn()"
                                    mat-icon-button
                                    title="like episode">
                                <mat-icon [matBadge]="nblikes"
                                          matBadgeColor="warn"
                                          aria-label="like">favorite</mat-icon>
                            </button>

                            <button mat-icon-button
                                    [disabled]="!authenticationService.isLoggedIn()"
                                    class="subscribe"
                                    i18n-title
                                    title="subscribe serie"
                                    i18n="serie|subscription to a serie@@serie_subscription">
                                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                                          [matBadge]="nbcomments"
                                          matBadgePosition="after">comment</mat-icon>
                            </button>
                            <button mat-icon-button
                                    class="subscribe"
                                    [disabled]="!authenticationService.isLoggedIn()"
                                    i18n-title
                                    title="subscribe serie"
                                    i18n="serie|subscription to a serie@@serie_subscription">
                                <mat-icon [matBadge]="nbsubscriptions"
                                          matBadgeColor="warn"
                                          matBadgePosition="after">subscriptions</mat-icon>
                            </button>
                            <button mat-icon-button
                                    class="subscribe"
                                    [disabled]="!authenticationService.isLoggedIn()"
                                    i18n-title
                                    title="rate serie"
                                    i18n="serie|subscription to a serie@@serie_subscription">
                                <mat-icon [matBadge]="nbratings"
                                          matBadgePosition="after">star</mat-icon>
                            </button>
                            <button [disabled]="!authenticationService.isLoggedIn()"
                                    mat-icon-button>
                                <mat-icon [matBadge]="nbshares"
                                          matBadgePosition="after"
                                          aria-label="edit">share</mat-icon>
                            </button>
                            <!-- <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                                        mat-icon-button
                                        (click)="deleteSerieDialog(serie.id)"
                                        title="delete serie">
                                    <mat-icon aria-label="delete serie">delete</mat-icon>
                                </button>
                                <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                                        mat-icon-button
                                        routerLink="/editserie/{{serie.id}}"
                                        title="edit episode">
                                    <mat-icon aria-label="heart icon">edit</mat-icon>
                                </button> -->
                        </div>
                    </mat-list-item>
                </ng-container>
            </div>
        </ng-template>
    </mat-list>

    <ng-container *ngIf="virtualDisplay else novirtualDisplay">

        <cdk-virtual-scroll-viewport #virtualViewport2
                                     [itemSize]="0"
                                     class="viewport listcard"
                                     *ngIf="displayMode=='grid'">

            <!-- <div *ngIf="displayMode=='grid'"
             class="listcard"> -->
            <div class="addbutton">
                <div class="centeradd">
                    <button mat-raised-button
                            color="primary"
                            *ngIf="authenticationService.isLoggedIn()"
                            routerLink="/series/new/">
                        <mat-icon aria-label="add serie">add</mat-icon>
                        add Serie
                    </button>
                </div>
            </div>
            <ng-container *ngIf="(series$ | async) as series">
                <mat-card appearance="outlined"
                          *cdkVirtualFor="let serie of series"
                          class="mat-elevation-z8 lcard"
                          [ngClass]="{'handset-portrait': handsetPortrait}">
                    <mat-card-header>
                        <div class="topheader">
                            <mat-card-title routerLink="/series/{{serie.id}}">{{serie.name |
                                truncateword :
                                45
                                }}</mat-card-title>
                            <a routerLink="/authors/{{serie.owner.id}}">
                                <mat-card-subtitle class="author">by
                                    &#64;{{serie.owner.pseudo}}</mat-card-subtitle>
                            </a>
                        </div>
                    </mat-card-header>
                    <mat-card-subtitle class="desc"
                                       routerLink="/series/{{serie.id}}">{{ serie.desc |
                        truncateword :
                        45}}</mat-card-subtitle>

                    <div
                         *ngIf="(imageService.ogphotourl(serie.photocontainer,serie.photoname) | async) as imgurl">
                        <img mat-card-image
                             routerLink="/series/{{serie.id}}"
                             class="image"
                             [src]="imgurl"
                             alt="Desc 1">
                    </div>
                    <mat-card-actions align="end">
                        <button [disabled]="!authenticationService.isLoggedIn()"
                                mat-icon-button
                                title="like episode">
                            <mat-icon [matBadge]="nblikes"
                                      matBadgeColor="warn"
                                      aria-label="like">favorite</mat-icon>
                        </button>

                        <button mat-icon-button
                                [disabled]="!authenticationService.isLoggedIn()"
                                class="subscribe"
                                i18n-title
                                title="subscribe serie"
                                i18n="serie|subscription to a serie@@serie_subscription">
                            <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                                      [matBadge]="nbcomments"
                                      matBadgePosition="after">comment</mat-icon>
                        </button>
                        <button mat-icon-button
                                class="subscribe"
                                [disabled]="!authenticationService.isLoggedIn()"
                                i18n-title
                                title="subscribe serie"
                                i18n="serie|subscription to a serie@@serie_subscription">
                            <mat-icon [matBadge]="nbsubscriptions"
                                      matBadgeColor="warn"
                                      matBadgePosition="after">subscriptions</mat-icon>
                        </button>
                        <button mat-icon-button
                                class="subscribe"
                                [disabled]="!authenticationService.isLoggedIn()"
                                i18n-title
                                title="rate serie"
                                i18n="serie|subscription to a serie@@serie_subscription">
                            <mat-icon [matBadge]="nbratings"
                                      matBadgePosition="after">star</mat-icon>
                        </button>
                        <button [disabled]="!authenticationService.isLoggedIn()"
                                mat-icon-button>
                            <mat-icon [matBadge]="nbshares"
                                      matBadgePosition="after"
                                      aria-label="edit">share</mat-icon>
                        </button>
                        <!-- <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                        mat-icon-button
                        (click)="deleteSerieDialog(serie.id)"
                        title="delete serie">
                    <mat-icon aria-label="delete serie">delete</mat-icon>
                </button>
                <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                        mat-icon-button
                        routerLink="/editserie/{{serie.id}}"
                        title="edit serie">
                    <mat-icon aria-label="edit">edit</mat-icon>
                </button> -->
                    </mat-card-actions>
                </mat-card>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </ng-container>
    <ng-template #novirtualDisplay>
        <div *ngIf="displayMode=='grid'"
             class="listcard">
            <div class="addbutton">
                <div class="centeradd">
                    <button mat-raised-button
                            color="primary"
                            *ngIf="authenticationService.isLoggedIn()"
                            routerLink="/series/new/">
                        <mat-icon aria-label="add serie">add</mat-icon>
                        add Serie
                    </button>
                </div>
            </div>
            <ng-container *ngIf="(series$ | async) as series">
                <mat-card appearance="outlined"
                          *ngFor="let serie of series"
                          class="mat-elevation-z8 lcard"
                          [ngClass]="{'handset-portrait': handsetPortrait}">
                    <mat-card-header>
                        <div class="topheader">
                            <mat-card-title routerLink="/series/{{serie.id}}">{{serie.name |
                                truncateword :
                                45
                                }}</mat-card-title>
                            <a routerLink="/authors/{{serie.owner.id}}">
                                <mat-card-subtitle class="author">by
                                    &#64;{{serie.owner.pseudo}}</mat-card-subtitle>
                            </a>
                        </div>
                    </mat-card-header>
                    <mat-card-subtitle class="desc"
                                       routerLink="/series/{{serie.id}}">{{ serie.desc |
                        truncateword :
                        45}}</mat-card-subtitle>

                    <div
                         *ngIf="(imageService.ogphotourl(serie.photocontainer,serie.photoname) | async) as imgurl">
                        <img mat-card-image
                             routerLink="/series/{{serie.id}}"
                             class="image"
                             [src]="imgurl"
                             alt="Desc 1">
                    </div>
                    <!-- src="{{ imageService.gphotourl(serie.photocontainer,serie.photoname) }}" -->
                    <mat-card-actions align="end">
                        <button [disabled]="!authenticationService.isLoggedIn()"
                                mat-icon-button
                                title="like episode">
                            <mat-icon [matBadge]="serie.nb_likes"
                                      matBadgeColor="warn"
                                      aria-label="like">favorite</mat-icon>
                        </button>

                        <button mat-icon-button
                                [disabled]="!authenticationService.isLoggedIn()"
                                class="subscribe"
                                i18n-title
                                title="subscribe serie"
                                i18n="serie|subscription to a serie@@serie_subscription">
                            <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                                      [matBadge]="serie.nb_comments"
                                      matBadgePosition="after">comment</mat-icon>
                        </button>
                        <button mat-icon-button
                                class="subscribe"
                                [disabled]="!authenticationService.isLoggedIn()"
                                i18n-title
                                title="subscribe serie"
                                i18n="serie|subscription to a serie@@serie_subscription">
                            <mat-icon [matBadge]="serie.nb_subscribtions"
                                      matBadgeColor="warn"
                                      matBadgePosition="after">subscriptions</mat-icon>
                        </button>
                        <button mat-icon-button
                                class="subscribe"
                                [disabled]="!authenticationService.isLoggedIn()"
                                i18n-title
                                title="rate serie"
                                i18n="serie|subscription to a serie@@serie_subscription">
                            <mat-icon [matBadge]="serie.nb_raters"
                                      matBadgePosition="after">star</mat-icon>
                        </button>
                        <button [disabled]="!authenticationService.isLoggedIn()"
                                mat-icon-button>
                            <mat-icon [matBadge]="nbshares"
                                      matBadgePosition="after"
                                      aria-label="edit">share</mat-icon>
                        </button>
                        <!-- <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                                mat-icon-button
                                (click)="deleteSerieDialog(serie.id)"
                                title="delete serie">
                            <mat-icon aria-label="delete serie">delete</mat-icon>
                        </button>
                        <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                                mat-icon-button
                                routerLink="/editserie/{{serie.id}}"
                                title="edit serie">
                            <mat-icon aria-label="edit">edit</mat-icon>
                        </button> -->
                    </mat-card-actions>
                </mat-card>
            </ng-container>
        </div>
    </ng-template>
</div>