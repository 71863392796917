import { BlobUploadsViewStateService } from '@/azure-storage/services/blob-uploads-view-state.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, catchError, of, shareReplay } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { MessageService } from './message.service';
import { PhotoService } from './photo.service';
import { SerieService } from './serie.service';

@Injectable({
  providedIn: 'root'
})
export class SastokenService {
  private getreadsastokenurl;
  private getwritesastokenurl;
  private getsastokenurl;
  private getstorageinfourl;
  public readsastoken$: Observable<string>;
  public writesastoken$: Observable<string>;
  // public writesastoken: string;
  private httpOptions = {
  };
  constructor(private appconfigService: AppConfigService,
    private http: HttpClient,
    private blobState: BlobUploadsViewStateService,
    private serieService: SerieService,
    private messageService: MessageService,
    private photoService: PhotoService,
    private logger: NGXLogger) {
    this.logger.debug('SastokenService service constructor');
    this.getreadsastokenurl = this.appconfigService.config.apiUrl + '/getreadsastoken';
    this.getwritesastokenurl = this.appconfigService.config.apiUrl + '/getwritesastoken';
    this.getsastokenurl = this.appconfigService.config.apiUrl + '/getsastoken';
    this.getstorageinfourl = this.appconfigService.config.apiUrl + '/getstorageinfo';
    this.readsastoken$ = this.getreadsastoken().pipe(shareReplay());
    this.writesastoken$ = this.getwritesastoken().pipe(shareReplay());
  }

  public getreadsastoken(): Observable<string> {
    this.logger.debug('getreadsastoken');
    return this.http.get<any>(this.getreadsastokenurl, this.httpOptions).pipe(map(res =>
      res.storageAccessToken

    ), catchError(this.handleError<string>('getreadsastoken', 'jdshfkjhf')));;
  }

  public getwritesastoken(): Observable<string> {
    this.logger.debug('getwritesastoken');
    return this.http.get<any>(this.getwritesastokenurl, this.httpOptions).pipe(map(res => {
      return res.storageAccessToken;
    }
    ));;
  }

  // public getsastoken(): Observable<Sast> {
  public getsastoken(): Observable<string> {
    this.logger.debug('getsastoken');
    // return this.http.get<Sast>(this.getsastokenurl,
    return this.http.get<any>(this.getsastokenurl,
      this.httpOptions
    ).pipe(map(res => {
      return res.storageAccessToken;
    }
    ));
  }

  // public getsastoken(): Observable<Sast> {
  public getStorageInfo(): Observable<string[]> {
    this.logger.debug('getStorageInfo');
    // return this.http.get<Sast>(this.getsastokenurl,
    return this.http.get<string[]>(this.getstorageinfourl,
      this.httpOptions
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('serieService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add('UploadService: ' + message);
  }
}