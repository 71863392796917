import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private http: HttpClient,
    public translate: TranslateService,
    private logger: NGXLogger) { }


  getiCurrentLanguage(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (localStorage.getItem('locale')) {
      this.translate.setDefaultLang(localStorage.getItem('locale'));
      this.translate.use(localStorage.getItem('locale'));
      this.logger.debug('getseri locale found ' + localStorage.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      localStorage.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + localStorage.getItem('locale'));
    }
    return lang;
  }

  getAvailableLanguages(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (localStorage.getItem('locale')) {
      this.translate.setDefaultLang(localStorage.getItem('locale'));
      this.translate.use(localStorage.getItem('locale'));
      this.logger.debug('getseri locale found ' + localStorage.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      localStorage.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + localStorage.getItem('locale'));
    }
    return lang;
  }

  public setLanguage(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (localStorage.getItem('locale')) {
      this.translate.setDefaultLang(localStorage.getItem('locale'));
      this.translate.use(localStorage.getItem('locale'));
      this.logger.debug('getseri locale found ' + localStorage.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      localStorage.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + localStorage.getItem('locale'));
    }
    return lang;
  }

  public changeLanguage(language: string): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (localStorage.getItem('locale')) {
      this.translate.setDefaultLang(localStorage.getItem('locale'));
      this.translate.use(localStorage.getItem('locale'));
      this.logger.debug('getseri locale found ' + localStorage.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      localStorage.setItem('locale', 'fr');
      this.logger.debug('getserie component  locale not found ' + localStorage.getItem('locale'));
    }
    return lang;
  }
}
