import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

import { Comment } from '../models/comment';
import { MessageService } from './message.service';
import { Page, queryPaginated, queryPaginatedAllLimit } from '../pagination';
import { AuthenticationService } from '../_services/authentication.service';
import { User } from '@/_models';
import { NGXLogger } from 'ngx-logger';
import { UrlService } from './url.service';
import { TranslateService } from '@ngx-translate/core';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 'Authorization': 'Basic ' +
      btoa('serge.frezefond@gmail.com:manager12')
  })
};

@Injectable({ providedIn: 'root' })
export class CommentService {

  private _comments = new BehaviorSubject<Comment[]>([]);
  comments$: Observable<Comment[]> = this._comments.asObservable();

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public translate: TranslateService,
    private urlService: UrlService,
    private authenticationService: AuthenticationService,
    private logger: NGXLogger,
  ) {
  }


  getMyCommentsPage(user_id: number): Observable<Page<Comment>> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getCommentsUrl() + 'mycomments', 100);
  }
  getMyComments(): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getCommentsUrl() + 'mycomments', 100).pipe(
      map(events => {
        return events.results;
      }),
      tap(() => this.log(`fetched my comments`)),
      catchError(this.handleError('my comments', []))
    );
  }

  /** GET episodes from the server */
  getEpisodeCommentsPage(episode_id: number): Observable<Page<Comment>> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getEpisodesUrl() + episode_id + '/comments/?ordering=-id', 100).pipe(
      shareReplay()
    );
  }


  /** GET episodes from the server */
  getEpisodeComments(episode_id: number): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getEpisodesUrl() + episode_id + '/comments/?ordering=-id', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        shareReplay(),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getComments(): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.urlService.getCommentsUrl(), httpOptions)
      .pipe(
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getCommentsList(urlOrFilter?: string | object): Observable<Page<Comment>> {
    return queryPaginated<Comment>(this.http, this.urlService.getCommentsUrl(), urlOrFilter);
  }

  getcommentwriter(comment_id: number): Observable<User> {
    return this.http.get<User>(this.urlService.getCommentsUrl() + comment_id + '/writer/').pipe(
      tap(_ => this.log(`fetched episodes`)),
      catchError(this.handleError<User>('getEpisodes'))
    );
  }


  addComment(comment: Comment): Observable<Comment> {
    this.log(`comment to add: ${comment}`);

    comment.initial_language = this.translate.currentLang;
    return this.http.post<Comment>(this.urlService.getCommentsUrl(), comment, httpOptions).pipe(
      tap((rcomment: Comment) => this.log(`added comment id=${rcomment.id}`)),
      catchError(this.handleError<Comment>('addComment'))
    );
  }

  deleteComment(comment: Comment | number): Observable<Comment> {
    const id = typeof comment === 'number' ? comment : comment.id;
    const url = `${this.urlService.getCommentsUrl()}${id}/`;
    return this.http.delete<Comment>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted episode id=${id}`)),
      catchError(this.handleError<Comment>('deleteEpisode'))
    );
  }


  likeComment(comment: Comment): Observable<any> {
    // this.authenticationService.currentUserValue.id,

    return this.http.post(this.urlService.getLikesUrl(),
      {
        owner: this.authenticationService.currentUserValue.id,
        // to_photo: photo.id
        to_content_object: this.urlService.getApiUrlPath() + `comments/${comment.id}/`
      }, httpOptions);
  }

  islikedbyme(commentId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getCommentsUrl() + commentId + '/islikedbyme/', httpOptions);
  }
  iscommentedbyme(commentId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getCommentsUrl() + commentId + '/iscommentedbyme/', httpOptions);
  }

  nblikes(commentId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getCommentsUrl() + commentId + '/nblikes/',
      httpOptions);
  }
  nbcomments(commentId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getCommentsUrl() + commentId + '/nbcomments/',
      httpOptions);
  }
  getCommentComments(comment_id: number): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getCommentsUrl() + comment_id + '/commentcomments/?ordering=-id', 100)
      .pipe(
        map(events => {
          return events.results;
        }
        ),
        shareReplay(),
        tap(episodes => this.logger.debug(`fetched comment's comments`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a EpisodeService message with the MessageService */
  private log(message: string) {
    this.messageService.add('EpisodeService: ' + message);
  }
}
