<mat-dialog-content [formGroup]="texteForm">
    <mat-form-field>
        <mat-select [formControl]="texteForm.controls['type']"
                    matNativeControl
                    required>
            <!-- /mat-select>placeholder=" Type">-->
            <mat-option [value]=1>
                parole
            </mat-option>
            <mat-option [value]=2>
                pensée
            </mat-option>
            <mat-option [value]=3>
                texte
            </mat-option>
            <mat-option [value]=4>
                texte no border
            </mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button id="id_parolebtn"
            (click)="close()"
            class="btn btn-primary">close</button>
    <button id="id_parolebtn"
            (click)="save()"
            class="mat-raised-button  btn btn-primary">submit</button>
</mat-dialog-actions>