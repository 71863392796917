import { CommentService } from '@/services/comment.service';
import { AuthenticationService } from '@/_services';
import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Comment } from '../models/comment';

@Component({
  selector: 'app-mycomments',
  templateUrl: './mycomments.component.html',
  styleUrls: ['./mycomments.component.scss']
})
export class MycommentsComponent implements OnInit {
  user_id: string;
  mycomments$: Observable<Comment[]>;
  constructor(
    private authenticationService: AuthenticationService,
    private commentService: CommentService,
    private logger: NGXLogger,
  ) { }
  ngOnInit() {
    this.mycomments$ = this.commentService.getMyComments();
  }

}
