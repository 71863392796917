<form *ngIf="!sent"
      class="my-form"
      [formGroup]="resetpasswordForm"
      (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined" class="my-card">

        <mat-card-title>
            New oPassword </mat-card-title>
        <div class="row">
            <div>
                <p *ngIf="verifying"
                   class="alert alert-warning text-center">We are verifying your password reset link
                </p>
                <p class="alert alert-success"
                   *ngIf="reset">Your new password has been set, you may now login.</p>
                <div *ngIf="verificationFailed"
                     class="alert alert-danger">
                    This password reset link is not valid, please request a new reset link.
                </div>
            </div>
        </div>
        <mat-card-content>

            <mat-form-field class="full-width">
                <mat-label>New Password</mat-label>
                <input matInput
                       placeholder="password1"
                       name="password1"
                       formControlName='password1'
                       [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
            <mat-form-field class="full-width">

                <mat-label>Confirm New Password</mat-label>
                <input matInput
                       placeholder="password2"
                       name="password2"
                       formControlName='password2'
                       [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix
                          (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
            </mat-form-field>
            <p class="alert alert-danger"
               *ngIf="error">{{error}}</p>
            <button mat-raised-button
                    type=" submit"
                    color="primary">Set New Password</button>
        </mat-card-content>
    </mat-card>
</form>