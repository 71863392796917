<div class="comments">

    <!-- <li *ngFor="let comment of comments" [ngClass]="{'selectedlistitem':selectedItem === texte}" (click)="selectItem(texte)">
        <span class="badge">{{comment.id}} </span> {{comment.text}}
    </li> -->

    <app-comment-form *ngIf="authenticationService.isLoggedIn()"
                      [comment_type]="comment_type"
                      [object_id]="object_id"
                      (newComment)="newcomment($event)"></app-comment-form>
    <!-- <ng-container *ngIf="(comments$ | async) as comments"> -->
    <!-- 
    <ng-container *ngFor="let comment of (comments$ | async)  as comments ; let i = index">
        <app-comment [comment]="comment"
                     [episode_id]="episode_id"></app-comment>
    </ng-container> -->

    <!-- 
            <mat-button-toggle (click)="togglePanel[i] = !togglePanel[i]">Reply</mat-button-toggle>
        <app-reply-form [commentId]="comment._id" *ngIf="togglePanel[i]"></app-reply-form>
-->
    <!-- <mat-divider></mat-divider> -->
    <app-comments-list [comment_type]="comment_type"
                       [object_id]="object_id"
                       [comments]="(comments$ | async)"></app-comments-list>
    <!-- </ng-container> -->
</div>