<div class="episodes">
  <mat-button-toggle-group *ngIf="chooseDisplayMode"
                           name="fontStyle"
                           aria-label="Font Style">
    <mat-button-toggle value="grid"
                       (change)="onValChange($event.value)">
      <mat-icon class="example-tab-icon"
                aria-label="toggle fullscreen">grid_on</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="list"
                       (change)="onValChange($event.value)">
      <mat-icon class="example-tab-icon"
                aria-label="toggle fullscreen">list</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div class="title"
       i18n="serie|my series@@my_series"
       *ngIf="displayTitle">{{title}}</div>
  <mat-list *ngIf="displayMode=='list'"
            class="list">
    <!-- <div mat-subheader
         class="listheader"
         i18n="serie|my series@@my_series"
         *ngIf="displayTitle">{{title}}</div> -->
    <!-- <div class="listheader"
         i18n="serie|my series@@my_series">{{title}}</div> -->
    <ng-container *ngIf="virtualDisplay else novirtualDisplayList">
      <cdk-virtual-scroll-viewport [itemSize]="85"
                                   class="viewport">
        <div class="addbutton"
             *ngIf="(serie$ | async) as serie">
          <!-- *ngIf="serie_id"> -->
          <!-- {{serie.owner.id}} -->
          <div class="centeradd"
               *ngIf="authenticationService.isOwner(serie.owner.id)">
            <!-- <a *ngIf="authenticationService.isLoggedIn()"
             mat-mini-fab
          </a> -->
            <button mat-raised-button
                    color="primary"
                    *ngIf="authenticationService.isLoggedIn()"
                    routerLink="/episodes/new/{{serie_id}}">
              <mat-icon aria-label="add serie">add</mat-icon>
              add Episode
            </button>
          </div>
        </div>
        <ng-container *ngIf="(episodes$ | async) as episodes">
          <mat-list-item *cdkVirtualFor="let episode of episodes"
                         routerLink="/episodes/{{episode.id}}"
                         class="item">
            <!-- src="{{ imageService.gphotourl(episode.photocontainer,episode.photoname) }}" -->
            <img class="myavatar"
                 *ngIf="((imageService.ogphotourl(episode.photocontainer,episode.photoname)) | async) as imgurl"
                 [src]="imgurl"
                 alt="Desc 1">
            <div class="itemcontent">
              <div MatListItemTitle>{{episode.title | truncateword :
                45}}
              </div>
              <!-- <div MatListItemLine> {{ (episode.desc.length>45)? (episode.desc |
            slice:0:45)+'...':(episode.desc) }}
          </div> -->
            </div>
            <!-- <div *ngIf="authenticationService.isLoggedIn()">
            <button (click)="like(episode)"
                    mat-button>
              <mat-icon aria-label="E">favorite</mat-icon>
            </button>
            <button mat-icon-button
                    title="bookmark episode"
                    (click)="bookmark(episode)">
              <mat-icon aria-label="Example icon-button with a heart icon">bookmark</mat-icon>
            </button>
          </div> -->
            <div>
              <!-- *ngIf="authenticationService.isLoggedIn()"> -->

              <button [disabled]="!authenticationService.isLoggedIn()"
                      mat-icon-button
                      title="like episode">
                <mat-icon [matBadge]="episode.nb_likes"
                          matBadgeColor="warn"
                          aria-label="like">favorite</mat-icon>
              </button>

              <button mat-icon-button
                      [disabled]="!authenticationService.isLoggedIn()"
                      class="subscribe"
                      i18n-title
                      title="comments"
                      i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="episode.nb_comments"
                          matBadgePosition="after">comment</mat-icon>
              </button>

              <button mat-icon-button
                      [disabled]="!authenticationService.isLoggedIn()"
                      title="bookmark episode">
                <mat-icon [matBadge]="nbbookmarks"
                          matBadgeColor="warn"
                          matBadgePosition="after"
                          aria-label="Example icon-button with a heart icon">bookmark</mat-icon>
              </button>
              <button [disabled]="!authenticationService.isLoggedIn()"
                      mat-icon-button>
                <mat-icon [matBadge]="nbshares"
                          matBadgePosition="after"
                          aria-label="edit">share</mat-icon>
              </button>
              <!-- <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                  mat-icon-button
                  (click)="deleteEpisodeDialog(episode.id)"
                  title="delete episode">
            <mat-icon aria-label=" delete episode">delete</mat-icon>
          </button>
          <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                  mat-icon-button
                  routerLink="/editepisode/{{episode.id}}"
                  title="edit episode">
            <mat-icon aria-label="heart icon">edit</mat-icon>
          </button> -->

            </div>
          </mat-list-item>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </ng-container>
    <ng-template #novirtualDisplayList>
      <div class="addbutton"
           *ngIf="(serie$ | async) as serie">
        <!-- *ngIf="serie_id"> -->
        <!-- {{serie.owner.id}} -->
        <div class="centeradd"
             *ngIf="authenticationService.isOwner(serie.owner.id)">
          <!-- <a *ngIf="authenticationService.isLoggedIn()"
                   mat-mini-fab
                </a> -->
          <button mat-raised-button
                  color="primary"
                  *ngIf="authenticationService.isLoggedIn()"
                  routerLink="/episodes/new/{{serie_id}}">
            <mat-icon aria-label="add serie">add</mat-icon>
            add Episode
          </button>
        </div>
      </div>
      <ng-container *ngIf="(episodes$ | async) as episodes">
        <mat-list-item *ngFor="let episode of episodes"
                       routerLink="/episodes/{{episode.id}}"
                       class="item">
          <img class="myavatar"
               *ngIf="((imageService.ogphotourl(episode.photocontainer,episode.photoname)) | async) as imgurl"
               [src]="imgurl"
               alt="Desc 1">
          <div class="itemcontent">
            <div MatListItemTitle>{{episode.title | truncateword :
              45}}
            </div>
            <!-- <div MatListItemLine> {{ (episode.desc.length>45)? (episode.desc |
                  slice:0:45)+'...':(episode.desc) }}
                </div> -->
          </div>
          <div>
            <!-- *ngIf="authenticationService.isLoggedIn()"> -->

            <button [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button
                    title="like episode">
              <mat-icon [matBadge]="episode.nb_likes"
                        matBadgeColor="warn"
                        aria-label="like">favorite</mat-icon>
            </button>

            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    class="subscribe"
                    i18n-title
                    title="comments"
                    i18n="serie|subscription to a serie@@serie_subscription">
              <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                        [matBadge]="episode.nb_comments"
                        matBadgePosition="after">comment</mat-icon>
            </button>

            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    title="bookmark episode">
              <mat-icon [matBadge]="episode.nb_bookmarks"
                        matBadgeColor="warn"
                        matBadgePosition="after"
                        aria-label="Example icon-button with a heart icon">bookmark</mat-icon>
            </button>
            <button [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button>
              <mat-icon [matBadge]="nbshares"
                        matBadgePosition="after"
                        aria-label="edit">share</mat-icon>
            </button>
          </div>
        </mat-list-item>
      </ng-container>
    </ng-template>
  </mat-list>



  <!--
<button mat-mini-fab>
  <mat-icon aria-label="Example icon-button with a heart icon"> sentiment_satisfied</mat-icon>
</button>
<button mat-mini-fab>
  <mat-icon aria-label="Example icon-button with a heart icon">thumb_up_alt</mat-icon>
</button>
-->

  <!-- <button mat-mini-fab
        routerLink="/episodes/new/{{serie_id}}"
        title="add">
  <mat-icon>add</mat-icon>
</button> -->

  <!-- <a mat-mini-fab
        *ngIf="authenticationService.isLoggedIn()"
   routerLink="/episodes/new/{{serie_id}}"
   [queryParams]="{maxno: episodes?.length || '0'}">
  <mat-icon aria-label="add episode">add</mat-icon>
</a> -->
  <ng-container *ngIf="virtualDisplay else novirtualDisplay">
    <cdk-virtual-scroll-viewport #virtualViewport2
                                 [itemSize]="0"
                                 class="viewport listcard"
                                 *ngIf="displayMode=='grid'">
      <div class="addbutton"
           *ngIf="serie_id">
        <div class="centeradd">
          <!-- <a *ngIf="authenticationService.isLoggedIn()"
           mat-mini-fab
           routerLink="/episodes/new/">
          <mat-icon aria-label="add serie">add</mat-icon>
        </a> -->
          <button mat-raised-button
                  color="primary"
                  *ngIf="authenticationService.isLoggedIn()"
                  routerLink="/episodes/new/{{serie_id}}">
            <mat-icon aria-label="add serie">add</mat-icon>
            add Episode
          </button>
        </div>
      </div>
      <ng-container *ngIf="(episodes$ | async) as episodes">
        <mat-card appearance="outlined"
                  *cdkVirtualFor="let episode of episodes"
                  class="mat-elevation-z8 lcard"
                  [ngClass]="{'handset-portrait': handsetPortrait}">
          <mat-card-header>

            <div class="topheader">
              <!-- <mat-card-title routerLink="/episodes/{{episode.id}}">{{episode.title | truncateword : 32 -->
              <mat-card-title routerLink="/episodes/{{episode.id}}">{{episode.title | truncateword :
                50
                }}
              </mat-card-title>
              <a routerLink="/authors/{{episode.owner.id}}">
                <mat-card-subtitle class="author">by
                  &#64;{{episode.owner.pseudo}}</mat-card-subtitle>
              </a>
            </div>
            <!-- <img mat-card-avatar
                     class="myavatar"
                     src="{{ imageService.gphotourl(episode.photocontainer,episode.photoname) }}"> -->
          </mat-card-header>
          <mat-card-subtitle class="desc"
                             routerLink="/episodes/{{episode.id}}">{{episode.desc | truncateword :
            50}}
          </mat-card-subtitle>
          <img mat-card-image
               class="image"
               routerLink="/episodes/{{episode.id}}"
               *ngIf="((imageService.ogphotourl(episode.photocontainer,episode.photoname)) | async) as imgurl"
               [src]="imgurl"
               alt="Nice Photo">
          <mat-card-actions align="end">
            <div>
              <button mat-icon-button
                      title="like episode"
                      [disabled]="!authenticationService.isLoggedIn()"
                      (click)="like(episode)">
                <mat-icon matBadge="15"
                          matBadgeColor="warn"
                          matBadgePosition="after"
                          aria-label="Example icon-button with a heart icon">favorite</mat-icon>
              </button>
              <button mat-icon-button
                      [disabled]="!authenticationService.isLoggedIn()"
                      class="subscribe"
                      i18n-title
                      title="comments"
                      i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="nbcomments"
                          matBadgePosition="after">comment</mat-icon>
              </button>
              <button mat-icon-button
                      title="bookmark episode"
                      [disabled]="!authenticationService.isLoggedIn()"
                      (click)="bookmark(episode)">
                <mat-icon matBadge="15"
                          matBadgeColor="warn"
                          matBadgePosition="after"
                          aria-label="Example icon-button with a heart icon">bookmark</mat-icon>
              </button>
              <button [disabled]="!authenticationService.isLoggedIn()"
                      mat-icon-button
                      (click)="openShareDialog()">
                <mat-icon [matBadge]="13"
                          matBadgePosition="after"
                          aria-label="edit">share</mat-icon>
              </button>
              <!-- <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                  mat-icon-button
                  (click)="deleteEpisodeDialog(episode.id)"
                  title="delete episode">
            <mat-icon aria-label=" delete episode">delete</mat-icon>
          </button>
          <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                  mat-icon-button
                  routerLink="/editepisode/{{episode.id}}"
                  title="edit episode">
            <mat-icon aria-label="heart icon">edit</mat-icon>
          </button> -->
            </div>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </ng-container>
  <ng-template #novirtualDisplay>
    <div *ngIf="displayMode=='grid'"
         class="listcard">
      <div class="addbutton"
           *ngIf="serie_id">
        <div class="centeradd">
          <!-- <a *ngIf="authenticationService.isLoggedIn()"
                 mat-mini-fab
                 routerLink="/episodes/new/">
                <mat-icon aria-label="add serie">add</mat-icon>
              </a> -->
          <button mat-raised-button
                  color="primary"
                  *ngIf="authenticationService.isLoggedIn()"
                  routerLink="/episodes/new/{{serie_id}}">
            <mat-icon aria-label="add serie">add</mat-icon>
            add Episode
          </button>
        </div>
      </div>
      <ng-container *ngIf="(episodes$ | async) as episodes">
        <mat-card appearance="outlined"
                  *ngFor="let episode of episodes"
                  class="mat-elevation-z8 lcard"
                  [ngClass]="{'handset-portrait': handsetPortrait}">
          <mat-card-header>

            <div class="topheader">
              <!-- <mat-card-title routerLink="/episodes/{{episode.id}}">{{episode.title | truncateword : 32 -->
              <mat-card-title routerLink="/episodes/{{episode.id}}">{{episode.title | truncateword :
                50
                }}
              </mat-card-title>
              <a routerLink="/authors/{{episode.owner.id}}">
                <mat-card-subtitle class="author">by
                  &#64;{{episode.owner.pseudo}}</mat-card-subtitle>
              </a>
            </div>
            <!-- <img mat-card-avatar
                           class="myavatar"
                           src="{{ imageService.gphotourl(episode.photocontainer,episode.photoname) }}"> -->
          </mat-card-header>
          <mat-card-subtitle class="desc"
                             routerLink="/episodes/{{episode.id}}">{{episode.desc | truncateword :
            50}}
          </mat-card-subtitle>
          <img mat-card-image
               class="image"
               routerLink="/episodes/{{episode.id}}"
               *ngIf="((imageService.ogphotourl(episode.photocontainer,episode.photoname)) | async) as imgurl"
               [src]="imgurl"
               alt="Nice Photo">
          <mat-card-actions align="end">
            <div>
              <button mat-icon-button
                      title="like episode"
                      [disabled]="!authenticationService.isLoggedIn()"
                      (click)="like(episode)">
                <mat-icon [matBadge]="episode.nb_likes"
                          matBadgeColor="warn"
                          matBadgePosition="after"
                          aria-label="Example icon-button with a heart icon">favorite</mat-icon>
              </button>
              <button mat-icon-button
                      [disabled]="!authenticationService.isLoggedIn()"
                      class="subscribe"
                      i18n-title
                      title="comments"
                      i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="episode.nb_comments"
                          matBadgePosition="after">comment</mat-icon>
              </button>
              <button mat-icon-button
                      title="bookmark episode"
                      [disabled]="!authenticationService.isLoggedIn()"
                      (click)="bookmark(episode)">
                <mat-icon [matBadge]="episode.nb_bookmarks"
                          matBadgeColor="warn"
                          matBadgePosition="after"
                          aria-label="Example icon-button with a heart icon">bookmark</mat-icon>
              </button>
              <button [disabled]="!authenticationService.isLoggedIn()"
                      mat-icon-button
                      (click)="openShareDialog()">
                <mat-icon [matBadge]="13"
                          matBadgePosition="after"
                          aria-label="edit">share</mat-icon>
              </button>
              <!-- <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                        mat-icon-button
                        (click)="deleteEpisodeDialog(episode.id)"
                        title="delete episode">
                  <mat-icon aria-label=" delete episode">delete</mat-icon>
                </button>
                <button *ngIf="authenticationService.isOwner(episode.owner.id)"
                        mat-icon-button
                        routerLink="/editepisode/{{episode.id}}"
                        title="edit episode">
                  <mat-icon aria-label="heart icon">edit</mat-icon>
                </button> -->

            </div>
          </mat-card-actions>

          <mat-card-subtitle class="desc"><a routerLink="/series/{{episode.serie.id}}">serie :
              {{episode.serie.name
              |
              truncateword :
              50}}
            </a>
          </mat-card-subtitle>
        </mat-card>
      </ng-container>
    </div>
  </ng-template>
</div>