<div *ngIf="photos"
     class="listphotos">

  <ng-container *ngFor="let photo of photos; let ind = index;">
    <!-- <ng-container *ngIf="photos"> -->
    <!-- <ng-container *ngFor="let photo of photos"> -->
    <div class="fullphoto">
      <div id="{{ 'divimage' + photo.id }}"
           class="image">
        <!-- <div *ngFor="let photo of photos"
       class="photow"> -->
        <!-- <app-edit-photo photo="photo"> </app-edit-photo> -->
        <!-- <a routerLink="/photos/{{photo.id}}"> -->
        <!-- <a [routerLink]="['/editphoto/'+photo.id,{episodeMode: 'noedit'}]"> -->
        <a [routerLink]="['/editphoto/'+photo.id,{episodeMode: 'editphotoshoot'}]">
          <img *ngIf="(imageService.ogphotourl(photo.photocontainer,photo.photoname) | async) as imgurl"
               [src]="imgurl"
               id="{{ 'myimg' + photo.id }}"
               class="photo"
               alt="Smiley face">
          <div id="{{ 'textimg' + photo.id }}"
               class="textimg">
          </div>
          <svg #mySvg
               id="{{ 'svg' + photo.id }}"
               xmlns="http://www.w3.org/2000/svg"
               style="position:absolute;top:0px;left:0px"
               zindex="9"
               width="100%"
               height="100%">
            <filter id='shadow'
                    color-interpolation-filters="sRGB">
              <feDropShadow dx="3"
                            dy="3"
                            stdDeviation="5"
                            flood-opacity="0.7" />
            </filter>
          </svg>
        </a>
      </div>
      <div class="byauthor">
        <a routerLink="/authors/{{photo.owner.id}}">
          by &#64;{{photo.owner.pseudo}}
        </a>
      </div>
    </div>
  </ng-container>
</div>
<!-- <span class="badge">{{photo.id}}</span> {{photo.desc}} -->
<!-- <app-carousssel [episode_id]="episode_id"></app-carousssel> -->
<!-- <button class="delete"
                title="delete photo"
                (click)="delete(photo)">x</button> -->