import { DialogData } from '@/forgot-password';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-resend-email-verification-done-dialog',
  templateUrl: './resend-email-verification-done-dialog.component.html',
  styleUrls: ['./resend-email-verification-done-dialog.component.scss']
})
export class ResendEmailVerificationDoneDialogComponent {


  constructor(public dialogRef: MatDialogRef<ResendEmailVerificationDoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('80%', '60%');

  }

  onClick(): void {
    this.dialogRef.close();
  }
}
