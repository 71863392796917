import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Episode } from '../models/episode';
import { Comment } from '../models/comment';
import { MessageService } from './message.service';
import { Page, queryPaginated, queryPaginatedAllLimit } from '../pagination';
import { AuthenticationService } from '../_services/authentication.service';
import { Author } from '@/models/author';
import { NGXLogger } from 'ngx-logger';
import { UrlService } from './url.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusType } from '@/models/serie';

// const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 'Authorization': 'Basic ' +
      btoa('serge.frezefond@gmail.com:manager12')
  })
};

@Injectable({ providedIn: 'root' })
export class EpisodeService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private urlService: UrlService,
    private logger: NGXLogger
  ) {
    this.logger.debug('constructor episodeservice  ');
  }


  getAuthorEpisodes(author_id: number): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getAuthorsUrl() + author_id + '/episodes/' + '?status=' + StatusType.PUBLIC_STATUS, 100).pipe(
      map(events => {
        return events.results;
      }),
      tap(episodes => this.log(`fetched episodes`)),
      catchError(this.handleError('getcEpisodes', []))
    );
  }


  /** GET episodes from the server */
  getSerieEpisodes(serie_id: number, status: number): Observable<Episode[]> {
    // return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getSeriesUrl() + serie_id + '/episodes/' + '?status=' + status, 100)
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getSeriesUrl() + serie_id + '/episodes/' + '?ordering=numero&status=' + StatusType.PUBLIC_STATUS, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        shareReplay(1),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getEpisodes(): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl() + '?status=' + StatusType.PUBLIC_STATUS, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        map(episodes => episodes.filter(
          episode => episode.id != 1
        )
        ),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getMyEpisodes(): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl() + '?owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        map(episodes => episodes.filter(
          episode => episode.id != 1
        )
        ),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }
  getMyPublicEpisodes(): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl() + '?status=1' + '&owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }
  getMyDraftEpisodes(): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl() + '?status=2' + '&owner=' + this.authenticationService.currentUserValue.id, 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }
  getMyLikedEpisodes(): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl() + 'mylikedepisodes/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getMySharedEpisodes(): Observable<Episode[]> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl() + 'mysharedepisodes/', 100)
      .pipe(
        map(events => {
          return events.results;
        }),
        tap(episodes => this.log(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  getEpisodesList(urlOrFilter?: string | object): Observable<Page<Episode>> {
    return queryPaginated<Episode>(this.http, this.urlService.getEpisodesUrl(), urlOrFilter);
  }

  getEpisodesListAll(): Observable<Page<Episode>> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl(), 100);
  }

  /** GET episode by id. Return `undefined` when id not found */
  getEpisodeNo404<Data>(id: number): Observable<Episode> {
    const url = this.urlService.getEpisodesUrl() + `?id=${id}/`;
    return this.http.get<Episode[]>(url)
      .pipe(
        map(episodes => episodes[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} episode id=${id}`);
        }),
        catchError(this.handleError<Episode>(`getEpisode id=${id}`))
      );
  }

  /** GET episode by id. Will 404 if id not found */
  getEpisode(id: number): Observable<Episode> {
    const url = this.urlService.getEpisodesUrl() + `${id}/`;
    return this.http.get<Episode>(url, httpOptions).pipe(
      tap(_ => this.log(`fetched episode id=${id}`)),
      shareReplay(1),
      catchError(this.handleError<Episode>(`getEpisode id=${id}`))
    );
  }

  /** GET author of episode by id. Will 404 if id not found */
  getEpisodeAuthor(episodeid: number): Observable<Author> {
    const episodeauthorUrl = this.urlService.getEpisodesUrl() + episodeid + '/author';
    //    const url = `${this.episodesuthorUrl}${id}/`;
    return this.http.get<Author>(episodeauthorUrl, httpOptions).pipe(
      tap(_ => this.log(`fetched episode id=${episodeid}`)),
      catchError(this.handleError<Author>(`getEpisode id=${episodeid}`))
    );
  }

  /* GET episodes whose name contains search term */
  searchEpisodes(term: string): Observable<Episode[]> {
    if (!term.trim()) {
      // if not search term, return empty episode array.
      return of([]);
    }
    return this.http.get<Episode[]>(this.urlService.getEpisodesUrl() + `?name=${term}`).pipe(
      tap(_ => this.log(`found episodes matching "${term}"`)),
      catchError(this.handleError<Episode[]>('searchEpisodes', []))
    );
  }


  getEpisodeComments(episode_id: number): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getEpisodesUrl() + episode_id + '/comments/?ordering=-id', 100)
      .pipe(
        map(events => {
          return events.results;
        }
        ),
        // shareReplay(),
        tap(episodes => this.logger.debug(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }
  //////// Save methods //////////

  /** POST: add a new episode to the server */
  addEpisode(episode: Episode): Observable<Episode> {
    this.log(`episode to add: ${episode}`);

    episode.initial_language = this.translate.currentLang;
    return this.http.post<Episode>(this.urlService.getEpisodesUrl(), episode, httpOptions).pipe(
      tap((xepisode: Episode) => this.log(`added episode w/ id=${xepisode.id}`)),
      catchError(this.handleError<Episode>('addEpisode'))
    );
  }

  /** DELETE: delete the episode from the server */
  deleteEpisode(episode: Episode | number): Observable<Episode> {
    const id = typeof episode === 'number' ? episode : episode.id;
    const url = this.urlService.getEpisodesUrl() + `${id}/`;
    return this.http.delete<Episode>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted episode id=${id}`)),
      catchError(this.handleError<Episode>('deleteEpisode'))
    );
  }

  /** PUT: update the episode on the server */
  updateEpisode(episode: Episode): Observable<any> {
    this.logger.debug('update episode service   ' + JSON.stringify(episode));

    return this.http.put(this.urlService.getEpisodesUrl() + episode.id + '/', episode, httpOptions).pipe(
      tap(_ => this.log(`updated episode id=${episode.id}`)),
      catchError(this.handleError<any>('updateEpisode'))
    );
  }

  likeEpisode(episode: Episode): Observable<any> {
    // this.authenticationService.currentUserValue.id,

    return this.http.post(this.urlService.getLikesUrl(),
      {
        owner: this.authenticationService.currentUserValue.id,
        to_content_object: this.urlService.getApiUrlPath() + `episodes/${episode.id}/`
        // to_episode: episode.id
      }, httpOptions);
  }

  bookmarkEpisode(episode: Episode): Observable<any> {
    return this.http.post(this.urlService.getBookmarksUrl(),
      {
        owner: this.authenticationService.currentUserValue.id,
        episode: episode.id
      }, httpOptions);
  }


  islikedbyme(episodeId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getEpisodesUrl() + episodeId + '/islikedbyme/', httpOptions);
  }

  iscommentedbyme(episodeId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getEpisodesUrl() + episodeId + '/iscommentedbyme/', httpOptions);
  }
  isbookmarkedbyme(episodeId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getEpisodesUrl() + episodeId + '/isbookmarkedbyme/', httpOptions);
  }

  nblikes(episodeId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getEpisodesUrl() + episodeId + '/nblikes/',
      httpOptions);
  }
  nbcomments(episodeId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getEpisodesUrl() + episodeId + '/nbcomments/',
      httpOptions);
  }
  nbbookmarks(episodeId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getEpisodesUrl() + episodeId + '/nbbookmarks/',
      httpOptions);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a EpisodeService message with the MessageService */
  private log(message: string) {
    this.messageService.add('EpisodeService: ' + message);
  }
}
