<ng-container *ngIf="(serie$ | async) as serie">
    <!-- <ng-container *ngIf="(author$ | async) as author"> -->
    <mat-card appearance="outlined"
              class="my-card">


        <mat-card-header>
            <div class="topheader">
                <button mat-icon-button
                        class="back"
                        (click)="goBack()">
                    <mat-icon><span class="material-symbols-outlined">
                            arrow_back_ios
                        </span></mat-icon>
                </button>
                <img mat-card-avatar
                     class="myavatar"
                     *ngIf="(imageService.ogphotourl(serie.photocontainer,serie.photoname)|async) as imgurl"
                     [src]="imgurl"
                     alt="Desc 1"
                     (click)="openfullscreenimageDialog()">
                <div class="toph2">
                    <mat-card-title>{{serie.name}}</mat-card-title>
                    <!-- <a *ngIf="author" -->
                    <a routerLink="/authors/{{serie.owner.id}}">
                        <!-- routerLink="/authors/{{author.id}}"> -->
                        <mat-card-subtitle class="author">by
                            &#64;{{serie.owner.pseudo}}</mat-card-subtitle>
                        <!-- &#64;{{author.pseudo}}</mat-card-subtitle> -->
                    </a>
                </div>
            </div>
        </mat-card-header>
        <mat-card-content>
            <mat-card-subtitle class="multilinetext">{{serie.desc}}</mat-card-subtitle>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    class="subscribe"
                    i18n-title
                    title="subscribe serie"
                    (click)="likeserie(serie)"
                    i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="nblikes"
                          [ngStyle]="{'color': islikedbyme ? 'yellow' : 'blue'}"
                          matBadgePosition="after"
                          matBadgePosition="after">favorite</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    class="subscribe"
                    i18n-title
                    title="subscribe serie"
                    (click)="display_comment=!display_comment"
                    i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="nbcomments"
                          [ngStyle]="{'color': iscommentedbyme ? 'yellow' : 'blue'}"
                          matBadgePosition="after"
                          matBadgePosition="after">comment</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    class="subscribe"
                    i18n-title
                    title="subscribe serie"
                    (click)="subscribeserie(serie)"
                    i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="nbsubscriptions"
                          [ngStyle]="{'color': issubscribedbyme ? 'yellow' : 'blue'}"
                          matBadgePosition="after"
                          matBadgePosition="after">subscriptions</mat-icon>
            </button>

            <!-- <ngb-rating [disabled]="!authenticationService.isLoggedIn()"
                                    [readonly]="!authenticationService.isLoggedIn()"
                                    [matBadge]="'15'"
                                    max="5"
                                    matBadgeColor="warn"
                                    matBadgePosition="after"
                                    [(rate)]="currentRate"
                                    (rateChange)='rateChange($event)'></ngb-rating>
                        <span *ngIf="authenticationService.isLoggedIn()">
                            <b>{{currentRate}}</b>/<b>{{currentRate2}}</b>
                        </span> -->
            <!-- <div>
                            <ngx-star-rating [(ngModel)]="currentRate"
                                             [id]="'rating'"></ngx-star-rating>
                            <div>Rating: {{currentRate}}</div>
                        </div> -->

            <button [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button
                    (click)="openRateDialog()">
                <mat-icon [matBadge]="nbratings"
                          [ngStyle]="{'color': isratedbyme ? 'yellow' : 'blue'}"
                          matBadgePosition="after"
                          aria-label="edit">star</mat-icon>
                <!--a routerLink="/editserie/{{serie.id}}">edit</a>-->
            </button>
            <button [disabled]="!authenticationService.isLoggedIn()"
                    mat-icon-button
                    (click)="openShareDialog()">
                <mat-icon [matBadge]="nbshares"
                          matBadgePosition="after"
                          aria-label="edit">share</mat-icon>
                <!--a routerLink="/editserie/{{serie.id}}">edit</a>-->
            </button>
            <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                    mat-icon-button
                    (click)="deleteSerieDialog(serie.id)"
                    title="delete serie">
                <mat-icon aria-label="delete serie">delete</mat-icon>
                <!--a routerLink="/editserie/{{serie.id}}">edit</a>-->
            </button>
            <button *ngIf="authenticationService.isOwner(serie.owner.id)"
                    mat-icon-button
                    routerLink="/editserie/{{serie.id}}"
                    title="edit serie">
                <mat-icon aria-label="edit">edit</mat-icon>
                <!--a routerLink="/editserie/{{serie.id}}">edit</a>-->
            </button>
        </mat-card-actions>
    </mat-card>
    <app-comments [comment_type]="'serie'"
                  [serie_id]="serie.id"
                  [ngStyle]="{'display': display_comment ? 'none' : ''}"></app-comments>
    <app-episodes [serie_id]="serie.id"
                  [virtualDisplay]="false"
                  [title]="serie.name + '(' +serie.id + ') \'s episodes'">
    </app-episodes>
    <!-- <div *ngIf="authenticationService.isOwner(author.id)">
            <a mat-fab
               routerLink="/episodes/new/{{serie.id}}"
               [queryParams]="{maxno: episodes?.length || '0'}">
                <mat-icon aria-label="add episode">add</mat-icon>
                add episode
            </a>
        </div> -->
    <!-- </ng-container> -->
</ng-container>