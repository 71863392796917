import { Episode } from '@/models/episode';
import { Page, queryPaginatedAllLimit } from '@/pagination';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) {
  }
  getRecommendedEpisodes(user_id: number): Observable<Page<Episode>> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getEpisodesUrl(), 100);
  }
}
