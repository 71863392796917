<input type="file"
       #fileInput
       style="display: none"
       accept="image/png,image/jpeg,image/gif,image/webp"
       (change)="onFilesAdded($event)"
       multiple />
<!-- (change)="onFilesAdded($event)" /> -->
<div *ngIf="preview && preview !== ''">
    <div class="previewTitle">
        {{thumbnailText}}
    </div>
    <img class="imgpreview"
         [src]="preview">
</div>
<!-- <p *ngIf="serieForm.controls.file.errors?.required"
                         style="color: red">This field is required!</p> -->
<!-- <div class="filename">: {{filename}} </div> -->
<div class="container">
    <!-- <div>
        <button [disabled]="uploading || uploadSuccessful"
                mat-raised-button
                color="primary"
                class="add-files-btn"
               (click)="fileInput.click()">
            Add Files
        </button>
    </div> -->
    <!-- (click)="addFiles()"> -->
    <!-- This is the content of the dialog, containing a list of the files to upload -->
    <!-- <mat-dialog-content fxFlex>
        <mat-list>
            <mat-list-item *ngFor="let file of files">
                <h4 mat-line>{{file.name}}</h4>
                sfrezefo mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress[file.name].progress | async"></mat-progress-bar
            </mat-list-item>
        </mat-list>
    </mat-dialog-content> -->
    <!-- This are the actions of the dialog, containing the primary and the cancel button-->
    <mat-dialog-actions class="actions">
        <button [disabled]="uploading || uploadSuccessful"
                *ngIf="stage=='addfiles'"
                mat-raised-button
                color="primary"
                (click)="fileInput.click()"> Add Files </button>
        <!-- class="add-files-btn" -->
        <button mat-raised-button
                (click)="cancelDialog()"> Cancel</button>
        <!-- *ngIf="showCancelButton" -->
        <button mat-raised-button
                *ngIf="stage=='upload'"
                color="primary"
                [disabled]="!canBeClosed"
                (click)="uploadDialog()">Upload</button>
        <button *ngIf="stage=='finish'"
                mat-raised-button
                color="primary"
                [disabled]="!canBeClosed"
                (click)="finishDialog()">Finish</button>
    </mat-dialog-actions>
    <div class="progress">

        <mat-progress-bar class="progress-bar"
                          mode="determinate"
                          [value]="uploadProgress"
                          *ngIf="uploadProgress">

        </mat-progress-bar>

        <mat-icon class="cancel-upload"
                  (click)="cancelUpload()"
                  *ngIf="uploadProgress">delete_forever</mat-icon>

    </div>
</div>