



import { Texte } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-advertisement-dialog',
  templateUrl: './advertisement-dialog.component.html',
  styleUrls: ['./advertisement-dialog.component.scss']
})
export class AdvertisementDialogComponent {
  text_id: number;
  photo_id: number;

  elem: any;

  constructor(
    private dialogRef: MatDialogRef<AdvertisementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    @Inject(DOCUMENT) private document: Document,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.logger.debug('deleteTextDialog constructor ' + JSON.stringify(data));
    this.text_id = data.text_id;
    this.photo_id = data.id;
  }

  ngOnInit(): void {
    this.elem = document.documentElement;

  }

  save() {
    const newTexte = new Texte();
    newTexte.id = this.text_id;
    this.texteService.deleteTexte(newTexte)
      .subscribe(txt => {
        console.log('delete txt :' + newTexte.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close('deleted');
  }

  close() {
    this.openFullscreen();
    this.dialogRef.close();
  }

  openFullscreen() {
    this.logger.debug('======================= openFullscreen')
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();

      this.logger.debug('======================= requestFullscreen')
    } else if (this.elem.mozRequestFullScreen) {
      this.logger.debug('======================= mozRequestFullScreen')
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.logger.debug('======================= webkitRequestFullscreen')
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.logger.debug('======================= msRequestFullscreen')
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
}
