export function compareDatesBySeconds(higherDate, lowerDate) {
    const d1 = new Date(higherDate);
    const d2 = new Date(lowerDate);
    const diffTime = Math.abs(d1.getTime() - d2.getTime());
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffSeconds = Math.ceil(diffTime / (1000));
    return diffSeconds;
}
export function compareDateToNowBySeconds(higherDate) {
    const d1 = new Date(higherDate);
    const d2 = new Date();
    const diffTime = Math.abs(d1.getTime() - d2.getTime());
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffSeconds = Math.ceil(diffTime / (1000));
    return diffSeconds;
}
export async function waitForElement(selector, timeout = 15000) {
    const start = Date.now();

    while (Date.now() - start < timeout) {
        const el = document.querySelector(selector);
        if (el) {
            return el;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
    }

    return null;
}