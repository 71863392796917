import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { $ } from 'protractor';
import { Observable, of } from 'rxjs';
import { Texte } from '../models/texte';
import { tap, catchError, map } from 'rxjs/operators';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
import { MessageService } from './message.service';
import { AppConfigService } from './app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { UrlService } from './url.service';


// const API_URL = environment.apiUrl;

const httpOptions = {
  // headers: new HttpHeaders({
  //   'Content-Type': 'application/json', 'Authorization': 'Basic ' +
  //     btoa('serge.frezefond@gmail.com:manager12')
  // })
};


@Injectable({
  providedIn: 'root'
})
export class TexteService {

  constructor(private http: HttpClient, private messageService: MessageService,
    public translate: TranslateService,
    private urlService: UrlService,
    private logger: NGXLogger
  ) {
  }



  getPhotoTextes(photo_id: number): Observable<Texte[]> {
    return queryPaginatedAllLimit<Texte>(this.http, this.urlService.getPhotosUrl() + photo_id + '/textes/').pipe(
      map(events => {
        return events.results;
      }),
      tap(episodes => this.log(`fetched photo's textes`)),
      catchError(this.handleError('getPhotoTextes', []))
    );;
  }

  /** GET textes from the server */
  getTextes(): Observable<Texte[]> {
    return this.http.get<Texte[]>(this.urlService.getTextesUrl(), httpOptions)
      .pipe(
        tap(textes => this.log(`fetched textes`)),
        catchError(this.handleError('getTextes', []))
      );
  }

  getTextesList(urlOrFilter?: string | object): Observable<Page<Texte>> {
    return queryPaginated<Texte>(this.http, this.urlService.getTextesUrl(), urlOrFilter);
  }

  getTextesListAll(): Observable<Page<Texte>> {
    return queryPaginatedAll<Texte>(this.http, this.urlService.getTextesUrl());
  }

  /** GET texte by id. Return `undefined` when id not found */
  getTexteNo404<Data>(id: number): Observable<Texte> {
    const url = `${this.urlService.getTextesUrl()}?id=${id}/`;
    return this.http.get<Texte[]>(url)
      .pipe(
        map(textes => textes[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} texte id=${id}`);
        }),
        catchError(this.handleError<Texte>(`getTexte id=${id}`))
      );
  }

  /** GET texte by id. Will 404 if id not found */
  getTexte(id: number): Observable<Texte> {
    const url = `${this.urlService.getTextesUrl()}${id}/`;
    return this.http.get<Texte>(url, httpOptions).pipe(
      tap(_ => this.log(`fetched texte id=${id}`)),
      catchError(this.handleError<Texte>(`getTexte id=${id}`))
    );
  }

  /* GET textes whose name contains search term */
  searchTextes(term: string): Observable<Texte[]> {
    if (!term.trim()) {
      // if not search term, return empty texte array.
      return of([]);
    }
    return this.http.get<Texte[]>(`${this.urlService.getTextesUrl()}?name=${term}`).pipe(
      tap(_ => this.log(`found textes matching "${term}"`)),
      catchError(this.handleError<Texte[]>('searchTextes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new texte to the server */
  addTexte(texte: Texte): Observable<Texte> {
    //    return this.http.post<Texte>(this.textesUrl, texte, httpOptions).pipe(
    // const txt: Texte;
    // texte.photo = 2;
    // texte.type = 2;
    texte.initial_language = this.translate.currentLang;
    console.log('texteserveice addTexte' + texte.texte);
    return this.http.post<Texte>(this.urlService.getTextesUrl(), texte, httpOptions)
      .pipe(
        tap((xtexte: Texte) => console.log('added texte id: ' + xtexte.id)),
        catchError(this.handleError<Texte>('addTexte'))
      );
    // txt = {
    //   id: 33,
    //   photo_id: 11,
    //   texte: 'aaa',
    //   type: 1,
    //   position_top: 1,
    //   position_left: 1
    // };
    // return of(txt);
    // .map(o => JSON.stringify(o));
    // data: { photo: 12, type: 1, texte: $("#comment").val(), position: 234 }, // data sent with the delete request

  }

  /** DELETE: delete the texte from the server */
  deleteTexte(texte: Texte | number): Observable<Texte> {
    const id = typeof texte === 'number' ? texte : texte.id;
    const url = `${this.urlService.getTextesUrl()}${id}/`;

    return this.http.delete<Texte>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted texte id=${id}`)),
      catchError(this.handleError<Texte>('deleteTexte'))
    );
  }

  /** PUT: update the texte on the server */
  updateTexte(texte: Texte): Observable<any> {
    console.log('update texte recieved :' + JSON.stringify(texte));
    return this.http.put(this.urlService.getTextesUrl() + texte.id + '/', texte, httpOptions).pipe(
      tap(_ => this.log(`updated texte id=${texte.id}`)),
      catchError(this.handleError<any>('updateTexte'))
    );
  }

  partialUpdateTexte(texte: Texte): Observable<any> {
    console.log('update texte recieved :' + JSON.stringify(texte));
    return this.http.patch(this.urlService.getTextesUrl() + texte.id + '/', texte, httpOptions).pipe(
      tap(_ => this.log(`updated texte id=${texte.id} `)),
      catchError(this.handleError<any>('updateTexte'))
    );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a TexteService message with the MessageService */
  private log(message: string) {
    this.messageService.add('TexteService: ' + message);
  }
}
