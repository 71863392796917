<h1 mat-dialog-title>Register</h1>
<div mat-dialog-content>
    <p>Your have successfully registered and <br>
        you will receive an email <br>
        to confirm your email address.
    </p>
    <p>You need to open the email and click <br>
        the provided link to confirm.</p>
    <p>You will then be able to login</p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button
            mat-dialog-close
            color="primary"
            (click)="onNoClick()">Ok Thanks</button>
</div>