<h1 mat-dialog-title>reset password </h1>
<div mat-dialog-content>
    <p>An email has been sent to your email address.<br>
        Use button/link in this email to change your password
    </p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button
            color="primary"
            (click)="onNoClick()">Ok Thanks</button>
</div>