<mat-dialog-content [formGroup]="texteForm">
    <mat-form-field>
        <mat-label>Min</mat-label>
        <input matInput
               type="number"
               [formControl]="texteForm.controls['min']"
               id="number">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Max</mat-label>
        <input matInput
               type="number"
               [formControl]="texteForm.controls['max']"
               id="number">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="close()"
            class="btn btn-primary">close</button>
    <button (click)="save()"
            class="mat-raised-button  btn btn-primary">submit</button>
</mat-dialog-actions>