import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Author } from '../models/author';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { Page, queryPaginated, queryPaginatedAll } from '../pagination';
import { AuthenticationService } from '../_services';
import { AppConfigService } from './app-config.service';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {


  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private urlService: UrlService,
    private authenticationService: AuthenticationService
  ) {
  }

  private httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + AuthenticationService.getJWTToken() })
  };

  /** GET authors from the server */
  getAuthors(): Observable<Author[]> {
    console.log('authorService getAuthors ');
    return this.http.get<Author[]>(this.urlService.getAuthorsUrl(), this.httpOptions)
      .pipe(
        tap(authors => this.log(`fetched authors`)),
        catchError(this.handleError('getAuthors', []))
      );
  }

  getAuthorsList(urlOrFilter?: string | object): Observable<Page<Author>> {
    console.log('authorService getAuthorsList ');
    return queryPaginated<Author>(this.http, this.urlService.getAuthorsUrl(), urlOrFilter);
  }

  getAuthorsListAll(): Observable<Page<Author>> {
    return queryPaginatedAll<Author>(this.http, this.urlService.getAuthorsUrl());
  }

  /** GET author by id. Return `undefined` when id not found */
  getAuthorNo404<Data>(id: number): Observable<Author> {
    const url = `${this.urlService.getAuthorsUrl()}?id=${id}/`;
    return this.http.get<Author[]>(url)
      .pipe(
        map(authors => authors[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} author id=${id}`);
        }),
        catchError(this.handleError<Author>(`getAuthor id=${id}`))
      );
  }

  /** GET author by id. Will 404 if id not found */
  getAuthor(id: number): Observable<Author> {
    const url = `${this.urlService.getAuthorsUrl()}${id}/`;
    // console.log('authorService getAuthor ');
    // console.log('authorService dynamic config ' + this.appconfigService.config);
    // console.log('authorService dynamic config resourceServerA:' + this.appconfigService.config.resourceServerA);



    return this.http.get<Author>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched author id=${id}`)),
      shareReplay(1),
      catchError(this.handleError<Author>(`getAuthor id=${id}`))
    );
  }

  /** GET number of episodes for author id */
  /*
  getAuthorMaxEpisode(id: number): Observable<Number> {
    const url = `${this.authorsUrl}${id}/getmax/`;
    console.log('authorService getAuthorMaxEpisode ');

    return this.http.get<Number>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched author id=${id}`)),
      catchError(this.handleError<Author>(`getAuthor id=${id}`))
    );
  }
  */


  /* GET authors whose name contains search term */
  searchAuthors(term: string): Observable<Author[]> {
    if (!term.trim()) {
      // if not search term, return empty author array.
      return of([]);
    }
    return this.http.get<Author[]>(`${this.urlService.getAuthorsUrl()}?name=${term}`).pipe(
      tap(_ => this.log(`found authors matching "${term}"`)),
      catchError(this.handleError<Author[]>('searchAuthors', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new author to the server */
  addAuthor(author: Author): Observable<Author> {

    console.log('addAuthor: ', author);
    // author.desc = 'aaaa';
    // author.author = 2;
    console.log('addAuthor url: ', this.urlService.getAuthorsUrl());
    return this.http.post<Author>(this.urlService.getAuthorsUrl(), author, this.httpOptions).pipe(
      tap((xauthor: Author) => this.log(`added author w/ id=${xauthor.id}`)),
      catchError(this.handleError<Author>('addAuthor'))
    );
  }

  /** DELETE: delete the author from the server */
  deleteAuthor(author: Author | number): Observable<Author> {
    const id = typeof author === 'number' ? author : author.id;
    const url = `${this.urlService.getAuthorsUrl()}${id}/`;

    return this.http.delete<Author>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted author id=${id}`)),
      catchError(this.handleError<Author>('deleteAuthor'))
    );
  }

  /** PUT: update the author on the server */
  updateAuthor(author: Author): Observable<any> {
    return this.http.put(this.urlService.getAuthorsUrl(), author, this.httpOptions).pipe(
      tap(_ => this.log(`updated author id=${author.id}`)),
      catchError(this.handleError<any>('updateAuthor'))
    );
  }

  followAuthor(author: Author): Observable<any> {
    console.log('authorService subscribe: userid ' + this.authenticationService.currentUserValue.id);

    return this.http.post(this.urlService.getFollowsUrl(), {
      owner: this.authenticationService.currentUserValue.id,
      author: author.id
    }, this.httpOptions);
  }

  isFfollowedByMeAuthor(authorId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getAuthorsUrl() + authorId + '/isfollowedbymeauthor/', this.httpOptions);
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('authorService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a AuthorService message with the MessageService */
  private log(message: string) {
    this.messageService.add('AuthorService: ' + message);
  }
}
