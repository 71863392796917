import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Author } from '../models/author';
import { AuthorService } from '../services/author.service';
import { UserService } from '../services/user.service';
// import { NumberValueAccessor } from '../../../node_modules/@angular/forms/src/directives';
import { AlertService, AuthenticationService } from '@/_services';
import { User } from '../_models/user';
import { Observable } from 'rxjs';
import { ImageService } from '@/services/image.service';
import { FullscreenImageDialogComponent } from '@/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-author-detail',
  templateUrl: './author-detail.component.html',
  styleUrls: ['./author-detail.component.scss']
})
export class AuthorDetailComponent implements OnInit {

  authId: number;
  public display = 'simple'; // possible value list or grid
  @Input() author: Author;
  isfollowedbyme: boolean;
  nbfollowers: number;
  iscommentedbyme: boolean;
  nbcomments: number;
  display_comment: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authorService: AuthorService,
    private authenticationService: AuthenticationService,
    private imageService: ImageService,
    private location: Location,
    public dialog: MatDialog,
  ) {
    this.authId = +this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.authId = +this.route.snapshot.paramMap.get('id');
    console.log('AuthorDetailComponent authorId ' + this.authId);
    this.userService.isFollowedByMe(this.authId).subscribe(data => this.isfollowedbyme = data);
    this.userService.nbFollowers(this.authId).subscribe(data => this.nbfollowers = data);
    this.userService.iscommentedbyme(this.authId).subscribe(data => this.iscommentedbyme = data);
    this.userService.nbcomments(this.authId).subscribe(data => this.nbcomments = data);
    this.getAuthor(this.authId).subscribe(author => {
      this.author = author;
      // console.log('AuthorDetailComponent author: ' + this.author.id.toString());
    });
    // console.log('AuthorDetailComponent fin ');
  }

  getAuthor(id: number): Observable<Author> {
    // console.log('AuthorDetailComponent getSerie id: ' + id);
    return this.authorService.getAuthor(id);
  }

  follow(author: Author): void {
    this.authorService.followAuthor(author).subscribe();
    this.isfollowedbyme = !this.isfollowedbyme;
    if (this.isfollowedbyme)
      this.nbfollowers += 1;
    else
      this.nbfollowers -= 1;
  }


  openfullscreenimageDialog() {
    this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname).subscribe(
      x => {
        const dialog_ref = this.dialog.open(FullscreenImageDialogComponent, {
          panelClass: ['full-screen'],
          data: { imgUrl: x },
          // data: { imgUrl: this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname) },
        });
        dialog_ref.afterClosed().subscribe(result => { });
      });
  }


  goBack(): void {
    this.location.back();
  }

}


