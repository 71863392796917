<mat-card appearance="outlined"
          class="my-card comment"
          *ngIf="(writer | async) as wr">
    <a routerLink="/users/{{comment.owner}}"
       class="writerimg">
        <img mat-card-avatar
             *ngIf="(imageService.ogphotourl(wr.photocontainer,wr.photoname)|async) as imgurl"
             [src]="imgurl"
             alt="Desc 1">
    </a>
    <div class="commentbloc">
        <div class="commenter">
            <div class="pseudo">&#64;{{wr.pseudo}} </div>
            <div class="sincedur">{{sincedur}}</div>
            <!-- --{{comment.pub_date}} -->
        </div>
        <!-- mat-line -->
        <div class="commenttext">{{comment.text}}</div>
        <div class="buttons">
            <button (click)="display_comment=!display_comment">
                <mat-icon [matBadge]="nbcomments"
                          matBadgePosition="after"
                          [ngStyle]="{'color': iscommentedbyme ? 'yellow' : 'blue'}"
                          aria-label="Example home icon"
                          fontIcon="chat_bubble_outline">
                </mat-icon>
            </button>
            <button (click)="likecomment(comment)">

                <mat-icon [matBadge]="nblikes"
                          matBadgePosition="after"
                          [ngStyle]="{'color': islikedbyme ? 'yellow' : 'blue'}"
                          aria-label="Example home icon">
                    favorite
                </mat-icon>
            </button>
        </div>
        <!-- <div class="commented">
        <button mat-icon-button
                title="like episode"
                [disabled]="!authenticationService.isLoggedIn()"
                (click)="display_comment=!display_comment">
        
        </button>
    </div> -->
    </div>
</mat-card>
<app-comments [comment_type]="'comment'"
              [comment_id]="comment.id"
              [ngStyle]="{'display': display_comment ? 'none' : ''}"></app-comments>
<!-- <app-photos [episode_id]="episode.id"></app-photos> -->