import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { from, OperatorFunction, Subject } from 'rxjs';
import { map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { BlobContainerRequest, BlobItemUpload } from '../types/azure-storage';
import { BlobSharedViewStateService } from './blob-shared-view-state.service';
import { BlobStorageService } from './blob-storage.service';
import { AppConfigService } from '@/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BlobUploadsViewStateService {
  private uploadQueueInner$ = new Subject<FileList>();

  uploadedItems$ = this.uploadQueue$.pipe(
    mergeMap(file => this.uploadFile(file)),
    this.blobState.scanEntries()
  );

  get uploadQueue$() {
    return this.uploadQueueInner$
      .asObservable()
      .pipe(mergeMap(files => from(files)));
  }

  constructor(
    private blobStorage: BlobStorageService,
    private blobState: BlobSharedViewStateService,
    private appconfigService: AppConfigService,
    private logger: NGXLogger
  ) { }

  uploadItems(files: FileList): void {
    this.logger.debug('dsfsdfuploadItems');
    this.uploadQueueInner$.next(files);
  }

  private uploadFile = (file: File) =>
    this.blobState.getStorageOptionsWithContainer().pipe(
      switchMap(options =>
        this.blobStorage
          .uploadToBlobStorage(file, {
            ...options,
            filename: file.name + new Date().getTime()
          })
          .pipe(
            this.mapUploadResponse(file, options),
            this.blobState.finaliseBlobChange(options.containerName)
          )
      )
    );
  public uploadFileWithNewName = (file: File, fname: string) => {

    return this.uploadFileToContainerWithNewName(file, fname, this.appconfigService.config.STORAGE_CONTAINER_NAME)
    // this.blobState.setContainer('uploads');
    // return this.blobState.getStorageOptionsWithContainer().pipe(
    //   switchMap(options =>
    //     this.blobStorage
    //       .uploadToBlobStorage(file, {
    //         ...options,
    //         filename: fname
    //       })
    //       .pipe(
    //         this.mapUploadResponse(file, options),
    //         this.blobState.finaliseBlobChange(options.containerName)
    //       )
    //   )
    // )
  };
  public uploadFileWithNewNameAndTags = (file: File, fname: string, tags: string) => {
    return this.uploadFileToContainerWithNewNameAndTags(file, fname, this.appconfigService.config.STORAGE_CONTAINER_NAME, tags)
  };

  public uploadFileToContainerWithNewName = (file: File, fname: string, containername: string) => {

    this.blobState.setContainer(containername);
    return this.blobState.getStorageOptionsWithContainer().pipe(
      switchMap(options =>
        this.blobStorage
          .uploadToBlobStorage(file, {
            ...options,
            filename: fname
          })
          .pipe(
            this.mapUploadResponse(file, options),
            this.blobState.finaliseBlobChange(options.containerName)
          )
      )
    )
  };
  public uploadFileToContainerWithNewNameAndTags = (file: File, fname: string, containername: string, tags) => {

    this.blobState.setContainer(containername);
    return this.blobState.getStorageOptionsWithContainer().pipe(
      switchMap(options =>
        this.blobStorage
          .uploadToBlobStorageAndTags(file, {
            ...options,
            filename: fname
          }, tags)
          .pipe(
            this.mapUploadResponse(file, options),
            this.blobState.finaliseBlobChange(options.containerName)
          )
      )
    )
  };

  private mapUploadResponse = (
    file: File,
    options: BlobContainerRequest
  ): OperatorFunction<number, BlobItemUpload> => source =>
      source.pipe(
        map(progress => ({
          filename: file.name,
          containerName: options.containerName,
          progress: parseInt(((progress / file.size) * 100).toString(), 10)
        })),
        startWith({
          filename: file.name,
          containerName: options.containerName,
          progress: 0
        })
      );
}
