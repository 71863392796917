export enum TexteType { parole = 1, pensee, texte, text_noborder };
export enum TexteShapeType { rectangle = 1, ovale };
export enum TexteDisplayType {
    DISPLAY_BOTH = 1,
    DISPLAY_ARROW = 2,
    DISPLAY_BORDER = 3,
    DISPLAY_NONE = 4
};
export enum StatusType {
    DRAFT_STATUS = 1,
    GOPUBLIC_STATUS = 2,
    PUBLIC_STATUS = 3,
    INAPPROPRIATE = 4
};
export enum GenderType {
    MALE = 1,
    FEMALE = 2
};

export class Texte {
    id: number;
    owner: number;
    numero: number;
    texte: string;
    initial_language: string;
    type: TexteType;
    gender: GenderType;
    status: StatusType;
    photo: number;
    shape: TexteShapeType;
    display: TexteDisplayType;
    position_x: number;
    position_y: number;
    arrow_x: number;
    arrow_y: number;
    font_size: string;
    background_color: string;
    carretpos: number;
}
