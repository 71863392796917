export enum CommentType {
    COMMENT_TYPE_SERIE = 19,
    COMMENT_TYPE_EPISODE = 19,
    COMMENT_TYPE_PHOTO = 19,
    COMMENT_TYPE_COMMENT = 19,
    COMMENT_TYPE_USER = 19,
    COMMENT_TYPE_AUTHOR = 19,
};
export class Comment {
    id: number;
    text: string;
    initial_language: string;
    owner: number;
    // to_content_type: CommentType
    to_content_type: CommentType
    to_object_id: number;
    to_content_object: string;
    on_comment: number;
    pub_date: Date;
}
