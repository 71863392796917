<form *ngIf="!sent"
      class="my-form"
      [formGroup]="resendEmailVerificationForm"
      (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined"
              class="my-card">
        <mat-card-title>
            Resend confirmation email</mat-card-title>
        <mat-card-content>
            <p *ngIf="sent"
               class="alert alert-success">
                We have sent a new verification message to your email address,<br>
                please check your email for this message.
            </p>
            <p class="text-danger"
               *ngIf="error">{{error}}</p>
            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input matInput
                       placeholder="Email"
                       name="email"
                       formControlName='email'>
                <mat-hint>Enter email used to register
                </mat-hint>
            </mat-form-field>
            <button mat-raised-button
                    matTooltipPosition="above"
                    matTooltip="Cancel Episode modification"
                    [matTooltipShowDelay]="100"
                    [matTooltipHideDelay]="300"
                    color="accent"
                    type="button"
                    (click)="onCancel()">cancel</button>
            <button mat-raised-button
                    type=" submit"
                    color="primary">Re-Send Verification email</button>
        </mat-card-content>
    </mat-card>
</form>