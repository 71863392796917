import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

interface Configuration {
  apiVersion: string;
  apiUrl: string;
  rootUrl: string;
  versionCheckURL: string;
  STORAGE_ACCOUNT_NAME: string;
  STORAGE_CONTAINER_NAME: string;
  sasGeneratorUrl: string;
  MOCKSTORAGE: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: Configuration;
  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  loadAppConfig() {
    return this.httpClient.get('/assets/appConfig.json')
      .toPromise()
      .then(data => {
        this.appConfig = <Configuration>data;
      });
    //   .catch (error => {
    //     console.warn("Error loading app-config.json, using envrionment file instead");
    //     this.appConfig = environment;
    //   })
  }

  get config() {
    return this.appConfig;
  }
}
