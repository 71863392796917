import { Component, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { SerieService } from '../services/serie.service';
import { Serie } from '../models/serie';
import { StatusType } from '@/models/serie';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FileValidator } from './file-input.validator';
import { UploadService } from '../services/upload.service';
import { NGXLogger } from 'ngx-logger';
import { ImageService } from '@/services/image.service';
import { Category } from '@/models/category';
import { CategoryService } from '@/services/category.service';
import { AppConfigService } from '@/services/app-config.service';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '@/_services';

@Component({
  selector: 'app-serie-form',
  templateUrl: './serie-form.component.html',
  styleUrls: ['./serie-form.component.scss',
    //  injectables: [NameService]
  ]
})
export class SerieFormComponent implements OnInit {

  serieForm: FormGroup;
  serie: Serie;
  @Output()
  fileName: string;
  public file: File;
  serieId: number;
  editMode = false;
  pageTitle: string;
  buttonText: string;
  preview: string;
  categories: Category[];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder,
    private appconfigService: AppConfigService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private serieService: SerieService,
    private categoryService: CategoryService,
    private uploadService: UploadService,
    public imageService: ImageService,
    private route: ActivatedRoute,
    private logger: NGXLogger
  ) {
    this.logger.debug('constructor SerieFormComponent  ');

  }

  ngOnInit() {
    console.log('SerieFormComponent ');

    this.serieForm = this.fb.group({
      'id': [0],
      'name': ['', Validators.required],
      // 'desc': ['', [Validators.minLength(5), Validators.required]],
      'desc': [''],
      'category': [82],
      'status': [StatusType.PUBLIC_STATUS],
      //    'pub_date': [''],
      'file': ['']
      // 'file': ['', FileValidator.validate]
    });

    this.route.params
      .subscribe(
        (params: Params) => {
          this.serieId = params['serie_id'] ? params['serie_id'] : '';
          this.editMode = params['serie_id'] != null;

          this.logger.debug(' edit mode: ', this.editMode);
          this.logger.debug(' seie id: ', this.serieId);

          this.initForm();

          this.pageTitle = this.editMode ? 'Edit Serie' : 'New Serie';
          this.buttonText = this.editMode ? 'Update' : 'Create';

          this.logger.debug('serddieForm  ' + JSON.stringify(this.serieForm.value));
          // this.customerEditForm.controls['email'].setAsyncValidators(
          // ValidateEmailNotTaken.createValidator(this.customerService, this.customerId));
        }
      );
  }

  private initForm() {
    this.categoryService.getCategories().subscribe({
      next: (data) => {
        this.logger.debug(' getCatogeries data  ', JSON.stringify(data));
        this.categories = data;
        if (this.editMode) {
          this.serieService.getSerie(this.serieId).subscribe({
            next: (data) => {
              this.logger.debug(' data  ', data);
              this.serie = data;
              this.serieForm.patchValue({
                id: this.serieId,
                name: data.name,
                desc: data.desc,
                category: data.category,
                status: data.status
              });
              this.logger.debug('serddieForm  ' + JSON.stringify(this.serieForm.value));
              this.imageService.ogphotourl(this.serie.photocontainer, this.serie.photoname).subscribe((url) => {
                this.preview = url;
              });
            },
            error:
              (e) => console.error(e)
          }
          );
        } else {
          this.logger.debug(' new serie: ');
          const id = this.categories[this.categories.findIndex(item => item.name === 'romance')].id;
          this.serieForm.patchValue({
            //   name: '',
            //   desc: '',
            // category: 83,
            category: id,
            // category: this.categories[0].id,
            //   status: 1
          });
        }
      },
      error:
        (e) => console.error(e)
    }
    );

  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.file = event.target.files[0];
    this.fileName = file.name;
    // this.logger.debug('onFileChange this fileName : ', this.fileName);
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
      // this.logger.debug('onFileChddange this preview : ', this.preview);
    };
    reader.readAsDataURL(file);
  }

  onSubmit() {

    let fileURL;

    if (this.file) {
      // we only reupload file if file field touched
      this.logger.debug('SerieFormComponent  file: ' + this.file);
      fileURL = this.uploadService.upload(this.file);
      let fileURL2 = this.uploadService.uploadWithTags(this.file,
        {
          "photo_type": 'serie',
          "object_id": this.serieId,
          "pseudo": this.authenticationService.currentUserValue.pseudo,
          "user_id": this.authenticationService.currentUserValue.id,
          "date": formatDate(Date.now(), 'yyyyMMddHHmm', this.locale)
        });
      this.logger.debug('SerieFormComponent uploaded unique fileURLs: ' + fileURL);
      // const fileURL = ''; // this.uploadService.getBaseUrl();
    }
    if (this.editMode) {

      const formValue = this.serieForm.value;
      this.logger.debug('you submitted value: ', formValue);

      const newSerie = new Serie();
      newSerie.id = this.serie.id;
      newSerie.name = formValue['name'];
      newSerie.desc = formValue['desc'];
      newSerie.photoname = fileURL;
      newSerie.status = formValue['status'];
      newSerie.category = formValue['category'];
      // newSerie.owner = 2; the  rest  server detects
      //    newSerie.pub_date = formValue['pub_date'];
      // we will hav to decide how we handle image change
      // newSerie.photourl = fileURL;
      // this.logger.debug('SerieFormComponent photourl: ', newSerie.photourl);

      this.serieService.updateSerie(newSerie)
        // this.serieService.updateSerie(this.serieForm.value)
        .subscribe(
          data => {
            console.log('addSerie data: ', data);
            this.serieId = data.id;
            this.router.navigate(['/series/' + this.serie.id]);
          },
          error => {
            this.logger.debug(error);
          });
      //    this.uploadService.uploadSerieThumbnai(new Set<File>([this.file]), this.serieId);

    } else {
      this.logger.debug('SerieFormComponent calling add serie');
      if (fileURL == null) {
        this.serieForm.value.photourl = 'http://localhost:4200/assets/testimages/notset.png';
        this.logger.debug('SerieFormComponent uploaded fileURLs  is null ');
      }
      const formValue = this.serieForm.value;
      const newSerie = new Serie();
      newSerie.name = formValue['name'];
      newSerie.desc = formValue['desc'];
      newSerie.category = formValue['category'];
      newSerie.status = formValue['status'];;
      newSerie.photocontainer = this.appconfigService.config.STORAGE_CONTAINER_NAME;
      newSerie.photoname = (fileURL == null) ? 'notset.png' : fileURL;
      this.logger.debug('you submitted value: ', newSerie);
      this.serieService.addSerie(newSerie).subscribe({

        next: data => {
          console.log('addSerie data: ', data);
          console.log('addSerie serie id: ', data.id);
          this.serieId = data.id;
          this.router.navigate(['/series/' + this.serieId]);
        },
        error: error => {
          this.logger.debug(error);
        }
      });
    }
    this.onCancel();
  }

  goBack(): void {
    this.location.back();
  }

  onCancel() {
    this.goBack();
    // this.router.navigate(['../'], { relativeTo: this.route });
  }
}

