import { AuthenticationService, AlertService } from '@/_services';
import { Component, Injectable, OnInit, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResendEmailVerificationDoneDialogComponent } from './resend-email-verification-done-dialog/resend-email-verification-done-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-resend-email-verification',
  templateUrl: './resend-email-verification.component.html',
  styleUrls: ['./resend-email-verification.component.scss']
})

@Injectable()
export class ResendEmailVerificationComponent implements OnInit {

  resendEmailVerificationForm: FormGroup;

  protected disabled: boolean;
  protected error: string;
  protected posting: boolean;
  reset: boolean;
  sent = false;
  verifying: boolean;
  protected verified: boolean;
  verificationFailed: boolean;
  protected uid: string;
  protected resetPasswordToken: string;
  hide = true;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,) {
  }

  ngOnInit(): void {

    this.resendEmailVerificationForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
    this.sent = false;
    this.posting = false;
    this.sent = false;
  }

  send(): void {
    this.posting = true;
  }


  // convenience getter for easy access to form fields
  get f() { return this.resendEmailVerificationForm.controls; }

  onSubmit(): void {
    this.authenticationService.resendVerifyEmail(this.f.email.value).pipe(first())
      .subscribe(
        data => {
          console.log('ResetPasswordComponent onSubmit loginForm called authenticationService.data' + data);
          this.verifying = false;
          this.verifying = true;
          this.openDialog();
          this.router.navigate(['/home']);
        },
        error => {
          this.verifying = false;
          this.verified = false;
          console.log('error ResetPasswordComponent called authenticationService.login');
          this.alertService.error(error);
        });
    return;
    this.send();
  }

  onCancel() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ResendEmailVerificationDoneDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  } o
}
