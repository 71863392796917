import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { UploadService } from '../services/upload.service';
import { NGXLogger } from 'ngx-logger';
import { Observable, map, mergeMap, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { SastokenService } from './sastoken.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  public readsastoken$: Observable<string>;

  constructor(
    private appconfigService: AppConfigService,
    private uploadService: UploadService,
    private sastokenService: SastokenService,
    private logger: NGXLogger) {
    this.readsastoken$ = this.sastokenService.getreadsastoken().pipe(shareReplay());;
  }

  // public gphotourl(container: string, imagename: string): string {
  //   let url: string;

  //   this.logger.debug('in old gphotourl ');
  //   if (this.appconfigService.config.MOCKSTORAGE === true) {
  //     // http://localhost:4200/home/assets/testimages/2d15331a-0fcf-48af-a2a8-16d47b2b4954.png
  //     url = 'assets/testimages/';
  //     url = url + imagename;
  //   } else {
  //     // url = 'https://sapicafrou.blob.core.windows.net/';
  //     url = `https://${this.appconfigService.config.STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${this.appconfigService.config.STORAGE_CONTAINER_NAME}/`;
  //     url = url + imagename;
  //   }
  //   return url;
  // }

  public ogphotourl(container: string, imagename: string): Observable<string> {
    let url: string;
    // this.logger.debug('in ogphotourl imgn : ' + imagename);
    if (this.appconfigService.config.MOCKSTORAGE === true) {
      url = 'assets/testimages/';
      url = url + imagename;
      return of(url);
    } else {
      // this.logger.debug('inY ogphotourl not mock');
      // return this.uploadService.getreadsastoken().pipe(shareReplay(1), tap(readsastoken => this.logger.debug('in pipe ' + JSON.stringify(readsastoken))), switchMap(readsastoken => {
      // return this.uploadService.readsastoken$.pipe(
      return this.readsastoken$.pipe(
        // tap(readsastoken => this.logger.debug('in pipe ' + JSON.stringify(readsastoken))),
        switchMap(readsastoken => {
          url = `https://${this.appconfigService.config.STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${this.appconfigService.config.STORAGE_CONTAINER_NAME}/`
            + imagename + '?' + readsastoken;
          return of(url);
          // return url;
        }));
    }
  }
}
