
import { Component, OnInit, Input, Output, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { EpisodeService } from '../services/episode.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Episode } from '../models/episode';
import { FileValidator } from '@/serie-form/file-input.validator';
import { UploadService } from '@/services/upload.service';
import { SerieService } from '@/services/serie.service';
import { ImageService } from '@/services/image.service';
import { NGXLogger } from 'ngx-logger';
import { Serie } from '@/models/serie';
import { StatusType } from '@/models/episode';
import { AppConfigService } from '@/services/app-config.service';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '@/_services';

@Component({
  selector: 'app-step1',
  templateUrl: './episode-form.component.html',
  styleUrls: ['./episode-form.component.scss']
})
export class EpisodeFormComponent implements OnInit {


  episodeForm: FormGroup;
  @Input() serie_id: number;  // on new the serie id is known
  @Input() episode_id: number; // on edit the episode id is known
  @Output() fileName: string;
  public file: File;
  // en entree pour edit de composent
  @Input() episode: Episode;
  @Input() photourl: string;
  preview: string;

  // maxnumero: string;  // on new the serie id is known


  editMode = false;
  pageTitle: string;
  buttonText: string;

  actionButton;
  action = null;



  constructor(private fb: FormBuilder,
    private appconfigService: AppConfigService,
    @Inject(LOCALE_ID) private locale: string,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private serieService: SerieService,
    private episodeService: EpisodeService,
    public imageService: ImageService,
    private uploadService: UploadService,
    private logger: NGXLogger
  ) {
    this.logger.debug('constructor EpisodeFormComponent  ');
  }

  ngOnInit() {
    console.log('EpisodeFormComponent enter');
    console.log('EpisodeFormComponent enter' + this.router.url);
    this.actionButton = 'Edit';
    this.action = 'update';

    this.episodeForm = this.fb.group({
      'title': ['', Validators.required],
      'desc': [''],
      // 'desc': ['', [Validators.minLength(5), Validators.required]],
      'status': [StatusType.PUBLIC_STATUS],
      // 'pub_date': ['', Validators.required],
      // 'numero': ['1', Validators.required],
      //     'serie': ['n', Validators.minLength(5)],
      //
      'file': ['']
      // 'file': ['', FileValidator.validate]
    });

    // this.route.params
    //   .subscribe(
    //     (params: Params) => {
    //       this.serie_id = params['serie_id'] ? params['serie_id'] : '';
    //       this.episode_id = params['episode_id'] ? params['episode_id'] : '';
    //       this.logger.debug('EpisodeFormComponent seieid ' + this.serie_id);
    //       this.logger.debug('EpisodeFormComponent episodeid  ' + this.episode_id);
    //       this.editMode = params['episode_id'] != null;
    //       this.logger.debug('EpisodeFormComponent editmod' + this.editMode);
    //       this.pageTitle = this.editMode ? 'Edit Episode' : 'New Episode';
    //       this.buttonText = this.editMode ? 'Update' : 'Create';
    //     }
    //   );
    this.route.queryParams.subscribe((params: Params) => {
      // this.maxnumero = params['maxno'] ? params['maxno'] : '0';
      console.log('EpisodeFormComponent  maxno ' + params['maxno']);
    });


    if (this.router.url.includes('new')) {
      this.buttonText = 'Create';
      this.pageTitle = 'New Episode';
      this.editMode = false;
      console.log('EpisodeFormComponent New');
      this.serie_id = +this.route.snapshot.paramMap.get('serie_id');
      console.log('EpisodeFormComponent New serie id ' + this.serie_id);
      // this.maxnumero = this.route.snapshot.paramMap.get('maxno');
      // console.log('EpisodeFormComponent New maxnumero' + this.maxnumero);
      // console.log('EpisodeFormComponent New maxnumero ::' + this.route.snapshot.queryParamMap.get('maxno'));

      this.episodeForm.setValue({
        title: '',
        desc: '',
        status: StatusType.PUBLIC_STATUS,
        // numero: this.maxnumero,
        file: ''
      });
    } else if (this.router.url.includes('edit')) {
      this.buttonText = 'Update';
      this.pageTitle = 'Edit Episode';
      this.editMode = true;
      this.episode_id = +this.route.snapshot.paramMap.get('episode_id');
      console.log('EpisodeFormComponent Edit id: ' + this.episode_id);

      this.episodeService.getEpisode(this.episode_id)
        .subscribe(episode => {
          console.log('EpisodeFormComponent Edit photourl: ' + episode.photourl);
          this.episode = episode;
          console.log('EpisodeFormComponent original episode : ' + JSON.stringify(episode));

          this.serie_id = (<Serie>(episode.serie)).id;
          // this.serie_id = episode.serie.id;
          // this.serie_id = episode.serie;
          this.logger.debug('EpisodeFormComponent episode serieid  ' + this.serie_id);
          this.episodeForm.setValue({
            title: this.episode.title,
            desc: this.episode.desc,
            status: this.episode.status,
            // numero: this.episode.numero,
            file: ''
          });
          this.imageService.ogphotourl(episode.photocontainer, episode.photoname).subscribe((url) => {
            this.preview = url;
          });
        });
    } else {
      console.log('EpisodeFormComponent neither Edit nor new');
    }
    this.logger.debug('EpisodeFormComponent  edit finished');

  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.file = event.target.files[0];
    this.fileName = file.name;
    console.log('onFileChange file.name : ', file.name);
    console.log('onFileChange this fileName : ', this.fileName);

    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
      this.logger.debug('onFileChddange this preview : ', this.preview);
    };
    reader.readAsDataURL(file);
  }

  onSubmit() {
    let fileURL;
    if (this.file) {
      // we only reupload file if file field touched
      this.logger.debug('Step1Component  file: ' + this.file);
      fileURL = this.uploadService.upload(this.file);
      let fileURL2 = this.uploadService.uploadWithTags(this.file,
        {
          "photo_type": 'serie',
          "object_id": this.episode_id,
          "pseudo": this.authenticationService.currentUserValue.pseudo,
          "user_id": this.authenticationService.currentUserValue.id,
          "date": formatDate(Date.now(), 'yyyyMMddHHmm', this.locale)
        }); this.logger.debug('Step1Component uploaded unique fileURLs: ' + fileURL);
      // const fileURL = ''; // this.uploadService.getBaseUrl();
    }

    const formValue = this.episodeForm.value;
    const newEpisode = new Episode();
    console.log('you submitted value: ', formValue);
    newEpisode.title = formValue['title'];
    newEpisode.desc = formValue['desc'];
    newEpisode.status = formValue['status'];
    // newEpisode.numero = formValue['numero'];
    // newEpisode.numero = 0;
    newEpisode.pub_date = formValue['pub_date'];
    // newEpisode.serie = new Serie();
    newEpisode.serie = this.serie_id;
    // newEpisode.serie_id = this.serie_id;
    this.logger.debug('EpisodeFormComponent  newepisode serie idd' + this.serie_id);
    newEpisode.photourl = null;
    newEpisode.photoname = fileURL;
    console.log('SerieFormComponent episodephotourl: ', newEpisode.photourl);
    this.logger.debug('EpisodeFormComponent  newepisode ' + JSON.stringify(newEpisode));

    if (!this.editMode) {
      console.log('going to add episode: ', formValue);
      newEpisode.photocontainer = this.appconfigService.config.STORAGE_CONTAINER_NAME
      newEpisode.photoname = (fileURL == null) ? 'notset.png' : fileURL;
      this.episodeService.addEpisode(newEpisode)
        .subscribe({
          next: (data) => {
            console.log('added episode id: ' + data.id);
            this.router.navigate(['/episodes/' + data.id]);
          },
          error: (error) => {
            console.log(error);
          }
        });
    } else { // edit mode
      newEpisode.id = this.episode_id;
      console.log('updateEpisode new data: ', JSON.stringify(newEpisode));
      this.episodeService.updateEpisode(newEpisode).subscribe(data => {
        console.log('updateEpisode data: ', JSON.stringify(data));
        this.router.navigate(['/episodes/' + this.episode.id]);
      },
        error => {
          this.logger.debug(error);
        });
    }
  }


  goBack(): void {
    this.location.back();
  }

  onCancel() {
    this.goBack();
    // this.router.navigate(['../'], { relativeTo: this.route });
  }
}
