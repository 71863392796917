import { PasswordChangedDialogComponent } from '@/change-password/password-changed-dialog/password-changed-dialog.component';
import { DialogData } from '@/forgot-password';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-registration-done-dialog',
  templateUrl: './registration-done-dialog.component.html',
  styleUrls: ['./registration-done-dialog.component.scss']
})
export class RegistrationDoneDialogComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<RegistrationDoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('80%', '80%');

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

