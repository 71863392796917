import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
import { UserSettings } from '@/models/userSettings';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'JWT ' + AuthenticationService.getJWTToken() })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private urlService: UrlService,
  ) {
  }

  getAll() {
    return this.http.get<UserSettings[]>(this.urlService.getUserSettingUrl());
  }

  getById(id: number): Observable<UserSettings> {
    return this.http.get<UserSettings>(this.urlService.getUserSettingUrl() + id);
  }

  getByUserId(id: number): Observable<UserSettings> {
    return this.http.get<UserSettings>(this.urlService.getUserUrl() + id + '/usersettings/');
  }

  update(userSettings: UserSettings): Observable<any> {
    return this.http.put(this.urlService.getUserSettingUrl() + `${userSettings.id}` + '/', userSettings, this.httpOptions).pipe(
      tap(_ => this.log(`updated userSettings id=${userSettings.id}`)),
      catchError(this.handleError<any>('updateSerie'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('serieService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a SerieService message with the MessageService */
  private log(message: string) {
    this.messageService.add('SerieService: ' + message);
  }
}
