<div class="sharing">
    <div class="sharingicons">
        <share-buttons [theme]="'circles-dark'"
                       [include]="['facebook','twitter','pinterest','messenger','whatsapp','line','copy']"
                       [show]="3"
                       [url]="sharedurl"
                       [image]="'xxxxxx:whatsapp'"
                       [autoSetMeta]="false">
        </share-buttons>
    </div>
    <button mat-raised-button
            matTooltipPosition="above"
            matTooltip="Cancel Serie creation"
            [matTooltipShowDelay]="500"
            [matTooltipHideDelay]="500"
            color="accent"
            type="button"
            (click)="onCancel()">cancel</button>
</div>