import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject, catchError, map, of, share, shareReplay } from 'rxjs';
import { Observable } from 'rxjs';
// import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import { BlobUploadsViewStateService } from '../azure-storage/services/blob-uploads-view-state.service';
import { MessageService } from './message.service';
import { PhotoService } from './photo.service';
// import { texast } from '@angular/core/src/render3';
import * as uuid from 'uuid';
import { Photo } from '../models/photo';
import { AppConfigService } from './app-config.service';
import { SerieService } from './serie.service';
import { NGXLogger } from 'ngx-logger';
import { runInThisContext } from 'vm';
import { Serie } from '@/models/serie';

interface Sast {
  'st': string;
}

@Injectable()
export class UploadService {
  private getreadsastokenurl;
  private getwritesastokenurl;
  private getsastokenurl;
  private getstorageinfourl;
  public readsastoken$: Observable<string>;
  // public writesastoken: string;

  private STORAGE_ACCOUNT_NAME: string;
  private STORAGE_CONTAINER_NAME: string;
  /** The selected file */
  currentFile: File;
  /** The current percent to be displayed */
  percent: number;
  rsastoken: string;

  private httpOptions = {
    // headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('admin1:manager12') })
  };
  constructor(
    private appconfigService: AppConfigService,
    private http: HttpClient,
    private blobState: BlobUploadsViewStateService,
    private serieService: SerieService,
    private messageService: MessageService,
    private photoService: PhotoService,
    private logger: NGXLogger) {
    this.logger.debug('Upload service constructor');
    this.currentFile = null;
    this.percent = 0;
    this.getreadsastokenurl = this.appconfigService.config.apiUrl + '/getreadsastoken';
    this.getwritesastokenurl = this.appconfigService.config.apiUrl + '/getwritesastoken';
    this.getsastokenurl = this.appconfigService.config.apiUrl + '/getsastoken';
    this.getstorageinfourl = this.appconfigService.config.apiUrl + '/getstorageinfo';
    this.STORAGE_ACCOUNT_NAME = this.appconfigService.config.STORAGE_ACCOUNT_NAME;
    this.STORAGE_CONTAINER_NAME = this.appconfigService.config.STORAGE_CONTAINER_NAME;
    this.logger.debug('upload service constructor STORAGE_ACCOUNT_NAME', this.STORAGE_ACCOUNT_NAME);
    this.logger.debug('upload service constructor STORAGE_CONTAINER_NAME', this.STORAGE_CONTAINER_NAME);
    // this.getreadsastoken().subscribe(res => this.readsastoken = res)
    // this.getwritesastoken().subscribe(res => this.writesastoken = res)
    this.readsastoken$ = this.getreadsastoken().pipe(shareReplay());
  }

  public getreadsastoken(): Observable<string> {
    this.logger.debug('getreadsastoken');
    return this.http.get<any>(this.getreadsastokenurl, this.httpOptions).pipe(map(res =>
      res.storageAccessToken

    ), catchError(this.handleError<string>('getreadsastoken', 'jdshfkjhf')));;
  }


  public getwritesastoken(): Observable<string> {
    this.logger.debug('getwritesastoken');
    return this.http.get<any>(this.getwritesastokenurl, this.httpOptions).pipe(map(res => {
      return res.storageAccessToken;
    }
    ));;
  }

  // public getsastoken(): Observable<Sast> {
  public getsastoken(): Observable<string> {
    this.logger.debug('getsastoken');
    // return this.http.get<Sast>(this.getsastokenurl,
    return this.http.get<any>(this.getsastokenurl,
      this.httpOptions
      /*      {
              // encore du basic authentification ?
              headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Basic ' + btoa('admin1:manager12')),
              // responseType: 'text' as 'text'
              params: new HttpParams().set('filename', filename)
              //         encodeURIComponent(filename))
            }
            */
    ).pipe(map(res => {
      return res.storageAccessToken;
    }
    ));

  }
  // public getsastoken(): Observable<Sast> {
  public getStorageInfo(): Observable<string[]> {
    this.logger.debug('getStorageInfo');
    // return this.http.get<Sast>(this.getsastokenurl,
    return this.http.get<string[]>(this.getstorageinfourl,
      this.httpOptions
      /*      {
              // encore du basic authentification ?
              headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', 'Basic ' + btoa('admin1:manager12')),
              // responseType: 'text' as 'text'
              params: new HttpParams().set('filename', filename)
              //         encodeURIComponent(filename))
            }
            */
    );

  }
  public genFilename(): string {
    const myId = uuid.v4();
    const fname: string = myId + '.png';

    this.logger.debug(`new fielname ${fname}`);
    return fname;
  }
  public getBaseUrl(): string {
    const myId = uuid.v4();
    const fname: string = myId + '.png';

    this.logger.debug('Upload service getBaseURL');
    return 'erzer';
  }
  public upload(file: File): string {
    // : { [key: string]: Observable<number> }
    this.logger.debug('upload filname : ' + file.name);
    let newFileName = this.genFilename();
    this.logger.debug('newfilname : ' + newFileName);
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    // let files = dataTransfer.files;
    this.blobState.uploadFileWithNewName(file, newFileName).subscribe();
    // this.blobState.uploadItems(files);
    // const muploaddUrl = this.getBaseUrl();
    // const status = {};
    // const progress = new Subject<number>();
    return newFileName;
  }
  public uploadWithName(file: File, fname: string) {
    this.logger.debug('upload filname : ' + file.name);
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    this.blobState.uploadFileWithNewName(file, fname).subscribe();
    return;
  }
  public uploadWithTags(file: File, tags): string {
    // : { [key: string]: Observable<number> }
    this.logger.debug('upload filname : ' + file.name);
    let newFileName = this.genFilename();
    this.logger.debug('newfilname : ' + newFileName);
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    // let files = dataTransfer.files;
    this.blobState.uploadFileWithNewNameAndTags(file, newFileName, tags).subscribe();
    // this.blobState.uploadItems(files);
    // const muploaddUrl = this.getBaseUrl();
    // const status = {};
    // const progress = new Subject<number>();
    return newFileName;
  }
  public uploadWithNameAndTags(file: File, filename: string, tags: string) {
    this.logger.debug('upload filname : ' + file.name);
    this.blobState.uploadFileWithNewNameAndTags(file, filename, tags).subscribe();
    return;
  }
  public uploadToContainerWithName(file: File, filename: string, container: string) {
    this.logger.debug('upload filname : ' + file.name);
    this.blobState.uploadFileToContainerWithNewName(file, filename, container).subscribe();
    return;
  }
  uploadFileToContainerWithNewName
  /*
  public upload(files: Set<File>): string[] {
    // : { [key: string]: Observable<number> }

    // this will be the our resulting map
    const status = {};
    const progress = new Subject<number>();
    const fileUrls: string[] = [];

    files.forEach(file => {
      */

  public uploadEpisodePhoto(file: File, episodeID: number, numero: number) {
    //  public uploadEpisodePhoto(files: Set<File>, episodeID: number, numero: number) {
    //
    // const fileURL = this.getBaseUrl();

    const fileURL = this.upload(file);

    console.log('uploadEpisodePhoto fileURL:' + fileURL);

    const photo: Photo = new Photo();
    photo.desc = 'desccccc ' + fileURL;
    photo.numero = numero;
    photo.episode = episodeID; // should be parameter for the service
    // photo.url = this.config.baseUrl;
    photo.url = fileURL;
    this.photoService.addPhoto(photo)
      .subscribe(newphoto => {
        this.log('uploadService djphoto added id: ' + newphoto.id + ' desc :' + newphoto.desc);
      });
    this.log('uploadService djphoto added ' + photo.desc);

  }


  public uploadSeriePhoto(file: File, serieID: number) {
    // const fileURL = this.getBaseUrl();
    const fileURL = this.upload(file);
    this.logger.debug('uploadSeriePhoto');
    this.serieService.setPhoto(serieID, fileURL)
      .subscribe(newphoto => {
        this.logger.debug('uploadService djphoto added id: ' + newphoto.id + ' desc :' + newphoto.desc);
      });
  }


  // : { [key: string]: Observable<number> }

  //   const progress = new Subject<number>();


  /*
    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
 
    // create a http-post request and pass the form
    // tell it to report the upload progress
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true
    });
 
    // create a new progress-subject for every file
    const progress = new Subject<number>();
 
    // send the http-request and subscribe for progress-updates
 
    let startTime = new Date().getTime();
 
   /* sfrezefo
     this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        // calculate the progress percentage
 
        const percentDone = Math.round((100 * event.loaded) / event.total);
        // pass the percentage into the progress-stream
        progress.next(percentDone);
      } else if (event instanceof HttpResponse) {
        // Close the progress-stream if we get an answer form the API
        // The upload is complete
        progress.complete();
      }
    });
 
    // Save every progress-observable in a map of all observables
    status[file.name] = {
      progress: progress.asObservable()
    };
  });
 
  // return the map of progress.observables
  return status;
  */
  //const progress = new Subject<number>();
  // status['x'] = {
  //progress: progress.asObservable()
  // };
  // });

  // return the map of progress.observables
  // return status;
  //   return {'xxx':3}:


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('serieService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    this.messageService.add('UploadService: ' + message);
  }

}