<div class="total">
    <app-header *ngIf="(!fullscreen )"
                (sidenavToggle)="drawer.toggle()"
                [ngStyle]="{'display': hideTopNav ? 'none' : ''}"
                (closeSidenav)="drawer.close()">
    </app-header>
    <mat-sidenav-container class="sdcontainer">
        <mat-sidenav #drawer
                     mode="over"
                     closed
                     role="navigation">
            <app-sidenav-list (closeSidenav)="drawer.close()"></app-sidenav-list>
        </mat-sidenav>
        <mat-sidenav-content class="sdcontent"
                             (scroll)="onScroll($event)">
            <errormessages></errormessages>
            <loading></loading>
            <router-outlet></router-outlet>
            <!-- <app-messages></app-messages> -->
        </mat-sidenav-content>
    </mat-sidenav-container>
    <div class="fill-remaining-space">
    </div>
    <app-footer *ngIf="(!fullscreen )"
                [ngStyle]="{'display': true ? 'none' : ''}">
    </app-footer>
    <!-- [ngStyle]="{'display': hideBottomNav ? 'none' : ''}"> -->
    <!-- [ngStyle]="{'display': hideTopNav ? 'none' : ''}"> -->
</div>