import { Texte, TexteType } from "@/models/texte";
import { faSquareRootAlt } from "@fortawesome/free-solid-svg-icons";
import { Key } from "protractor";
import domtoimage from 'dom-to-image';

export const sleep = (ms) => new Promise(r => setTimeout(r, ms));

export function hello(): string {
    return 'hello';
}

export const randomInt = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

export function createEllipse(logger, svg, imgratio, id, rgbafillcolor, boxx: number, boxy: number, boxw: number, boxh: number) {
    // logger.debug('createThoughtLine =' + String(imgratio));
    let ellipse = document.createElementNS('http://www.w3.org/2000/svg',
        'ellipse');
    const cx = boxx + boxw / 2;
    const cy = boxy + boxh / 2;
    const rx = boxw / Math.sqrt(2);
    const ry = boxh / Math.sqrt(2);
    ellipse.setAttribute('fill', rgbafillcolor); // Note: NOT setAttributeNS()
    // ellipse.setAttribute('fill', 'Cornsilk'); // Note: NOT setAttributeNS()
    // ellipse.setAttribute('fill-opacity', '0.7'); // Note: NOT setAttributeNS()
    ellipse.setAttribute('cx', String(cx));
    ellipse.setAttribute('cy', String(cy));
    ellipse.setAttribute('rx', String(rx));
    ellipse.setAttribute('ry', String(ry));
    ellipse.setAttribute('draggable', 'false');
    ellipse.style.position = 'absolute';
    ellipse.style.zIndex = '10';
    ellipse.style.stroke = 'black';
    ellipse.style.strokeWidth = '1';
    ellipse.setAttribute('data-textid', String(id));
    ellipse.setAttribute('id', 'ellipse' + String(id));
    svg.appendChild(ellipse);
}

export function createSpeakLine(logger, svg, imgratio, id, rgbafillcolor, x1, y1, x2, y2, x3, y3) {
    // logger.debug('createThoughtLine =' + String(imgratio));
    let triangle = document.createElementNS('http://www.w3.org/2000/svg',
        'polygon');
    triangle.setAttribute('fill', rgbafillcolor); // Note: NOT setAttributeNS()
    // triangle.setAttribute('fill', 'Cornsilk'); // Note: NOT setAttributeNS()
    // triangle.setAttribute('fill-opacity', '0.7'); // Note: NOT setAttributeNS()
    triangle.setAttribute('points', String(x1) + ' ' + String(y1) + ' ' +
        String(x2) + ' ' + String(y2) + ' ' +
        String(x3) + ' ' + String(y3));
    triangle.setAttribute('draggable', 'false');
    triangle.style.position = 'absolute';
    triangle.style.zIndex = '10';
    triangle.style.stroke = 'black';
    triangle.style.strokeWidth = '1';
    triangle.setAttribute('data-textid', String(id));
    triangle.setAttribute('id', 'triangle' + String(id));
    svg.appendChild(triangle);
}

export function createThoughtLine(logger, svg, imgratio, id, rgbafillcolor, x1, y1, x2, y2) {
    const diam = 4;
    const nbstep = 5;
    // logger.debug('createThoughtLine =' + String(imgratio));
    for (let i = 1; i < nbstep; i++) {
        // logger.debug('createThoughtLine nb ' + String(i));
        let ncircle = document.createElementNS('http://www.w3.org/2000/svg',
            'circle');
        ncircle.setAttribute('fill', rgbafillcolor); // Note: NOT setAttributeNS()
        // ncircle.setAttribute('fill', 'Cornsilk'); // Note: NOT setAttributeNS()
        // ncircle.setAttribute('fill-opacity', '0.6'); // Note: NOT setAttributeNS()
        ncircle.setAttribute('cx', String(x1 + i * (x2 - x1) / nbstep));     // setAttribute turns 150 into a string
        ncircle.setAttribute('cy', String(y1 + i * (y2 - y1) / nbstep));    // using a string works, too
        ncircle.setAttribute('r', String(diam + i * 2));       // give the ncircle a radius so we can see it
        ncircle.style.stroke = 'black';
        ncircle.style.strokeWidth = '1';
        ncircle.setAttribute('draggable', 'false');
        ncircle.style.position = 'absolute';
        ncircle.style.zIndex = '10';
        ncircle.setAttribute('data-textid', String(id));
        ncircle.setAttribute('id', 'ncirclet' + '-' + + String(i) + '-' + String(id));
        svg.appendChild(ncircle);
        // logger.debug('createThoughtLine nb ' + String(i));
    }
}

export function computeArrowPoints(logger, boxx: number, boxy: number, boxw: number, boxh: number,
    xl: number, yl: number): { x1: number, y1: number, x2: number, y2: number } {
    let x1, y1, x2, y2: number;

    // logger.debug('compute arrow points : box x y :'
    //     + String(boxx) + ' , ' + String(boxy) + ' , box w h : '
    //     + String(boxw) + ' , ' + String(boxh) + ' , xl yl'
    //     + String(xl) + ' , ' + String(yl));

    let spaceAp = 10;
    if (yl < boxy) {
        if ((xl < boxx) && (Math.abs(boxy - yl) / Math.abs(boxx - xl) < 1)) {
            x1 = boxx;
            y1 = ((boxy + (boxy + boxh)) / 2) - spaceAp;
            x2 = boxx;
            y2 = ((boxy + (boxy + boxh)) / 2) + spaceAp;
        } else if ((xl > (boxx + boxw)) && Math.abs(boxy - yl) / Math.abs((boxx + boxw) - xl) < 1) {
            x1 = (boxx + boxw);
            y1 = ((boxy + (boxy + boxh)) / 2) - spaceAp;
            x2 = (boxx + boxw);
            y2 = ((boxy + (boxy + boxh)) / 2) + spaceAp;
        } else {
            x1 = ((boxx + (boxx + boxw)) / 2) - spaceAp;
            y1 = boxy;
            x2 = (boxx + (boxx + boxw)) / 2 + spaceAp;
            y2 = boxy;
        }
    } else if (yl > (boxy + boxh)) {
        if ((xl < boxx) && Math.abs((boxy + boxh) - yl) / Math.abs(boxx - xl) < 1) {
            x1 = boxx;
            y1 = (boxy + (boxy + boxh)) / 2 - spaceAp;
            x2 = boxx;
            y2 = (boxy + (boxy + boxh)) / 2 + spaceAp;
        } else if ((xl > (boxx + boxw)) && Math.abs((boxy + boxh) - yl) / Math.abs((boxx + boxw) - xl) < 1) {
            x1 = (boxx + boxw);
            y1 = (boxy + (boxy + boxh)) / 2 - spaceAp;
            x2 = (boxx + boxw);
            y2 = (boxy + (boxy + boxh)) / 2 + spaceAp;
        } else {
            x1 = (boxx + (boxx + boxw)) / 2 - spaceAp;
            y1 = (boxy + boxh);
            x2 = (boxx + (boxx + boxw)) / 2 + spaceAp;
            y2 = (boxy + boxh);
        }
    } else if (xl < boxx) {
        x1 = boxx;
        y1 = (boxy + (boxy + boxh)) / 2 - spaceAp;
        x2 = boxx;
        y2 = (boxy + (boxy + boxh)) / 2 + spaceAp;
    } else if (xl > (boxx + boxw)) {
        x1 = (boxx + boxw);
        y1 = (boxy + (boxy + boxh)) / 2 - spaceAp;
        x2 = (boxx + boxw);
        y2 = (boxy + (boxy + boxh)) / 2 + spaceAp;
    } else {
        x1 = boxx;
        y1 = (boxy + (boxy + boxh)) / 2 - spaceAp;
        x2 = boxx;
        y2 = (boxy + (boxy + boxh)) / 2 + spaceAp;
    }
    // logger.debug('compute arrow points : box x1 y1 :'
    //     + String(x1) + ' , ' + String(y1) + ' , x2 y2 : '
    //     + String(x2) + ' , ' + String(y2));
    return { x1: x1, y1: y1, x2: x2, y2: y2 };
}

export function computeArrowPoints8(logger, boxx: number, boxy: number, boxw: number, boxh: number,
    xl: number, yl: number): { x1: number, y1: number, x2: number, y2: number } {
    let x1, y1, x2, y2: number;

    let xx = xl - (boxx + (boxw / 2));
    let yy = yl - (boxy + (boxh / 2));
    let raprot = Math.abs(yy / ((Math.abs(xx) < 0.001) ? 0.001 : xx));
    let rap1 = (boxh / 3) / boxw;
    let rap2 = boxh / boxw;
    let rap3 = boxh / (boxw / 3);
    let rapindic = ((raprot > rap1) ? 0 : 1) + ((raprot > rap2) ? 0 : 2);
    let spaceAp = 6;
    let signindic = ((xx > 0) ? 0 : 1) + ((yy > 0) ? 2 : 0);

    // logger.debug('compute arrow points : box x y :'
    //     + String(boxx) + ' , ' + String(boxy) + ' , box w h : '
    //     + String(boxw) + ' , ' + String(boxh) + ' , xl yl'
    //     + String(xl) + ' , ' + String(yl));

    if (signindic == 0) {
        if (raprot < rap1) {
            x1 = (boxx + boxw);
            y1 = boxy + boxh / 2 - spaceAp;
            x2 = (boxx + boxw);
            y2 = boxy + boxh / 2 + spaceAp;
        } else if ((raprot >= rap1) && (raprot < rap2)) {
            x1 = (boxx + boxw);
            y1 = boxy + boxh / 6 - spaceAp;
            x2 = (boxx + boxw);
            y2 = boxy + boxh / 6 + spaceAp;
        } else if ((raprot >= rap2) && (raprot < rap3)) {
            x1 = boxx + 5 * (boxw / 6) - spaceAp;
            y1 = boxy;
            x2 = boxx + 5 * (boxw / 6) + spaceAp;
            y2 = boxy;
        } else if (raprot >= rap3) {
            x1 = boxx + (boxw / 2) - spaceAp;
            y1 = boxy;
            x2 = boxx + (boxw / 2) + spaceAp;
            y2 = boxy;
        }
    } else if (signindic == 1) {
        if (raprot < rap1) {
            x1 = (boxx);
            y1 = boxy + boxh / 2 - spaceAp;
            x2 = (boxx);
            y2 = boxy + boxh / 2 + spaceAp;
        } else if ((raprot >= rap1) && (raprot < rap2)) {
            x1 = (boxx);
            y1 = boxy + boxh / 6 - spaceAp;
            x2 = (boxx);
            y2 = boxy + boxh / 6 + spaceAp;
        } else if ((raprot >= rap2) && (raprot < rap3)) {
            x1 = boxx + 1 * (boxw / 6) - spaceAp;
            y1 = boxy;
            x2 = boxx + 1 * (boxw / 6) + spaceAp;
            y2 = boxy;
        } else if (raprot >= rap3) {
            x1 = boxx + (boxw / 2) - spaceAp;
            y1 = boxy;
            x2 = boxx + (boxw / 2) + spaceAp;
            y2 = boxy;
        }
    } else if (signindic == 2) {
        if (raprot < rap1) {
            x1 = (boxx + boxw);
            y1 = boxy + boxh / 2 - spaceAp;
            x2 = (boxx + boxw);
            y2 = boxy + boxh / 2 + spaceAp;
        } else if ((raprot >= rap1) && (raprot < rap2)) {
            x1 = (boxx + boxw);
            y1 = boxy + 5 * (boxh / 6) - spaceAp;
            x2 = (boxx + boxw);
            y2 = boxy + 5 * (boxh / 6) + spaceAp;
        } else if ((raprot >= rap2) && (raprot < rap3)) {
            x1 = boxx + 5 * (boxw / 6) - spaceAp;
            y1 = boxy + boxh;
            x2 = boxx + 5 * (boxw / 6) + spaceAp;
            y2 = boxy + boxh;
        } else if (raprot >= rap3) {
            x1 = boxx + (boxw / 2) - spaceAp;
            y1 = boxy + boxh;
            x2 = boxx + (boxw / 2) + spaceAp;
            y2 = boxy + boxh;
        }
    } else if (signindic == 3) {
        if (raprot < rap1) {
            x1 = boxx;
            y1 = boxy + boxh / 2 - spaceAp;
            x2 = boxx;
            y2 = boxy + boxh / 2 + spaceAp;
        } else if ((raprot >= rap1) && (raprot < rap2)) {
            x1 = boxx;
            y1 = boxy + 5 * (boxh / 6) - spaceAp;
            x2 = boxx;
            y2 = boxy + 5 * (boxh / 6) + spaceAp;
        } else if ((raprot >= rap2) && (raprot < rap3)) {
            x1 = boxx + (boxw / 6) - spaceAp;
            y1 = boxy + boxh;
            x2 = boxx + (boxw / 6) + spaceAp;
            y2 = boxy + boxh;
        } else if (raprot >= rap3) {
            x1 = boxx + (boxw / 2) - spaceAp;
            y1 = boxy + boxh;
            x2 = boxx + (boxw / 2) + spaceAp;
            y2 = boxy + boxh;
        }
    }
    // logger.debug('compute arrow points : box x1 y1 :'
    //     + String(x1) + ' , ' + String(y1) + ' , x2 y2 : '
    //     + String(x2) + ' , ' + String(y2));
    return { x1: x1, y1: y1, x2: x2, y2: y2 };
}

export function createPhylactereRect(logger, svg, imgratio, id, rgbafillcolor,
    spx, spy, ap1x, ap1y, ap2x, ap2y,
    boxx: number, boxy: number, boxw: number, boxh: number, texteType: TexteType) {
    // logger.debug('createThoughtLine =' + String(imgratio));
    let path = document.createElementNS('http://www.w3.org/2000/svg',
        'path');
    path.setAttribute('fill', rgbafillcolor);
    path.setAttribute('draggable', 'false');
    path.style.position = 'absolute';
    path.style.zIndex = '10';
    path.style.stroke = 'black';
    path.style.strokeWidth = '1';
    path.style.filter = 'url(#shadow)';
    path.setAttribute('data-textid', String(id));
    path.setAttribute('id', 'path' + String(id));
    let conf = 0;
    if (ap1x == boxx) conf = 4
    else if (ap1x == boxx + boxw) conf = 2
    else if (ap1y == boxy) conf = 1
    else if (ap1y == boxy + boxh) conf = 3

    let pathstring = 'M ' + boxx + ' ' + boxy + ' ';
    let isParole: boolean;
    isParole = (texteType === TexteType.parole)
    if (texteType === TexteType.text_noborder) {
        path.style.strokeWidth = '0';
    }

    if (conf == 1 && isParole) {
        pathstring += 'L ' + ap1x + ' ' + boxy + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + ap2x + ' ' + boxy + ' ';
        pathstring += 'L ' + (boxx + boxw) + ' ' + boxy + ' ';
    }
    else
        pathstring += 'L ' + (boxx + boxw) + ' ' + boxy + ' ';
    if (conf == 2 && isParole) {
        pathstring += 'L ' + (boxx + boxw) + ' ' + ap1y + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + (boxx + boxw) + ' ' + ap2y + ' ';
        pathstring += 'L ' + (boxx + boxw) + ' ' + (boxy + boxh) + ' ';
    }
    else
        pathstring += 'L ' + (boxx + boxw) + ' ' + (boxy + boxh) + ' ';
    if (conf == 3 && isParole) {
        pathstring += 'L ' + ap2x + ' ' + (boxy + boxh) + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + ap1x + ' ' + (boxy + boxh) + ' ';
        pathstring += 'L ' + boxx + ' ' + (boxy + boxh) + ' ';
    }
    else
        pathstring += 'L ' + boxx + ' ' + (boxy + boxh) + ' ';
    if (conf == 4 && isParole) {
        pathstring += 'L ' + boxx + ' ' + ap2y + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + boxx + ' ' + ap1y + ' ';
        pathstring += 'L ' + boxx + ' ' + boxy + ' ';
    }
    else
        pathstring += 'L ' + boxx + ' ' + boxy + ' ';
    path.setAttribute('d', pathstring);
    svg.appendChild(path);
}

export function createPhylactereOvale(logger, svg, imgratio, id, rgbafillcolor,
    spx, spy, ap1x, ap1y, ap2x, ap2y,
    boxx: number, boxy: number, boxw: number, boxh: number, texteType: TexteType) {
    // logger.debug('createThoughtLine =' + String(imgratio));
    let path = document.createElementNS('http://www.w3.org/2000/svg',
        'path');
    path.setAttribute('fill', rgbafillcolor);
    path.setAttribute('draggable', 'false');
    path.style.position = 'absolute';
    path.style.zIndex = '10';
    path.style.stroke = 'black';
    path.style.strokeWidth = '1';
    path.style.filter = 'url(#shadow)';
    path.setAttribute('data-textid', String(id));
    path.setAttribute('id', 'path' + String(id));
    let conf = 0;
    if (ap1x == boxx) conf = 4
    else if (ap1x == boxx + boxw) conf = 2
    else if (ap1y == boxy) conf = 1
    else if (ap1y == boxy + boxh) conf = 3

    let isParole: boolean;
    isParole = (texteType === TexteType.parole)
    if (texteType === TexteType.text_noborder) {
        path.style.strokeWidth = '0';
    }

    let pathstring = 'M ' + boxx + ' ' + (boxy + boxh / 2) + ' ';
    if (conf == 4 && isParole)
        pathstring = 'M ' + boxx + ' ' + ap1y + ' ';

    if (conf == 1 && isParole) {
        pathstring += 'C ' + boxx + ' ' + boxy + ' ' + boxx + ' ' + boxy + ' ' + ap1x + ' ' + boxy + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + ap2x + ' ' + boxy + ' ';
    }
    else
        pathstring += 'C ' + boxx + ' ' + boxy + ' ' + boxx + ' ' + boxy + ' ' + (boxx + boxw / 2) + ' ' + boxy;
    if (conf == 2 && isParole) {
        pathstring += 'C ' + (boxx + boxw) + ' ' + boxy + ' ' + (boxx + boxw) + ' ' + boxy + ' ' +
            (boxx + boxw) + ' ' + ap1y + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + (boxx + boxw) + ' ' + ap2y + ' ';
    }
    else
        pathstring += 'C ' + (boxx + boxw) + ' ' + boxy + ' ' + (boxx + boxw) + ' ' + boxy + ' ' + (boxx + boxw) + ' ' + (boxy + boxh / 2);
    if (conf == 3 && isParole) {
        pathstring += 'C ' + (boxx + boxw) + ' ' + (boxy + boxh) + ' ' + (boxx + boxw) + ' ' + (boxy + boxh) + ' ' +
            + ap2x + ' ' + (boxy + boxh) + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + ap1x + ' ' + (boxy + boxh) + ' ';
    }
    else
        pathstring += 'C ' + (boxx + boxw) + ' ' + (boxy + boxh) + ' ' + (boxx + boxw) + ' ' + (boxy + boxh) + ' ' +
            (boxx + boxw / 2) + ' ' + (boxy + boxh);
    if (conf == 4 && isParole) {
        pathstring += 'C ' + boxx + ' ' + (boxy + boxh) + ' ' + boxx + ' ' + (boxy + boxh) + ' '
            + boxx + ' ' + ap2y + ' ';
        pathstring += 'L ' + spx + ' ' + spy + ' ';
        pathstring += 'L ' + boxx + ' ' + ap1y + ' ';
    }
    else
        pathstring += 'C ' + boxx + ' ' + (boxy + boxh) + ' ' + boxx + ' ' + (boxy + boxh) + ' '
            + boxx + ' ' + (boxy + boxh / 2) + ' Z';
    path.setAttribute('d', pathstring);
    svg.appendChild(path);
}
export function unhighlightTexte(texteID: string) {
    // this.logger.debug('EditPhotoComponent unhiglight txt id  ' + texteID);
    console.log('util  unhiglight txt id  ' + texteID);
    if (texteID) {

        const textDiv = document.getElementById(texteID);
        // textDiv.setAttribute('draggable', 'true');
        if (textDiv) {
            textDiv.className = 'text-block';
            // textDiv.style.fontSize = '1em';
            // textDiv.style.font = 'normal normal  1em arial,serif';
            textDiv.style.font = 'normal normal   arial,serif';
            textDiv.style.color = 'black';
            textDiv.contentEditable = 'false';
            textDiv.removeEventListener('input', changedtextDiv);
        }
    }
    // suppress dynamically added butons
}

export function highlightTexte(texteID: string, self) {
    // this.logger.debug('EditPhotoComponent higlight txt id  ' + texteID);
    const textDiv = document.getElementById(texteID);
    // textDiv.setAttribute('draggable', 'true');
    textDiv.className = 'text-block-highlight';
    // textDiv.style.fontSize = 'xx-large';
    // textDiv.style.font = 'italic bold 20px arial,serif';
    textDiv.style.font = 'italic bold  arial,serif';
    textDiv.style.color = 'magenta';
    // textDiv.contentEditable = 'true';
    // textDiv.focus();
    // textDiv.addEventListener('input', changedtextDiv.bind(self));
    // dynamically add button to validate future changes

}

export function seteditable(texteID: string, self) {
    const textDiv = document.getElementById(texteID);
    console.log('EditPhotoComponent seteditable txtid  ' + texteID);
    textDiv.contentEditable = 'true';
    textDiv.focus();
    textDiv.addEventListener('input', changedtextDiv.bind(self));
    textDiv.addEventListener("keydown", (e) => {
        if (e.key == "Enter") {
            console.log('EditPhotoComponent seteditable txtid enter ' + e.key);
            //add an ordered list element
            self.selectedTextNewline = true;
        } else {
            self.selectedTextNewline = false;
            console.log('EditPhotoComponent seteditable txtid not enter ' + e.key);
        }
    });
}

export function textvalue(textDiv: HTMLElement): string {
    let newValue = '';
    let isOnFreshLine = true;
    let firstDiv = true;

    let childNodes = textDiv.childNodes;
    // Recursive function to navigate childNodes and build linebreaks with text
    function parseChildNodesForValueAndLines(childNodes: NodeListOf<ChildNode>): string {
        let newValue = '';
        console.log('parseChildNodesForValueAndLines called')
        for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];

            if (childNode.nodeName === 'BR') {
                console.log('parseChildNodesForValueAndLines BR')
                // BRs are always line breaks which means the next loop is on a fresh line
                newValue += '\n';
                isOnFreshLine = true;
                continue;
            }

            // We may or may not need to create a new line
            // if (childNode.nodeName === 'DIV' && isOnFreshLine === false) {
            if (childNode.nodeName === 'DIV') {
                if (firstDiv === false) {
                    console.log('parseChildNodesForValueAndLines DIV')
                    // Divs create new lines for themselves if they aren't already on one
                    newValue += '\n';
                }
                else {
                    firstDiv = false;
                }
            }

            // We may or may not need to create a new line
            if (childNode.nodeName === 'P') {
                console.log('parseChildNodesForValueAndLines P')
                newValue += '\n';
            }
            // Whether we created a new line or not, we'll use it for this content so the next loop will not be on a fresh line:
            isOnFreshLine = false;

            // Add the text content if this is a text node:
            if (childNode.nodeType === 3 && childNode.textContent) {
                console.log('parseChildNodesForValueAndLines textcontent : ' + childNode.textContent)
                newValue += childNode.textContent;
            }

            // If this node has children, get into them as well:
            newValue += parseChildNodesForValueAndLines(childNode.childNodes);
        }
        return newValue;
    }
    return parseChildNodesForValueAndLines(childNodes);
}

export function getPos(target) {
    target.focus()
    let _range = document.getSelection().getRangeAt(0)
    let range = _range.cloneRange()
    range.selectNodeContents(target)
    range.setEnd(_range.endContainer, _range.endOffset)
    return range.toString().length;
}

export function setCarretPos(target, pos) {
    console.log('setCarretPos pos' + pos);
    target.focus()
    // document.getSelection().collapse(target, pos)


    var range = document.createRange()
    var sel = window.getSelection()
    range.setStart(target.childNodes[0], pos)
    range.collapse(true)
    sel.removeAllRanges()
    sel.addRange(range)
    return
}

// function getCaretPosition(ctrl) {
//     // IE < 9 Support 
//     if (document.selection) {
//         ctrl.focus();
//         var range = document.selection.createRange();
//         var rangelen = range.text.length;
//         range.moveStart('character', -ctrl.value.length);
//         var start = range.text.length - rangelen;
//         return {
//             'start': start,
//             'end': start + rangelen
//         };
//     } // IE >=9 and other browsers
//     else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
//         return {
//             'start': ctrl.selectionStart,
//             'end': ctrl.selectionEnd
//         };
//     } else {
//         return {
//             'start': 0,
//             'end': 0
//         };
//     }
// }

// function setCaretPosition(ctrl, start, end) {
//     // IE >= 9 and other browsers
//     if (ctrl.setSelectionRange) {
//         ctrl.focus();
//         ctrl.setSelectionRange(start, end);
//     }
//     // IE < 9 
//     else if (ctrl.createTextRange) {
//         var range = ctrl.createTextRange();
//         range.collapse(true);
//         range.moveEnd('character', end);
//         range.moveStart('character', start);
//         range.select();
//     }

function changedtextDiv(e) {
    console.log('changedtextDiv contenteditable element changed' + e);
    console.log('changedtextDiv photo_id' + this.photo_id);

    const newTexte = new Texte();

    let curpos = getPos(e.target);
    if (this.selectedTextNewline) curpos += 1;
    let newtextvalue = textvalue(e.target)
    this.logger.debug('changedtextDiv innerhtml : ' + e.target.innerHTML);
    console.log('changedtextDiv textvalue : ' + newtextvalue);
    console.log('changedtextDiv getpos : ' + curpos);

    // newTexte.texte = e.target.innerText;
    newTexte.texte = newtextvalue;
    newTexte.photo = this.photo_id;
    newTexte.id = parseInt(this.selectedTextId, 10);

    this.texteService.partialUpdateTexte(newTexte)
        .subscribe(txt => {
            this.logger.debug('partialUpdateTexte ' + JSON.stringify(txt));
        });
    var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, texte: newTexte.texte, carretpos: curpos } : el);
    // var result = this._textes.value.map(el => el.id.toString() == this.selectedTextId ? { ...el, texte: textDiv.innerText } : el);
    this._textes.next(result);
}


export function updateThoughtLine(logger, id, x1, y1, x2, y2, diam = 4, nbstep = 5) {
    // logger.debug('updateThoughtLine id, x1, y1, x2, y2, diam = 4, nbstep = 5', id, x1, y1, x2, y2, 4, 5);
    for (let i = 1; i < nbstep; i++) {
        const selectedCircle = <HTMLElement>document.getElementById('ncirclet' + '-' + String(i) + '-' + String(id));
        // logger.debug('updateThoughtLine nb ' + String(i));
        selectedCircle.setAttribute('cx', String(x1 + i * (x2 - x1) / nbstep));     // setAttribute turns 150 into a string
        selectedCircle.setAttribute('cy', String(y1 + i * (y2 - y1) / nbstep));    // using a string works, too
        selectedCircle.setAttribute('r', String(diam + i * 2));       // give the ncircle a radius so we can see it
        // logger.debug('updateThoughtLine nb ' + String(i));
    }
}

export function updateSpeakLine(logger, id, x1, y1, x2, y2, x3, y3) {
    // logger.debug('updateSpeakLine id, x1, y1, x2, y2, x3,y3', id, x1, y1, x2, y2, x3, y3);
    // logger.debug('updateSpeakLine ');
    const triangle = <HTMLElement>document.getElementById('triangle' + String(id));
    triangle.setAttribute('points', String(x1) + ' ' + String(y1) + ' ' +
        String(x2) + ' ' + String(y2) + ' ' +
        String(x3) + ' ' + String(y3));
    triangle.setAttribute('id', 'triangle' + String(id));
}

export function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export function genImageWithText(self) {
    console.log('genImageWithText file');
    let node3 = document.getElementById('divimage');
    domtoimage.toPng(node3)
        .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            document.body.appendChild(img);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = dataUrl;

            let blob = dataURLtoBlob(dataUrl);
            const file = new File(
                [blob],
                "blob.png"
                // ,
                // {
                //   type: "image/jpeg",
                //   lastModified: (new Date()).to()
                // }
            );
            let fileURL = self.uploadService.upload(file);
            console.log('upladed file' + fileURL);

            // the filename you want
            a.download = 'divimage.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
            // download(dataUrl, 'divimage.png');
        }.bind(this))
        // .bind(this)
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
    console.log('genImageWithText file end');
}

export function genImageWithTextFast(self, imgDiv, fname: string, containername: string): string {
    let fileURL;
    console.log('genImageWithText file');
    domtoimage.toPng(imgDiv)
        .then(function (dataUrl) {
            let blob = dataURLtoBlob(dataUrl);
            const file = new File(
                [blob],
                "blob.png"
            );
            fileURL = self.uploadService.uploadToContainerWithName(file, fname, containername);
            console.log('upladed file' + fileURL);
            // return fileURL;
        }.bind(this))
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
            // return '';
        });
    console.log('genImageWithText file end');
    return fileURL;
}
export function getrandombool(): boolean {
    return (Math.random() < 0.5);
}