
<ng-container *ngIf="(errors$ | async) as errors">

    <div class="messages-container" *ngIf="showMessages">

        <div class="message" *ngFor="let error of errors">
            {{error}}
        </div>

        <mat-icon class="close" (click)="onClose()">close</mat-icon>

    </div>

</ng-container>


