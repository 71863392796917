<form class="serie-form-flex"
      [formGroup]="episodeForm"
      (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined"
              class="my-card">
        <mat-card-title>
            {{pageTitle}}
        </mat-card-title>
        <mat-card-content>

            <mat-form-field class="full-width">
                <mat-label>title</mat-label>
                <input matInput
                       type="text"
                       id="titleInput"
                       placeholder="Title"
                       [formControl]="episodeForm.controls['title']">
                <mat-error *ngIf="episodeForm.controls.title.invalid">title is required</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width"
                            appearance="outline">
                <mat-label>Desc</mat-label>
                <!-- <input matInput
                       type="text"
                       id="descInput"
                       placeholder="Desc"
                       [formControl]="episodeForm.controls['desc']"> -->
                <textarea matInput
                          formControlName="desc"
                          placeholder="Description"
                          cdkTextareaAutosize=""
                          [cdkAutosizeMinRows]="2"
                          [cdkAutosizeMaxRows]="5"
                          [maxlength]="200">
                                               </textarea>
                <mat-error *ngIf="episodeForm.controls.desc.invalid">desc is required</mat-error>
            </mat-form-field>

            <div class="status">
                <mat-label id="example-radio-group-label"
                           class="example-radio-group">Status</mat-label>

                <mat-radio-group matInput
                                 aria-labelledby="example-radio-group-label"
                                 class="example-radio-group"
                                 formControlName="status"
                                 value='1'>
                    <!-- <mat-label>status</mat-label> -->
                    <!-- <span style="margin-right:3%;">Status : </span> -->
                    <mat-radio-button class="example-radio-button"
                                      [value]=1>
                        draft
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button"
                                      [value]=3>
                        published
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <!-- <mat-form-field class="full-width">
                <mat-label>Numero</mat-label>
                <input matInput
                       type="number"
                       id="numeroInput"
                       placeholder="Numero"
                       [formControl]="episodeForm.controls['numero']">
            </mat-form-field> -->

            <!-- <mat-label class="example-radio-group">Episode thumbnail</mat-label> -->
            <!-- <img *ngIf="episode" -->
            <!-- <img mat-card-image
                 class="episodeimg"
                 *ngIf="episode && (imageService.ogphotourl(episode.photocontainer,episode.photoname)|async) as imgurl"
                 [src]="imgurl"
                 alt="desc 1"> -->
            <input type="file"
                   id="fileInput"
                   accept="image/png, image/jpeg"
                   (change)="onFileChange($event)"
                   placeholder="Episode thumnail"
                   [formControl]="episodeForm.controls['file']" />
            <mat-error *ngIf="episodeForm.controls.file.invalid">file is required</mat-error>

            <div *ngIf="preview && preview !== ''"> <img class="imgpreview"
                     [src]="preview">
            </div>
            <!-- <p *ngIf="episodeForm.controls.file.errors?.required"
               style="color: red">This field is required!</p> -->
            <!-- <div>: {{fileName}} </div> -->
        </mat-card-content>
        <mat-card-actions class="myactions">
            <button mat-raised-button
                    matTooltipPosition="above"
                    matTooltip="Cancel Episode modification"
                    [matTooltipShowDelay]="100"
                    [matTooltipHideDelay]="300"
                    color="accent"
                    type="button"
                    (click)="onCancel()">cancel</button>
            <button mat-raised-button
                    matTooltip="Validate Episode modification"
                    [matTooltipShowDelay]="100"
                    [matTooltipHideDelay]="300"
                    color="primary"
                    [disabled]="episodeForm.invalid"
                    type="submit">{{buttonText}}</button>

        </mat-card-actions>

    </mat-card>
</form>