import { Texte } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-background-color-picker-dialog',
  templateUrl: './background-color-picker-dialog.component.html',
  styleUrls: ['./background-color-picker-dialog.component.scss']
})
export class BackgroundColorPickerDialogComponent {

  @Input() className: string;
  @Input() color: string;
  @Input() disabled: boolean;
  @Output() colorChange = new EventEmitter<string>();
  @Output() update = new EventEmitter<any>();
  texteForm: FormGroup;
  description: string;
  text_id: number;
  photo_id: number;

  constructor(
    private dialogRef: MatDialogRef<BackgroundColorPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.logger.debug('BackgroundColorPickerDialogComponent data : ' + JSON.stringify(data));
    this.text_id = data.text_id;
    this.photo_id = data.id;
    this.color = data.color;
  }

  ngOnInit(): void {
    toggle: false;
    // this.texteForm = this.fb.group({
    //   'background_color': ['rgba(255, 248, 220, 0.1)', Validators.required],
    // });
  }
  // colorPickerOpen(value) {
  //   this.logger.debug('BackgroundColorPickerDialogComponentcolor colorPickerOpen');
  // }
  colorPickerClose(value) {
    this.logger.debug('BackgroundColorPickerDialogComponentcolor colorPickerClose');
    this.logger.debug('BackgroundColorPickerDialogComponent value' + value);
    // if (this.disabled) return;
    this.color = value || this.color;
    this.colorChange.emit(this.color);
    // this.texteForm.setValue({ background_color: value });
    this.update.emit();
    this.dialogRef.close(value);
  }
  colorPickerChange(value) {
    this.logger.debug('BackgroundColorPickerDialogComponentcolor colorPickerChange');
    this.logger.debug('BackgroundColorPickerDialogComponent value' + value);
    // if (this.disabled) return;
    this.color = value || this.color;
    this.colorChange.emit(this.color);
    // this.texteForm.setValue({ background_color: value });
    this.update.emit();
  }
  colorPickerSelect(value) {
    this.logger.debug('BackgroundColorPickerDialogComponentcolor colorPickerSelect');
    this.logger.debug('BackgroundColorPickerDialogComponent value' + value);
    // if (this.disabled) return;
    this.color = value || this.color;
    this.colorChange.emit(this.color);
    // this.texteForm.setValue({ background_color: value });
    this.update.emit();
    this.dialogRef.close(value);
  }
  colorPickerCancel() {
    this.logger.debug('BackgroundColorPickerDialogComponentcolor colorPickerCancel');
    this.dialogRef.close(this.texteForm.value);
  }
  onSubmit() {
    const formValue = this.texteForm.value;
    // const formValue = data;
    const newTexte = new Texte();
    console.log('you submitted value: ', formValue);
    newTexte.id = this.text_id;
    newTexte.photo = this.photo_id;
    newTexte.background_color = formValue['background_color'];

    console.log('texteform newtexte background color: ', newTexte.background_color);
    this.texteService.partialUpdateTexte(newTexte)
      .subscribe(txt => {
        console.log('background_color txt :' + txt.background_color);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close(this.texteForm.value);
  }

  close() {
    this.dialogRef.close();
  }
}