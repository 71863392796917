import { Author } from "./author";
import { Serie } from "./serie";

export enum StatusType {
    DRAFT_STATUS = 1,
    GOPUBLIC_STATUS = 2,
    PUBLIC_STATUS = 3,
    INAPPROPRIATE = 4
};

export class Episode {
    public id: number;
    public owner: Author;
    // public owner: number;
    public title: string;
    public desc: string;
    public initial_language: string;
    public status: StatusType;
    public numero: number;
    public photourl: string;
    public photoname: string;
    public photocontainer: string;
    public pub_date: Date;
    public serie_id: number;
    public serie: Serie | number;
    // public serie: number;
    public nb_likes: number;
    public islikedbyme: boolean;
    public nb_comments: number;
    public iscommentedbyme: boolean;
    public nb_bookmarks: number;
    public isbookmarkedbyme: boolean;
    public nb_shares: number;
}
