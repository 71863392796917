<div class="rating">
    <mat-star-rating [rating]="rating"
                     [starCount]="starCount"
                     [color]="starColor"
                     (ratingUpdated)="onRatingChanged($event)"></mat-star-rating>
    <button mat-raised-button
            matTooltipPosition="above"
            matTooltip="Cancel Serie creation"
            [matTooltipShowDelay]="500"
            [matTooltipHideDelay]="500"
            color="accent"
            type="button"
            (click)="onCancel()">cancel</button>
    <button mat-raised-button
            matTooltipPosition="above"
            matTooltip="validate rating"
            [matTooltipShowDelay]="500"
            [matTooltipHideDelay]="500"
            color="accent"
            type="button"
            (click)="onValidate()">validate</button>
</div>