<mat-dialog-content [formGroup]="texteForm">
    <mat-form-field>
        <select #langSelect
                (change)="changeLang(langSelect.value)">
            <option *ngFor="let lang of getLanguages()"
                    [value]="lang"
                    [selected]="lang === getCurrentlanguage()">{{ lang }}</option>
        </select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button id="id_parolebtn"
            (click)="close()"
            class="btn btn-primary">Cancel</button>
    <button id="id_parolebtn"
            (click)="save()"
            class="mat-raised-button  btn btn-primary">Ok</button>
</mat-dialog-actions>