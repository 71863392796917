<mat-dialog-content>
    You need to select a text first.
    Click on a text to select it
    and rety your modification.

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button id="id_parolebtn"
            (click)="close()"
            class="btn btn-primary">Ok</button>
</mat-dialog-actions>