<ng-container *ngIf="author">
    <mat-card appearance="outlined"
              class="my-card">
        <mat-card-header>
            <div class="topheader">
                <button mat-icon-button
                        class="back"
                        (click)="goBack()">
                    <mat-icon><span class="material-symbols-outlined">
                            arrow_back_ios
                        </span></mat-icon>
                </button>
                <!-- src="{{ imageService.gphotourl(author.photocontainer,author.photoname) }}" -->
                <img class="myavatar"
                     *ngIf="(imageService.ogphotourl(author.photocontainer,author.photoname)|async) as imgurl"
                     [src]="imgurl"
                     alt="Desc 1"
                     (click)="openfullscreenimageDialog()">
                <mat-card-title>&#64;{{author.pseudo}}</mat-card-title>
            </div>
        </mat-card-header>
        <mat-card-content>
            <mat-card-subtitle class="multilinetext">{{author.bio}}</mat-card-subtitle>
        </mat-card-content>
        <mat-card-actions align="right">
            <!-- <button mat-raised-button
                            [disabled]="!authenticationService.isLoggedIn()"
                            color="primary"
                            class="subscribe"
                            i18n-title
                            title="subscribe author"
                            (click)="follow(author)"
                            i18n="author|subscription to a author@@author_subscription">
                        follow
                    </button> -->
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    title="follow author"
                    (click)="follow(author)">
                <mat-icon [matBadge]="nbfollowers"
                          matBadgeColor="warn"
                          matBadgePosition="after"
                          [ngStyle]="{'color': isfollowedbyme ? 'yellow' : 'blue'}"
                          aria-label="Example icon-button with a heart icon">person_add</mat-icon>
            </button>
            <button mat-icon-button
                    [disabled]="!authenticationService.isLoggedIn()"
                    class="subscribe"
                    i18n-title
                    title="comment author"
                    (click)="display_comment=!display_comment"
                    i18n="serie|subscription to a serie@@serie_subscription">
                <mat-icon [disabled]="!authenticationService.isLoggedIn()"
                          [matBadge]="nbcomments"
                          [ngStyle]="{'color': iscommentedbyme ? 'yellow' : 'blue'}"
                          matBadgePosition="after"
                          matBadgePosition="after">comment</mat-icon>
            </button>
            <!--

                    <ngb-rating [(rate)]="currentRate" (rateChange)='rateChange($event)'></ngb-rating>
                    <span>
                        <b>{{currentRate}}</b>/<b>{{currentRate2}}</b>
                    </span>
                    -->
        </mat-card-actions>
    </mat-card>
    <app-comments [comment_type]="'user'"
                  [user_id]="authId"
                  [ngStyle]="{'display': display_comment ? 'none' : ''}"></app-comments>
    <app-episodes [title]="'siiiii episodes'"
                  [author_id]="authId"
                  [chooseDisplayMode]="false"
                  [displayMode]="'list'">
    </app-episodes>
    <!-- <h2 i18n="author|authors episodes@@author_episodes">{{ author.pseudo | uppercase }}: {{
            author.id }} 's series
        </h2> -->
    <!-- <mat-tab-group class="tabs"
                   *ngIf="display!='simple'">
        <mat-tab label="episodes">
            <app-episodes [title]="author.pseudo + author.id + '\'s episodes'"
                          [author_id]="authorId"
                          [chooseDisplayMode]="false"
                          [displayMode]="display">
            </app-episodes>
        </mat-tab>
        <mat-tab label="series">
            <app-series [title]="author.pseudo + author.id + '\'s series'"
                        [author_id]="authorId"
                        [chooseDisplayMode]="false"
                        [displayMode]="display">
            </app-series>
        </mat-tab>
    </mat-tab-group> -->
</ng-container>