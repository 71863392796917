import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../serie-detail.component';
import { StarRatingColor } from '../../star-rating/star-rating.component';

@Component({
  selector: 'app-rating-dialog',
  templateUrl: './rating-dialog.component.html',
  styleUrls: ['./rating-dialog.component.scss']
})
export class RatingDialogComponent implements OnInit {


  //mat rating
  rating: number = 3;
  starCount: number = 5;
  starColor: StarRatingColor = StarRatingColor.accent;
  starColorP: StarRatingColor = StarRatingColor.primary;
  starColorW: StarRatingColor = StarRatingColor.warn;


  constructor(public dialogRef: MatDialogRef<RatingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  onValidate(): void {
    this.dialogRef.close({ rating: this.rating });
  }
  // m
  onNoClick(): void {
    this.dialogRef.close();
  }
  // mat rating
  onRatingChanged(rating) {
    console.log(rating);
    this.rating = rating;
  }
}
