<div *ngIf="photo"
     class="container">

  <button mat-icon-button
          (click)="goBack()">
    <mat-icon><span class="material-symbols-outlined">
        arrow_back_ios
      </span></mat-icon>
  </button>
  <!-- <div>
    <button mat-icon-button
            routerLink="/editphoto/{{photo.id}}"
            title="edit photo">
      <mat-icon aria-label="edit">edit</mat-icon>
    </button>
  </div> -->

  <div class="photow"
       id="divimage">
    <!-- <div *ngIf="(authenticationService.isOwner(photo.owner.id)) " -->
    <div class="buttonoverlay">
      <button mat-icon-button
              class="buttoncolor"
              [routerLink]="['/editphoto/'+photo.id,{episodeMode: 'edit'}]"
              title="edit photo from episode">
        <mat-icon aria-label="heart icon">edit</mat-icon>
      </button>
      <button mat-icon-button
              class="buttoncolor"
              title="delete photo"
              (click)="deletePhotoDialog(photo.id)">
        <mat-icon aria-label="delete photo">delete</mat-icon>
      </button>
    </div>
    <img class="photo"
         [src]="imageService.gphotourl(photo.photocontainer,photo.photoname)"
         alt="{{photo.desc}} " />
  </div>
  <!-- <h2>{{ photo.desc | uppercase }} Details</h2>
  <div><span>id: </span>{{photo.id}}</div>
  <div><span>desc: </span>{{photo.desc}}</div>
  <div><span>numero: </span>{{photo.numero}}</div>
  <button (click)="comment(photo)">comment</button>
  <button (click)="like(photo)">like</button>
  <button (click)="edit(photo)">edit(photo)</button>
  <app-textes [photo_id]="photo.id"></app-textes> -->
</div>