import { AuthenticationService } from '@/_services';
import { Texte } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss']
})
export class LanguageDialogComponent {
  texteForm: FormGroup;
  description: string;
  text_id: number;
  photo_id: number;

  constructor(
    private dialogRef: MatDialogRef<LanguageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private texteService: TexteService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private logger: NGXLogger
  ) {
    this.logger.debug('newTextDialog constructor ' + JSON.stringify(data));
    this.text_id = data.text_id;
    this.photo_id = data.id;
  }

  ngOnInit(): void {
    this.texteForm = this.fb.group({
      'shape': [1, Validators.required],
    });
  }

  save() {
    const formValue = this.texteForm.value;
    const newTexte = new Texte();
    console.log('you submitted value: ', formValue);
    newTexte.id = this.text_id;
    newTexte.photo = this.photo_id;
    newTexte.shape = formValue['shape'];

    console.log('texteform newtexte: ', newTexte.texte);
    this.texteService.partialUpdateTexte(newTexte)
      .subscribe(txt => {
        console.log('add_parole txt :' + txt.texte);
        console.log('add_parole id :' + txt.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close(this.texteForm.value);
  }

  close() {
    this.dialogRef.close();
  }

  getLanguages(): string[] {
    return this.translate.getLangs().map((lg) => { if (lg == 'zh-hans') { return 'zh' } else return lg });
  }

  getCurrentlanguage(): string {
    return (this.translate.currentLang == 'zh-hans') ? 'zh' : this.translate.currentLang;
  }
  changeLang(lg: string) {
    let language = (lg == 'zh') ? 'zh-hans' : lg;
    this.translate.use(language)
    this.translate.setDefaultLang(language)
    localStorage.setItem('locale', language)
  }


}
