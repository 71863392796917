<mat-dialog-content>

    Bienvenue sur Picafrou !

    Les présentes Conditions d’utilisation (ou « Conditions ») régissent votre utilisation
    de Picafrou, sauf lorsque nous indiquons expressément que des conditions distinctes (et non les
    présentes) s’appliquent, et fournissent des informations sur le Service Picafrou (le « Service
    »),
    détaillé ci-dessous. En créant un compte Picafrou ou en utilisant Picafrou, vous acceptez les
    présentes Conditions. Les Conditions de service de Meta ne s’appliquent pas à ce Service.

    Cliquez ici pour obtenir un Résumé du contrat et ici pour obtenir d’autres informations que le
    Code des communications électroniques européen nous oblige à fournir.


    Le Service Picafrou
    Nous acceptons de vous fournir le Service Picafrou. Le Service comprend l’ensemble des
    produits, des fonctionnalités, des applications, des services, des technologies et des logiciels
    Picafrou
    que nous vous fournissons afin de promouvoir la mission de Picafrou : vous rapprocher des
    personnes et des choses que vous aimez. Le Service inclut les aspects suivants :
    Offrir des opportunités personnalisées pour créer, communiquer, établir des liens, découvrir et
    partager. Chaque personne est différente. Nous vous proposons donc différents types de comptes
    et de fonctionnalités pour vous aider à créer, partager, développer votre présence et
    communiquer sur et en dehors de Picafrou. Nous souhaitons également renforcer vos relations au
    moyen
    d’expériences partagées qui comptent vraiment à vos yeux. Ainsi, nous créons des systèmes qui
    tentent de
    déterminer quelles personnes et quels intérêts comptent pour vous et pour les autres, et nous
    utilisons ces informations afin de vous aider à créer, à trouver, à vivre et à partager des
    expériences importantes à vos yeux. Cela consiste notamment à mettre en avant du contenu, des
    fonctionnalités, des offres et des comptes qui sont susceptibles de vous intéresser, et de vous
    offrir divers moyens d’utiliser Picafrou en fonction de ce que vous et d’autres personnes font
    sur Picafrou et en dehors. Veuillez consulter les Fiches système de nos systèmes de
    recommandation pour obtenir des informations sur la manière dont nos différents systèmes de
    recommandation
    hiérarchisent les signaux afin de déterminer le contenu et les suggestions à vous présenter,
    ainsi que sur les
    options permettant de personnaliser votre expérience. Vous pouvez également consulter notre
    Politique de confidentialité pour en savoir plus sur la façon dont nous personnalisons votre
    expérience Picafrou.
    Nous développons et utilisons des outils, et offrons des ressources aux membres de notre
    communauté afin de contribuer à rendre leurs expériences positives et inclusives, y compris
    lorsque nous
    pensons qu’ils pourraient avoir besoin de notre aide. Nous disposons également d’équipes et de
    systèmes qui s’efforcent de lutter contre les abus et les violations de nos Conditions
    générales, ainsi que contre les comportements préjudiciables et trompeurs. Pour en savoir plus
    sur les
    restrictions que nous pouvons imposer à l’utilisation de Picafrou, y compris des informations
    sur la façon dont nous appliquons nos politiques de contenu, nos procédures, nos mesures et nos
    outils,
    cliquez ici. Pour en savoir plus sur la manière dont nous traitons les infractions répétées de
    nos politiques de contenu, cliquez ici ; par exemple, notre Politique d’utilisation détournée
    explique comment nous pouvons suspendre l’accès à Picafrou lorsque nous déterminons que vous
    avez
    fréquemment fourni un contenu manifestement illégal, ou suspendre le traitement des signalements
    et des réclamations lorsque nous déterminons que vous avez fréquemment soumis des signalements
    ou
    des réclamations manifestement infondés. Nous utilisons toutes les informations à notre
    disposition,
    y compris les vôtres, pour tenter de garantir la sécurité de notre plateforme. Il se peut
    également que nous partagions des informations concernant une utilisation abusive ou du contenu
    préjudiciable avec les forces de l’ordre. Pour en savoir plus, consultez la Politique de
    confidentialité.

    Développer et utiliser des technologies nous permettant d’offrir constamment des services à
    notre communauté grandissante La gestion et l’analyse des informations pour notre communauté
    grandissante sont essentielles à
    notre Service. Une grande partie de notre Service consiste à créer et à utiliser des
    technologies de pointe capables de personnaliser, de protéger et d’améliorer notre Service à une
    très grande
    échelle pour une communauté mondiale étendue. Des technologies telles que l’intelligence
    artificielle et
    l’apprentissage automatique nous offrent la capacité d’appliquer des processus complexes à tout
    notre Service. Les technologies automatisées nous permettent également d’assurer le
    fonctionnement et l’intégrité de notre Service. Pour en savoir plus, consultez la Politique de
    confidentialité.

    Communiquer avec vous.
    Nous pouvons être amenés à vous envoyer des communications, telles que des e-mails ou des
    notifications au sein des produits, pour vous répondre ou vous informer sur des questions
    relatives aux produits, sur la recherche ou sur nos conditions et politiques. Pour en apprendre
    davantage,
    veuillez consulter notre Politique de confidentialité.

    Assurer l’accès à notre Service.
    Pour assurer le fonctionnement de notre service à l’échelle mondiale, nous devons stocker et
    transférer des données à travers nos systèmes dans le monde entier, y compris en dehors de votre
    pays de résidence. L’utilisation de cette infrastructure globale est nécessaire et essentielle
    pour fournir notre Service.
    Cette infrastructure peut être possédée ou exploitée par ...
    .Veuillez consulter notre Politique de confidentialité pour en savoir plus sur la manière dont
    nous transférons, stockons et traitons
    vos informations.
    Vous connecter aux marques, aux produits et aux services de la manière qui vous convient le
    mieux.
    Sous réserve des choix que vous faites et des réglages que vous définissez, nous utilisons les
    informations de Picafrou ainsi que de partenaires externes, pour personnaliser votre expérience
    sur Picafrou,
    y compris pour vous montrer des publicités , des offres et d’autres types de contenu
    sponsorisé qui, selon nous, seront pertinents pour vous. Nous faisons de notre mieux pour que ce
    contenu soit aussi pertinent que vos autres expériences sur Picafrou. Veuillez consulter notre
    Politique de confidentialité afin d’en savoir plus sur la manière dont nous utilisons les
    informations pour vous offrir une expérience personnalisée.

    Mise à disposition et amélioration de Picafrou.
    Nous utilisons les informations à notre disposition afin de développer, tester et améliorer
    notre Service et de collaborer avec des tiers dans le cadre de recherches visant à perfectionner
    notre
    Service et contribuer au bien-être de notre communauté. Cela inclut d’analyser les informations
    que nous avons concernant nos utilisateurs et de comprendre comment les personnes utilisent nos
    Services, par exemple, en effectuant des sondages et des tests et en résolvant les problèmes qui
    surviennent dans de nouvelles fonctionnalités.
    Veuillez consulter notre Politique de confidentialité afin d’en savoir plus sur la manière dont
    nous utilisons vos informations pour offrir, développer et améliorer Picafrou.

    Comment Picafrou est-il financé
    Vous pouvez choisir d’utiliser le Service gratuitement avec des publicités ou de vous abonner
    pour les utiliser sans publicité :
    Si vous choisissez d’utiliser gratuitement le Service avec des publicités, vous nous autorisez à
    vous afficher des publicités que les entreprises et les organisations nous paient pour
    promouvoir.
    Si vous choisissez de vous abonner pour utiliser Picafrou vous ne
    verrez pas de publicité de notre part et nous n’utiliserons pas vos données à des fins
    publicitaires.
    Les détails du prix, de la devise et de la période de paiement pour l’abonnement sans publicité
    vous
    seront fournis avant que vous ne finalisiez votre achat.
    Si nous vous affichons des publicités sur les Produits Meta, nous vous affichons des publicités
    pertinentes et utiles sans informer les annonceurs de votre identité. Nous ne vendons pas les
    informations vous concernant. Nous autorisons les annonceurs à nous donner des informations,
    telles que leur objectif commercial et le type d’audience à laquelle ils souhaitent adresser
    leurs
    publicités (par exemple, les personnes de 18 à 35 ans qui vivent près du magasin de l’annonceur
    au Portugal). Puis, nous affichons leur publicité aux personnes qui, selon nous, pourraient la
    trouver pertinente.
    De plus, nous envoyons aux annonceurs des rapports sur les performances de leurs publicités pour
    les aider à comprendre comment les personnes interagissent avec leur contenu sur Picafrou et en
    dehors.
    Nous fournissons par exemple aux annonceurs des informations démographiques générales et liées
    aux centres d’intérêts aux annonceurs pour les aider à mieux comprendre leur audience. Nous ne
    partageons aucune information qui vous identifie directement (des informations comme votre nom
    ou adresse e-mail qui, en elles-mêmes, peuvent servir à vous contacter ou à vous identifier)
    sans
    avoir obtenu votre autorisation spécifique. Découvrez comment fonctionnent les publicités
    Picafrou
    ici.
    Vous pouvez voir du contenu de marque sur Picafrou publié par des titulaires de compte qui font
    la promotion de produits ou services sur la base d’une relation commerciale avec le partenaire
    commercial mentionné dans leur contenu. Pour en savoir plus sur ce point, cliquez ici.
    Vous pouvez en apprendre davantage sur la manière dont nous recueillons et utilisons vos
    informations dans notre Politique de confidentialité. Vous y trouverez notamment plus de détails
    sur les différents types d’informations que nous pouvons utiliser pour vous afficher des
    publicités.
    Vous pouvez contrôler les types de
    publicités et d’annonceurs que vous voyez ainsi que les types d’informations que nous utilisons
    pour définir les publicités que nous vous montrons. En savoir plus.

    La Politique de Confidentialité
    La mise à disposition de notre Service nécessite la collecte et l’utilisation de vos
    informations.
    La Politique de Confidentialité explique comment nous recueillons, utilisons et partageons des
    informations à travers les Produits Meta. Elle présente également les différentes manières dont
    vous pouvez contrôler vos informations, y compris dans les Paramètres de sécurité et de
    confidentialité de Picafrou.

    Vos engagements
    En contrepartie de notre engagement à vous fournir le Service, nous vous demandons de prendre
    les engagements suivants envers nous.
    Qui peut utiliser Picafrou. Nous souhaitons que notre Service soit aussi ouvert et inclusif que
    possible, mais nous voulons également qu’il soit sûr, sécurisé et conforme à la loi. Par
    conséquent, nous avons besoin que vous vous engagiez à respecter quelques restrictions afin de
    faire partie
    de la communauté Picafrou.
    Vous devez avoir au moins 13 ans.
    Il ne doit pas vous être interdit de bénéficier d’un quelconque aspect de notre service en vertu
    des lois applicables ou d’utiliser des services de paiement si vous figurez sur une liste noire
    applicable.
    Votre compte ne doit pas avoir déjà été désactivé pour avoir enfreint la loi ou nos politiques.
    Vous ne devez pas être un délinquant sexuel condamné.
    Utilisations interdites de Picafrou. La fourniture d’un Service ouvert et sûr à une vaste
    communauté nécessite que chacun y apporte sa contribution.
    Vous ne pouvez pas usurper l’identité d’autrui ni fournir des informations erronées.
    Vous n’avez pas l’obligation de révéler votre identité sur Picafrou, mais vous devez nous
    fournir
    des informations exactes et à jour (y compris les informations d’inscription), ce qui peut
    inclure
    de fournir des données personnelles. Vous ne pouvez pas usurper l’identité de quelqu’un ou d’une
    chose que vous n’êtes pas et vous ne pouvez pas créer de compte pour une autre personne, sauf si
    vous avez son autorisation expresse.
    Vous ne pouvez rien faire qui soit illégal, trompeur ou frauduleux, ni agir dans un but illicite
    ou
    interdit.
    Vous ne pouvez pas enfreindre (ni aider ou inciter autrui à enfreindre) les présentes Conditions
    ou
    nos politiques, notamment et en particulier les Règles de la communauté Picafrou, les
    Conditions
    générales de la Plateforme Meta et les Règles pour les développeurs et les Règles applicables à
    la
    musique.
    Si vous postez du contenu de marque, vous devez respecter nos Politiques de contenu de marque,
    ce
    qui vous oblige à utiliser notre outil de contenu de marque. Découvrez comment signaler un
    comportement ou du contenu dans nos Pages d’aide.
    Vous ne pouvez rien faire qui interfère avec le fonctionnement prévu du Service ou qui nuise à
    celui-ci.
    Cela inclut l’utilisation abusive de tout canal de signalement, de contestation ou de recours,
    comme
    en effectuant des signalements ou recours frauduleux ou non fondés.
    Vous ne pouvez pas tenter de créer de comptes, ni de recueillir des informations ou d’y accéder
    de
    manière illégale.
    Cela inclut la création de comptes ou la collecte d’informations de manière automatisée sans
    notre
    autorisation expresse.
    Vous ne pouvez pas vendre, donner en licence, ou acheter aucun compte ou aucune donnée obtenus
    auprès de nous ou de nos Services.
    Cela inclut les tentatives d’achat, de vente ou de transfert de tout aspect de votre compte (y
    compris votre nom d’utilisateur) ; de solliciter, de recueillir ou d’utiliser les identifiants
    de
    connexion ou les badges d’autres utilisateurs ; ou de demander ou recueillir des noms
    d’utilisateur,
    mots de passe Picafrou, ou de détourner des tokens d’accès.
    Vous ne pouvez pas publier les informations privées ou confidentielles d’une autre personne sans
    autorisation ou faire quelque chose qui enfreint les droits d’une autre personne, y compris les
    droits de propriété intellectuelle (ex. violation des droits d’auteur, violation d’une marque
    déposée, ou marchandises contrefaites ou piratées).
    Vous pouvez utiliser les œuvres d’une autre personne sous réserve des exceptions ou limitations
    aux
    droits d’auteur et aux droits voisins en vertu de la loi applicable. Vous déclarez que vous
    possédez
    ou avez obtenu tous les droits nécessaires concernant le contenu que vous publiez ou partagez.
    Apprenez-en davantage ici, notamment sur la manière de signaler du contenu qui, à votre avis,
    enfreint vos droits de propriété intellectuelle.
    Vous ne pouvez pas modifier, traduire, créer des œuvres dérivées ou faire de l’ingénierie
    inverse
    sur nos produits ou leurs composants.
    Vous ne pouvez pas utiliser un nom de domaine ou une URL dans votre nom d’utilisateur sans notre
    consentement écrit préalable.
    Les autorisations que vous nous accordez. Dans le cadre de notre accord, vous nous accordez
    également les autorisations nécessaires à la fourniture du Service.
    Nous ne revendiquons pas la propriété de votre contenu, mais vous nous en accordez une licence
    d’utilisation.
    Aucune modification n’est apportée à vos droits sur votre contenu. Nous ne revendiquons pas la
    propriété du contenu que vous publiez sur ou par le biais du Service et vous êtes libre de
    partager
    votre contenu avec n’importe qui, où bon vous semble. Cependant, nous avons besoin de certaines
    autorisations juridiques de votre part (que nous appelons une « licence ») pour fournir le
    Service.
    Lorsque vous partagez, publiez ou importez du contenu protégé par des droits de propriété
    intellectuelle (comme des photos ou des vidéos) sur notre Service ou en rapport avec ce dernier,
    vous nous accordez une licence non exclusive, gratuite, transférable, sous-licenciable et
    mondiale
    pour héberger, utiliser, distribuer, modifier, exécuter, copier, diffuser ou afficher
    publiquement,
    traduire et créer des œuvres dérivées de votre contenu (conformément à vos paramètres de
    confidentialité et d’application). La licence prend fin lorsque votre contenu est supprimé de
    nos
    systèmes. Vous pouvez supprimer votre contenu individuellement ou tout d’un coup en supprimant
    votre
    compte. Pour en savoir plus sur la manière dont nous utilisons les informations et sur la
    procédure
    à suivre pour contrôler ou supprimer votre contenu, consultez la Politique de Confidentialité
    ainsi
    que les Pages d’aide Picafrou.
    Autorisation d’utiliser votre nom d’utilisateur, votre photo de profil et les informations
    relatives
    à vos relations et à vos actions en rapport avec les comptes, les publicités (si nous vous
    affichons
    des publicités sur les Produits Meta) et le contenu sponsorisé.
    Vous nous donnez l’autorisation d’afficher votre nom d’utilisateur, votre photo de profil ainsi
    que
    les informations relatives à vos actions (comme les mentions J’aime) et à vos relations (comme
    les
    abonnements) à proximité ou en lien avec les comptes, les publicités, les offres et tout autre
    contenu sponsorisé que vous suivez ou avec lesquels vous interagissez qui apparaissent dans les
    Produits Meta, sans vous verser aucune compensation. Par exemple, nous pouvons montrer que vous
    avez
    aimé une publication sponsorisée créée par une marque nous ayant rémunéré pour que nous
    affichions
    ses publicités sur Picafrou. Comme pour les actions sur d’autres contenus et les abonnements à
    d’autres comptes, les actions sur du contenu sponsorisé et les abonnements à des comptes
    sponsorisés
    sont uniquement visibles par les personnes autorisées à voir ce contenu ou à s’y abonner. Nous
    respectons également vos paramètres publicitaires. Apprenez-en davantage sur vos paramètres
    publicitaires ici.
    Vous acceptez que nous téléchargions et installions des mises à jour du Service sur votre
    appareil.

    Droits supplémentaires que nous conservons
    Si vous choisissez un nom d’utilisateur ou un identifiant similaire pour votre compte, nous
    pouvons
    modifier celui-ci si nous pensons qu’il est approprié ou nécessaire de le faire (par exemple,
    s’il
    enfreint la propriété intellectuelle de quelqu’un ou usurpe l’identité d’un autre utilisateur).
    Si vous utilisez du contenu couvert par des droits de propriété intellectuelle que nous détenons
    et
    rendons disponibles dans notre Service (par exemple, des images, des dessins, des vidéos ou des
    sons
    que nous fournissons et que vous ajoutez à du contenu que vous créez ou partagez), nous
    conservons
    tous les droits relatifs à notre contenu (mais pas au vôtre).
    Vous ne pouvez utiliser notre propriété intellectuelle et nos marques de commerce, ou toute
    autre
    marque similaire, que dans les cas expressément autorisés par nos Règles d’utilisation des
    marques
    ou après avoir préalablement reçu une autorisation écrite de notre part.
    Vous devez obtenir notre autorisation écrite ou une autorisation dans le cadre d’une licence
    open
    source pour modifier, créer des œuvres dérivées, décompiler ou tenter d’extraire notre code
    source
    de toute autre manière.

    Suppression de contenu et désactivation ou résiliation de votre compte
    Nous pouvons supprimer ou restreindre tout contenu ou toute information que vous partagez sur le
    Service si nous estimons que cela enfreint les présentes Conditions d’utilisation ou nos
    politiques
    (y compris nos Règles de la Communauté Picafrou), ou si la loi nous y oblige. Nous pouvons
    immédiatement refuser de vous fournir le Service ou arrêter de vous fournir le Service en tout
    ou en
    partie (et notamment en mettant fin ou en désactivant votre accès aux Produits Meta et aux
    Produits
    des entités Meta) afin de protéger notre communauté ou nos services, ou si vous générez un
    risque ou
    une responsabilité juridique pour nous, enfreignez les présentes Conditions d’utilisation ou nos
    politiques (notamment nos Règles de la Communauté Picafrou), si vous violez de manière répétée
    les
    droits de propriété intellectuelle d’autres personnes ou si la loi nous y oblige. Nous pouvons
    également modifier ou résilier le Service, supprimer ou bloquer du contenu ou des informations
    partagés sur notre Service ou cesser de fournir tout ou partie du Service si nous estimons que
    cela
    est raisonnablement nécessaire afin d’éviter ou d’atténuer des conséquences juridiques ou
    réglementaires qui nous seraient défavorables. Dans certains cas où nous supprimons du contenu,
    nous
    vous en informerons et vous expliquerons les options à votre disposition pour demander un
    réexamen.
    Vous ne pourrez pas faire cette demande si vous enfreignez gravement ou à maintes reprises les
    présentes Conditions ou si cela engage notre responsabilité juridique ou celle d’un tiers, porte
    atteinte à notre communauté d’utilisateurs, ou compromet ou altère l’intégrité ou le
    fonctionnement
    de nos services, systèmes ou produits, ou en cas de restrictions techniques ou lorsqu’il nous
    est
    interdit de le faire pour des raisons légales. En savoir plus sur notre processus de gestion des
    réclamations en cliquant ici. Si vous estimez que votre compte a été supprimé par erreur ou si
    vous
    souhaitez désactiver ou supprimer votre compte de façon permanente, consultez nos Pages d’aide.
    Lorsque vous demandez de supprimer du contenu ou votre compte, le processus de suppression
    commencera automatiquement dans les 30 jours après votre demande. La suppression de contenu peut
    prendre jusqu’à 90 jours après le début du processus de suppression. Lorsque le processus de
    suppression d’un tel contenu débute, le contenu n’est plus visible pour les autres utilisateurs,
    mais reste soumis aux présentes Conditions d’utilisation et à notre Politique de
    Confidentialité.
    Une fois le contenu supprimé, son retrait des systèmes de sauvegarde et de récupération en cas
    de
    sinistre peut prendre jusqu’à 90 jours supplémentaires.
    Le contenu ne sera pas supprimé dans les 90 jours de la suppression du compte ou du début du
    processus de suppression du contenu dans les situations suivantes :
    votre contenu a été utilisé par des tiers conformément à la présente licence, et ils ne l’ont
    pas
    supprimé (auquel cas, cette licence continue de s’appliquer jusqu’à la suppression du contenu) ;
    ou
    la suppression dans les 90 jours n’est pas possible en raison de limitations techniques de nos
    systèmes, auquel cas, nous procéderons à la suppression dès que ce sera possible d’un point de
    vue
    technique ; ou
    la suppression limiterait notre capacité à :
    identifier ou enquêter sur des activités illégales ou des violations de nos conditions générales
    et
    politiques (par exemple, identifier ou enquêter sur une utilisation abusive de nos produits ou
    systèmes) ;
    protéger la sécurité de nos produits, systèmes et utilisateurs ;
    respecter une obligation légale, telle que la conservation des éléments de preuve ; ou
    nous conformer à la demande d’une autorité judiciaire ou administrative, des forces de l’ordre
    ou
    d’un organisme gouvernemental ;
    auquel cas, le contenu ne sera pas conservé plus longtemps qu’il n’est nécessaire aux fins pour
    lesquelles il a été conservé (la durée exacte variant au cas par cas).
    Si vous supprimez ou si nous désactivons votre compte, les présentes Conditions prendront fin en
    tant qu’accord entre vous et nous, mais cette section et celle ci-dessous appelée « Notre accord
    et
    ce qu’il se passe en cas de désaccord » resteront applicables même après la résiliation, la
    désactivation ou la suppression de votre compte.

    Notre accord et les conséquences en cas de désaccord
    Notre accord.
    Votre utilisation de la musique dans le Service est également soumise à nos Règles applicables à
    la
    musique et votre utilisation de notre API est soumise à nos Conditions générales de la
    plate-forme
    Meta et à nos Règles pour les développeurs. Si vous utilisez certaines autres fonctionnalités ou
    des
    services associés, vous aurez l’occasion d’accepter des conditions supplémentaires qui feront
    également partie de notre accord. Par exemple, si vous utilisez des fonctionnalités de paiement,
    vous devrez accepter les Conditions de Paiement de la Communauté. Si vous avez souscrit un
    abonnement sans publicité, les Conditions générales sans publicité s’appliquent également. Ces
    conditions prévaudront en cas de conflit avec le présent accord.
    Si une quelconque disposition du présent accord devient inapplicable, les autres dispositions
    resteront en vigueur.
    Toute modification de notre accord ou toute renonciation à celui-ci doit être écrite et signée
    par
    nous. Notre manquement à faire appliquer toute disposition du présent accord ne pourra être
    interprété comme une renonciation.
    Nous nous réservons tous les droits qui ne vous sont pas explicitement accordés.
    Les ayants droit en vertu du présent accord
    Le présent accord n’offre aucun droit à des tiers.
    Vous ne pouvez pas céder vos droits ou obligations en vertu du présent accord sans notre
    consentement.
    Nos droits et obligations peuvent être attribués à d’autres personnes. Par exemple en cas de
    changement de propriété (comme lors d’une fusion, d’une acquisition ou d’une vente d’actifs) ou
    si
    la loi l’exige.
    Le responsable en cas d’incident.
    Nous utiliserons la diligence et l’attention raisonnables en vous fournissant notre Service et
    en
    maintenant un environnement sûr, sécurisé et exempt d’erreur. En revanche, nous ne pouvons pas
    garantir que notre Service fonctionnera toujours sans interruption, retard, ou imperfection. Si
    nous
    avons utilisé la diligence et l’attention raisonnables, nous déclinons toute responsabilité en
    cas
    de : perte non causée par notre manquement aux présentes Conditions ou par nos agissements ;
    perte
    que ni nous ni vous ne pouvions raisonnablement prévoir au moment où les présentes Conditions
    ont
    été conclues ; contenu offensant, inapproprié, obscène, illégal ou autrement répréhensible
    publié
    par d’autres personnes que vous pouvez rencontrer sur notre Service ; et évènement échappant à
    notre
    contrôle raisonnable.
    Les dispositions ci-dessus ne visent pas à exclure ni à limiter notre responsabilité en cas de
    décès, de préjudice corporel ou de présentation erronée dus à notre négligence. Elles ne visent
    pas
    non plus à exclure ou à limiter notre responsabilité pour tout autre agissement pour lequel la
    loi
    ne nous le permet pas.
    La gestion des litiges.
    Si une réclamation ou un litige découle de votre utilisation du Service en tant que consommateur
    ou
    s’y rapporte, vous et nous acceptons que vous pouvez résoudre votre réclamation ou litige
    individuel(le) contre nous, et que nous pouvons résoudre notre réclamation ou litige contre
    vous,
    devant tout tribunal compétent pour votre réclamation ou litige dans le pays de votre résidence
    principale, et que les lois de ce pays s’appliqueront sans égard aux dispositions en matière de
    conflits de lois.

    Si une réclamation ou un litige qui survient entre nous se rapporte à l’utilisation du Service à
    tout autre titre, y compris, mais sans s’y limiter, à l’accès ou à l’utilisation du Service à
    des
    fins professionnelles ou commerciales, vous acceptez que cette réclamation ou ce litige soit
    résolu
    dans un tribunal compétent en Irlande et que la loi irlandaise s’appliquera sans égard aux
    dispositions en matière de conflits de lois.
    Support non demandé.
    Nous apprécions toujours les commentaires et autres suggestions, mais nous pouvons les utiliser
    sans
    limitation aucune ni obligation de rémunération, et nous n’avons pas l’obligation de les garder
    confidentiels.

    Mise à jour des présentes Conditions
    Nous pouvons modifier notre Service et nos règlements, et il est possible que nous devions
    apporter
    des modifications aux présentes Conditions afin de les adapter parfaitement à notre Service et à
    nos
    règlements. Sauf disposition légale contraire, nous vous informerons (par l’intermédiaire de
    notre
    Service, par exemple) au moins 30 jours avant de modifier les présentes Conditions et vous
    donnerons
    l’opportunité de les examiner avant leur entrée en vigueur. Vous serez ensuite lié aux
    Conditions
    mises à jour si vous poursuivez votre utilisation du Service. Si vous ne souhaitez pas accepter
    les
    présentes Conditions ou leur mise à jour, vous pouvez supprimer votre compte ici.
    Date d’entrée en vigueur : 12 janvier 2024

</mat-dialog-content>les produits Meta.
<mat-dialog-actions>
    <button id="id_parolebtn"
            (click)="close()"
            class="btn btn-primary">Refuse</button>
    <button id="id_parolebtn"
            (click)="save()"
            class="mat-raised-button  btn btn-primary">Accept</button>
</mat-dialog-actions>