<script src="https://code.jquery.com/jquery-3.1.0.min.js"></script>
<!--link href="{{ STATIC_URL }}/bootstrap/css/bootstrap.css" rel="stylesheet" -->
<link rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
      crossorigin="anonymous">
<!--!script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script-->
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
        integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
        crossorigin="anonymous"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossorigin="anonymous"></script>
<script src="/javascripts/html2canvas.min.js"></script>


<!-- <script type="text/javascript"
        src="prototype.js"></script> -->
<ng-container *ngIf="(photo$ | async) as photo">
    <div id="buttons"
         class="buttons"
         *ngIf="episodeMode!='nobuttons'">

        <!-- <div *ngIf="(authenticationService.isOwner(photo.owner.toString())) " -->
        <!-- <button mat-icon-button
                class="buttoncolor"
                (click)="editPhoto()"
                title="edit photo from episode">
            <mat-icon aria-label="heart icon">edit</mat-icon>
        </button> -->

        <!-- <button *ngIf="authenticationService.isOwner(photo.owner.toString())"
            mat-icon-button
            (click)="deleteEpisodeDialog(episode.id)"
            title="delete episode">
        <mat-icon aria-label=" delete episode">delete</mat-icon>
    </button> -->
        <!-- <div *ngIf="(episodeMode!=='noedit') "> -->
        <div>

            <button mat-mini-fab
                    class="buttoncolor"
                    (click)="back()">
                <mat-icon>
                    arrow_back_ios
                </mat-icon>
            </button>
            <!-- <ng-container *ngIf="authenticationService.isOwner(photo.owner.toString())"> -->
            <ng-container *ngIf="authenticationService.isOwner(photo.owner.id.toString())">
                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="textTypeDialog()">
                    <span class="material-icons-outlined">
                        shape_line
                    </span>
                </button>
                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="textShapeDialog()">
                    <span class="material-icons-outlined">
                        forum
                    </span>
                </button>

                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="backgroudnColorPickerDialog()">
                    <span class="material-icons-outlined">
                        format_color_fill
                    </span>
                </button>
                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="increasefont()">
                    <span class="material-icons-outlined">
                        text_increase
                    </span>
                </button>
                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="decreasefont()">
                    <span class="material-icons-outlined">
                        text_decrease
                    </span>
                </button>
                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="newTextDialog()">
                    <span class="material-icons-outlined">
                        add
                    </span>
                </button>

                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="deleteTextDialog()">
                    <span class="material-symbols-outlined">
                        font_download_off
                    </span>
                </button>
                <button mat-icon-button
                        class="buttoncolor"
                        [disabled]="(!authenticationService.isLoggedIn()) || !((episodeMode=='edit') || ((episodeMode=='editphotoshoot')))"
                        (click)="deletePhotoDialog(photo.id)">
                    <span class="material-icons-outlined">
                        delete
                    </span>
                </button>
            </ng-container>
        </div>
    </div>
    <div id="buttons"
         class="buttons"
         *ngIf="episodeMode=='photoshoot'">
        <button mat-raised-button
                color="primary"
                (click)="newPhotoDialog()">
            <span class="material-symbols-outlined">
                attach_file_add
            </span>
        </button>
    </div>
    <app-comments *ngIf="(photo$ | async) as photo"
                  [comment_type]="'photo'"
                  [photo_id]="photo.id"
                  [ngStyle]="{'display': display_comment ? 'none' : ''}"></app-comments>
    <div *ngIf="(photo$ | async) as photo">
        <div class="image"
             id="divimage"
             draggable="false">
            <!-- (click)="imgSize(photo)" -->
            <img class="contentimage"
                 #myImg
                 id="{{ 'myimg' + photo.id }}"
                 *ngIf="(imageService.ogphotourl(photo.photocontainer,photo.photoname)|async) as imgurl"
                 [src]="imgurl"
                 alt=""
                 draggable="false"
                 (load)="onImageLoad($event)" />
            <div id="{{ 'textimg' + photo.id }}"
                 class="textimg"></div>
            <svg #mySvg
                 id="{{ 'svg' + photo.id }}"
                 xmlns="http://www.w3.org/2000/svg"
                 style="position:absolute;top:0px;left:0px"
                 zindex="9"
                 height="100%"
                 width="100%">
                <filter id='shadow'
                        color-interpolation-filters="sRGB">
                    <feDropShadow dx="3"
                                  dy="3"
                                  stdDeviation="5"
                                  flood-opacity="0.7" />
                </filter>
            </svg>

        </div>
    </div>

    <div id="buttons"
         class="buttons"
         *ngIf="episodeMode!='nobuttons'">
        <button mat-icon-button
                class="buttoncolor"
                (click)="likephoto(photo)"
                title="like photo">
            <mat-icon aria-label="heart icon"
                      [matBadge]="nblikes"
                      matBadgePosition="after"
                      [ngStyle]="{'color': islikedbyme ? 'yellow' : 'blue'}">favorite</mat-icon>
        </button>
        <button mat-icon-button
                [disabled]="!authenticationService.isLoggedIn()"
                mat-icon-button
                title="comment episode"
                (click)="display_comment=!display_comment">
            <mat-icon [matBadge]="nbcomments"
                      [ngStyle]="{'color': iscommentedbyme ? 'yellow' : 'blue'}"
                      matBadgePosition="after"
                      aria-label="Example icon-button with a heart icon">comment</mat-icon>
        </button>
        <button [disabled]="!authenticationService.isLoggedIn()"
                mat-icon-button
                (click)="openShareDialog()"
                title="share episode">
            <mat-icon [matBadge]="15"
                      matBadgePosition="after"
                      aria-label="share">share</mat-icon>
        </button>
    </div>
</ng-container>
<!-- <button type="submit"
            id="textchangedOK"
            class="btn btn-primary"
            [hiden]="showTextChangedOKButton"
            (click)="validateText()">validate text changes
    </button> -->
<!-- <h2>textes for photo {{photo.id}}</h2> -->
<!-- <app-text-form [photo_id]="photo.id"
                   (newTexte)="newtext($event)"></app-text-form> -->
<!-- <div class="container">
    <p>add text to photo:</p>
    <form [formGroup]="texteForm" (ngSubmit)="onSubmit(texteForm.value)">
        <div class="form-group">
            <mat-form-field class="full-width">
                <mat-label>Texte</mat-label>
                <textarea matInput type="text" id="comment" placeholder="texteForm" [formControl]="texteForm.controls['texte']" rows="2"></textarea>
            </mat-form-field>

            <mat-form-field>
                <mat-select [formControl]="texteForm.controls['type']" matNativeControl required>
-->
<!-- /mat-select>placeholder=" Type">-->
<!--
                    <mat-option [value]=0>
                        pensée
                    </mat-option>
                    <mat-option [value]=1>
                        parole
                    </mat-option>
                    <mat-option [value]=2>
                        contexte
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button type="submit" id="id_parolebtn" class="btn btn-primary">submit</button>
        </div>


    </form>
</div> -->

<!-- <a mat-fab
   routerLink="/">
    <mat-icon aria-label="upload image">cloud_upload</mat-icon> -->