import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef } from '@angular/core';
import { User } from '@/_models';
import { AuthenticationService } from '@/_services';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { VersionCheckService } from './services/version-check.service';
import { DOCUMENT } from '@angular/common';
import { PhotoService } from './services/photo.service';
import { NGXLogger } from 'ngx-logger';
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from './services/language.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AdvertisementDialogComponent } from './advertisement-dialog/advertisement-dialog.component';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  fullscreenSubscription: Subscription;
  fullscreen: boolean;
  fullscreen$: Observable<boolean>;
  scrollTop = 0;
  cumulscrolldown: number = 0;
  cumulscrollup: number = 0;

  hideTopNav: boolean = false;
  hideBottomNav: boolean = false;
  elem: any;

  constructor(
    private ref: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private versionCheckService: VersionCheckService,
    private languageService: LanguageService,
    private photoService: PhotoService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private router: Router,
    private swUpdate: SwUpdate,
    @Inject(DOCUMENT) private document: any
  ) {
    translate.addLangs(['en', 'zh-hans', 'hi', 'es', 'fr', 'ar', 'bn', 'ru', 'pt', 'ja', 'ko', 'vi']);
    // translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    if (localStorage.getItem('locale')) {
      translate.setDefaultLang(localStorage.getItem('locale'));
      translate.use(localStorage.getItem('locale'));
      this.logger.debug('app component  locale found ' + localStorage.getItem('locale'));
    } else {
      translate.setDefaultLang('fr');
      translate.use('fr');
      localStorage.setItem('locale', 'fr');
      this.logger.debug('app component  locale not found ' + localStorage.getItem('locale'));
    }
    languageService.setLanguage()
    // translate.use('ar');
  }

  ngOnInit(): void {
    // compile time or runtime env
    // this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    // out for change detection issue
    // this.versionCheckService.initVersionCheck(this.appconfigService.config.versionCheckURL);
    this.authenticationService.autoLogin();
    this.elem = document.documentElement;
    //    this.openFullscreen();
    this.fullscreen$ = this.photoService.getFullscreen();
    this.fullscreenSubscription = this.photoService.fullscreen.subscribe(fs => {
      // this.ref.markForCheck();
      this.logger.debug('app component fullscreen value  ' + fs);
      this.fullscreen = fs;
      // this.ref.markForCheck();
      this.ref.detectChanges();
    });
    // this.advertisementDialog();
    if (this.swUpdate.isEnabled) {

      // we need to propose user when a new version is available even if not exited or full refresh
      // this.swUpdate.available.subscribe(() => {
      //   if (confirm("New version available. Load New Version?")) {
      //     window.location.reload();
      //   }
      // });
    }

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.fullscreenSubscription.unsubscribe();
  }


  // openFullscreen() {
  //   if (this.elem.requestFullscreen) {
  //     this.elem.requestFullscreen();
  //   } else if (this.elem.mozRequestFullScreen) {
  //     /* Firefox */
  //     this.elem.mozRequestFullScreen();
  //   } else if (this.elem.webkitRequestFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     this.elem.webkitRequestFullscreen();
  //   } else if (this.elem.msRequestFullscreen) {
  //     /* IE/Edge */
  //     this.elem.msRequestFullscreen();
  //   }
  // }

  advertisementDialog() {
    this.logger.debug('EditPhotoComponent deleteTextDialog: ');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.position = {
      'top': '0',
      left: '0'
    };
    dialogConfig.maxWidth = '100vw';
    dialogConfig.maxHeight = '100vh';
    dialogConfig.height = '100%';
    dialogConfig.width = '100%';
    // dialogConfig.width = '100vw';
    // dialogConfig.height = '100vh';
    dialogConfig.data = {
      textd: 'hello'
    };

    const dialogRef = this.dialog.open(AdvertisementDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Delete Dialog output:", data)
      });
  }



  onScroll(event) {
    // this.logger.debug('onScroll event ' + JSON.stringify(event));
    // this.logger.debug('onScroll event target.scrollTop;' + event.target.scrollTop + ' ' + this.scrollTop);
    if ((this.scrollTop < event.target.scrollTop)) {
      //scroll up 
      if (this.hideTopNav) {
        //hidetopnav allredy true
      } else {
        //scroll up and istart hiding topnav
        this.cumulscrollup += event.target.scrollTop - this.scrollTop
        if (this.cumulscrollup > 10) {
          this.cumulscrolldown = 0;
          this.hideTopNav = this.scrollTop < event.target.scrollTop;
        }
      }
    }
    else {
      // scroll down 
      if (this.hideTopNav) {
        //hidetopnav changing to false
        this.cumulscrolldown += this.scrollTop - event.target.scrollTop
        if (this.cumulscrolldown > 10) {
          this.hideTopNav = this.scrollTop < event.target.scrollTop;
          this.cumulscrollup = 0;
        }
      } else {
        //scroll down and allredy showing topnav
      }
    }
    // this.logger.debug(this.hideTopNav ? 'UP' : 'DOWN')
    // this.hideBottomNav = this.scrollTop < event.target.scrollTop;
    this.scrollTop = event.target.scrollTop;
  }
}
