<div *ngIf="episode">
  <h2>{{ episode.title }} :</h2>
  <app-photos [episode_id]="episode.id"></app-photos>
</div>

<style>
  .my-drop-zone {
    border: dotted 3px lightgray;
  }

  .nv-file-over {
    border: dotted 3px red;
  }

  /* Default class applied to drop zones on over */
  .another-file-over-class {
    border: dotted 3px green;
  }

  html,
  body {
    height: 100%;
  }
</style>
<!--
<div class="container">

  <div class="navbar navbar-default">
    <div class="navbar-header">
      <a class="navbar-brand" href>Angular2 File Upload</a>
    </div>
  </div>

  <div class="row">

    <div class="col-md-3">


      <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
        [uploader]="uploader" class="well my-drop-zone">
        Base drop zone
      </div>


      Single
      <input type="file" ng2FileSelect [uploader]="uploader" />
    </div>

    <div class="col-md-9" style="margin-bottom: 40px">

      <table class="table">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploader.queue">
            <td><strong>{{ item?.file?.name }}</strong></td>
            <td *ngIf="uploader.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
            <td *ngIf="uploader.isHTML5">
              <div class="progress" style="margin-bottom: 0;">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
              </div>
            </td>
            <td class="text-center">
              <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
              <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
              <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
            </td>
            <td nowrap>
              <button type="button" class="btn btn-success btn-xs" (click)="item.upload()"
                [disabled]="item.isReady || item.isUploading || item.isSuccess">
                <span class="glyphicon glyphicon-upload"></span> Upload
              </button>
              <button type="button" class="btn btn-warning btn-xs" (click)="item.cancel()"
                [disabled]="!item.isUploading">
                <span class="glyphicon glyphicon-ban-circle"></span> Cancel
              </button>
              <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                <span class="glyphicon glyphicon-trash"></span> Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="episode">
  <app-upload [episode_id]="episode.id"></app-upload>
</div>
-->
<div *ngIf="episode">
  <app-uploaddialog [episodeId]="episode.id" [numero]="1"></app-uploaddialog>
</div>