

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Episode } from '../models/episode';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
import { EpisodesComponent } from '../episodes/episodes.component';
import { AppConfigService } from './app-config.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Author } from '@/models/author';
import { UrlService } from './url.service';

// const API_URL = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', 'Authorization': 'Basic ' +
      btoa('serge.frezefond@gmail.com:manager12')
  })
};

@Injectable({ providedIn: 'root' })
export class BookmarkService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private urlService: UrlService,
  ) {
  }

  getMyBookmarkedEpisodes(): Observable<Page<Episode>> {
    return queryPaginatedAllLimit<Episode>(this.http, this.urlService.getMyBookmarkedEpisodesUrl(), 100);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a EpisodeService message with the MessageService */
  private log(message: string) {
    this.messageService.add('EpisodeService: ' + message);
  }
}

