<mat-tab-group #tabgroup
               (touchstart)="swipe($event, 'start')"
               (touchend)="swipe($event, 'end')"
               matTabGroupGesture
               class="tabs"
               [(selectedIndex)]="selectedTabNum"
               (selectedIndexChange)="changeSelectedTabNum($event)">
    <mat-tab label="friends">
        <div *ngIf="(myfriends$ | async) as myfriends">
            <app-authors [title]="'my friends'"
                         [displayMode]="'list'"
                         [authors]="myfriends"
                         [chooseDisplayMode]="false"></app-authors>
        </div>
    </mat-tab>
    <mat-tab label="following">
        <div *ngIf="(myfollowed$ | async) as myfollowed"
             class="flexd">
            <app-authors [title]="'my following'"
                         [displayMode]="'list'"
                         [authors]="myfollowed"
                         [chooseDisplayMode]="false"></app-authors>
        </div>
    </mat-tab>
    <mat-tab label="followers">
        <div *ngIf="(myfollowers$ | async) as myfollowers"
             class="flexd">
            <app-authors [title]="'my followers'"
                         [displayMode]="'list'"
                         [authors]="myfollowers"
                         [chooseDisplayMode]="false"></app-authors>
        </div>
    </mat-tab>
</mat-tab-group>