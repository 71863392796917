<mat-card appearance="outlined"
          class="my-card">
    <mat-card-title>
        Email Validation
    </mat-card-title>
    <mat-card-content>
        <div>
            <div *ngIf="verifying"
                 style="text-align: center;">
                <mat-spinner></mat-spinner>
            </div>
            <p *ngIf="verifying"
               class="alert alert-warning">We are verifying your account</p>
            <p *ngIf="verified"
               class="alert alert-success">
                Your account has has been verified! You may now login.
            </p>
            <div *ngIf="verificationFailed"
                 class="alert alert-danger">
                This email verification link is not valid. Please request a new email verification
                link.
            </div>
            <p class="text-danger"
               *ngIf="error">{{error}}</p>
        </div>
        <p class="alert alert-danger"
           *ngIf="error">{{error}}</p>

        <mat-card-actions>
            <button mat-raised-button
                    routerLink="/login"
                    type=" submit"
                    color="primary">ok</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>