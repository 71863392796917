import { Episode } from '@/models/episode';
import { EpisodeService } from '@/services/episode.service';
import { ImageService } from '@/services/image.service';
import { AuthenticationService } from '@/_services';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Serie } from '../models/serie';
import { SerieService } from '../services/serie.service';
import { PhotoService } from '@/services/photo.service';
import { Photo } from '@/models/photo';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  //encapsulation: ViewEncapsulation.None // to modify style of child component does not work

})
export class DashboardComponent implements OnInit {

  @ViewChild('tabgroup', { static: true }) tabgroup: MatTabGroup;
  @Input() selectedTabNum: number = 2;
  public display = 'grid'; // possible value list or grid
  series$: Observable<Serie[]>;
  // mySeries$: Observable<Serie[]>;
  // myDraftSeries$: Observable<Serie[]>;
  episodes$: Observable<Episode[]>;
  // myEpisodes$: Observable<Episode[]>;
  // myDraftEpisodes$: Observable<Episode[]>;
  photoshoots$: Observable<Photo[]>;
  // myPhotoshoots$: Observable<Photo[]>;

  constructor(private serieService: SerieService,
    private route: ActivatedRoute,
    private router: Router,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    private imageService: ImageService,
    public authenticationService: AuthenticationService,
    private logger: NGXLogger) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('tabid');
    this.selectedTabNum = id;

    this.series$ = this.serieService.getSeries();
    this.episodes$ = this.episodeService.getEpisodes();
    this.photoshoots$ = this.photoService.getPhotoshoots();

    // if (this.authenticationService.isLoggedIn()) {
    //   this.mySeries$ = this.serieService.getMyPublicSeries();
    //   this.myDraftSeries$ = this.serieService.getMyDraftSeries();
    //   this.myEpisodes$ = this.episodeService.getMyPublicEpisodes();
    //   this.myDraftEpisodes$ = this.episodeService.getMyDraftEpisodes();
    //   this.myPhotoshoots$ = this.photoService.getMyPhotoshoots();
    // }
    this.logger.debug('dashboard series$ and episodes$ set');
    // this.authenticationService.refreshToken().subscribe(); ///// why here ???
  }
  onValChange(value) {
    console.log('onValChange');
    console.log(value);
    this.display = value;
  }
  changeSelectedTabNum(tabnum) {
    this.selectedTabNum = tabnum;
    this.router.navigate(['/dashboard/' + tabnum]);
  }
  // public selected: number;
  private swipecoord?: [number, number];
  private swipetime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipecoord = coord;
      this.swipetime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipecoord[0], coord[1] - this.swipecoord[1]];
      const duration = time - this.swipetime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        switch (swipe) {
          case 'previous':
            if (this.selectedTabNum > 0) { this.selectedTabNum--; }
            break;
          case 'next':
            if (this.selectedTabNum < this.tabgroup._tabs.length - 1) { this.selectedTabNum++; }
            break;
        }
      }
    }
  }
}
