import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { AuthenticationService } from '@/_services';
import { AppConfigService } from '@/services/app-config.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NGXLogger } from 'ngx-logger';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    refreshTokenUrl = 'http://localhost:8000/en/dj-rest-auth/token/refresh/';
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        // private http: HttpClient,
        // private injector: Injector,
        public authenticationService: AuthenticationService,
        private logger: NGXLogger

    ) {
        this.logger.debug('jwtinterceptor constructor')
        // this.authenticationService = this.injector.get(AuthenticationService);
    }
    // {

    //     this.logger.info('intercept constructor');

    //     const appConfigService = this.injector.get(AppConfigService);
    //     appConfigService.loadAppConfig().then(function (finalResult) {
    //         this.logger.info('Got the final result: ' + finalResult);
    //     });

    // }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this.logger.info('intercept:');
        let authreq = request;
        let token = localStorage.getItem('access_token');
        if (token != null) {
            // this.logger.info('injecting token in header');
            authreq = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            });
        }
        return next.handle(authreq).pipe(catchError((error) => {
            if (error instanceof HttpErrorResponse && !authreq.url.includes('login') && error.status === 401) {
                this.logger.info('401 detected need token refresh :');
                return this.handle401Error(authreq, next);
            } else {
                this.logger.info('not 401 error detected no need token refresh :');
                this.logger.info('error status ' + error.status);
                this.logger.info('error.error : ' + JSON.stringify(error.error));
                // return throwError(() => new Error(error));
                return throwError(error);
            }
        }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        this.logger.info('handle401Error:');
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            this.logger.info('handle401Error not refreshin:');
            const refresh_token = AuthenticationService.getRefreshToken();
            this.logger.info('getrefreshtoken token:' + refresh_token);
            if (refresh_token != null) {
                this.logger.info('calling refreshtoken :');
                return this.authenticationService.refreshToken()
                    .pipe(
                        switchMap((token: any) => {
                            this.logger.info('new token :' + JSON.stringify(token));
                            localStorage.setItem('access_token', token.access);
                            localStorage.setItem('refresh_token', token.refresh);
                            this.isRefreshing = false;
                            this.refreshTokenSubject.next(token.access);
                            return next.handle(this.addTokenHeader(request, token.access));
                        }),
                        catchError((err) => {
                            this.isRefreshing = false;
                            this.logger.info('error refreshing token :' + err);
                            this.authenticationService.logout();
                            return throwError(err);
                        })
                    );
            }
        }
        return this.refreshTokenSubject.pipe(
            tap({
                next: x => { this.logger.info('error refreshTokenSubject  :'); },
                error: err => { console.error(err); }
            }),
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }
    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        });
    }

}
