import { Texte } from '@/models/texte';
import { TexteService } from '@/services/texte.service';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { NewTextDialogComponent } from '../new-text-dialog/new-text-dialog.component';

@Component({
  selector: 'app-delete-text-dialog',
  templateUrl: './delete-text-dialog.component.html',
  styleUrls: ['./delete-text-dialog.component.scss']
})
export class DeleteTextDialogComponent {
  text_id: number;
  photo_id: number;

  constructor(
    private dialogRef: MatDialogRef<NewTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private texteService: TexteService,
    private logger: NGXLogger
  ) {
    this.logger.debug('deleteTextDialog constructor ' + JSON.stringify(data));
    this.text_id = data.text_id;
    this.photo_id = data.id;
  }

  ngOnInit(): void {
  }

  save() {
    const newTexte = new Texte();
    newTexte.id = this.text_id;
    this.texteService.deleteTexte(newTexte)
      .subscribe(txt => {
        console.log('delete txt :' + newTexte.id);
        // this.newTexte.emit(txt)
        // return txt.id;
      });
    this.dialogRef.close('deleted');
  }

  close() {
    this.dialogRef.close();
  }
}
