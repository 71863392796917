import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService, AuthenticationService } from '@/_services';
import { sleep } from '@/shared/util';
import { MatDialog } from '@angular/material/dialog';
import { LoginFailedDialogComponent } from './login-failed-dialog/login-failed-dialog.component';

// const sleep = (ms) => new Promise(r => setTimeout(r, ms));



@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.scss']
})
export class LoginComponentComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = false;
  submitted = false;
  returnUrl: string;
  hide = true;
  error: string = null;

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      console.log('constructor component allready loged');
      console.log('constructor navigating to /');

      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      }],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

    // get return url from route parameters or default to '/'

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log('return Url ' + this.returnUrl);

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  async onSubmit() {
    this.submitted = true;
    console.log('submit loginForm');

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      console.log('submit loginForm error');

      return;
    }

    this.isLoading = true;
    await sleep(1000);

    this.authenticationService.login(this.f.email.value.toLowerCase(), this.f.password.value)
      .pipe(first())
      .subscribe({

        next: (data) => {
          console.log('LoginComponentComponent onSubmit loginForm called authenticationService.login');
          console.log('LoginComponentComponent onSubmit loginForm called authenticationService.data' + data);
          console.log('LoginComponentComponent onSubmit submit loginForm nexturl : ' + this.returnUrl);

          this.isLoading = false;
          this.router.navigate([this.returnUrl]);
          this.error = 'totot'
        },
        error: (error) => {
          console.log('error loginForm called authenticationService.login');
          // this.alertService.error(error);
          this.error = error;
          this.isLoading = false;
        }
      });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(LoginFailedDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
